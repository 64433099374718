import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/billing/successful.svg';
import { classNames } from 'utils/classNames';
import { IPaymentInvoice } from 'models/billing';

import styles from './PaymentSuccess.module.scss';

interface PaymentSuccessProps {
  data: IPaymentInvoice;
  onClose?: () => void;
  className?: string;
}

export const PaymentSuccess = ({ data = {}, className, onClose }: PaymentSuccessProps) => {
  const { number, tax = 0, total_excluding_tax = 0, total = 0 } = data;

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <BrandLogo />
        </div>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>
      <div className={styles.body}>
        <p className={styles.funds}>
          <SuccessIcon className={styles.icon} /> Funds Added: ${total_excluding_tax / 100}
        </p>
        <p className={styles.title}>Thank you!</p>
        <p className={styles.description}>Your total balance will be updated shortly</p>
        <p className={styles.order}>
          Order ID: <span> {number}</span>
        </p>
        <div className={styles.tax}>
          <div>Tax</div>
          <p>${tax / 100}</p>
        </div>
        <div className={styles.total}>
          <div>Total</div>
          <p>${total / 100}</p>
        </div>
      </div>
    </div>
  );
};
