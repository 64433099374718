import { generateMessage } from '../config/api';
import { IGetScrapingInfo } from '../models/scraping';

import { BaseService } from './BaseService';

class AIService extends BaseService {
  public async generateMessage({
    creatorId,
    chatterId,
  }: IGetScrapingInfo): Promise<{ message: string; balanceAmount: number }> {
    return this.post(generateMessage(creatorId, chatterId));
  }
}

export const aiService = new AIService();
