import { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';

import Suggections from './Suggections';

interface IProps {
  disabled: boolean;
  notes: string;
  onUpdate: (data: string) => void;
}

const TextareaBlock = ({ notes, onUpdate, disabled }: IProps) => {
  const isTypedRef = useRef(false);

  const [suggestionPos, setSuggestionPos] = useState<{ x: number; y: number } | false>(false);
  const [value, setValue] = useState(notes);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string | false>(false);

  const [selectionRange, setSelectionRange] = useState<number | null>(null);

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    isTypedRef.current = false;
    setValue(notes);
  }, [notes]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(value);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [value]);

  useEffect(() => {
    if (!debouncedInputValue || !isTypedRef?.current) {
      return;
    }

    onUpdate(debouncedInputValue);
  }, [debouncedInputValue]);

  useEffect(() => {
    if (selectionRange === null || !ref.current) {
      return;
    }

    ref.current.focus();
    ref.current.setSelectionRange(selectionRange, selectionRange);
  }, [selectionRange]);

  const handleChange = (e: any) => {
    const cursorPos = e.target.selectionStart;
    const currentSymbol = e.target.value.charAt(cursorPos - 1);

    if (currentSymbol === '#') {
      isTypedRef.current = false;
      setValue(e.target.value);

      const position = getCursorCoordinates();
      if (position) {
        let { x, y } = position;
        const height = ref.current?.getBoundingClientRect().height ?? 0;
        const width = ref.current?.getBoundingClientRect().width ?? 0;

        if (x + 120 > width) {
          x = width - 120;
        }

        if (y + 215 > height) {
          y = height - 215;
        }

        setSuggestionPos({ x, y });
      } else {
        setSuggestionPos(false);
      }
    } else {
      isTypedRef.current = true;

      setValue(e.target.value);
      setSuggestionPos(false);
    }
  };

  function getCursorCoordinates() {
    if (!ref.current) {
      return;
    }
    const textarea = ref.current;
    const cursorPos = ref.current.selectionStart;
    const content = textarea.value.substring(0, cursorPos);

    const rows = content.split('\n').length;
    const lastRow = content.lastIndexOf('\n');

    const line = content.substring(lastRow + 1);

    const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
    const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop);

    const top = (rows - 1) * lineHeight + paddingTop;
    const fontSize = parseFloat(getComputedStyle(textarea).fontSize);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.font = `${fontSize}px "Roboto", serif, "Times New Roman"`;

    const textWidth = ctx.measureText(line).width;

    return { y: top + 8, x: textWidth + 8 };
  }

  return (
    <Box sx={{ height: '354px', position: 'relative' }}>
      {suggestionPos ? (
        <Suggections
          {...suggestionPos}
          onClick={(tag: string) => {
            if (!ref.current) {
              return;
            }
            const cursorPos = ref.current.selectionStart ?? 0;

            const newValue = [value.slice(0, cursorPos), tag.replace('#', ''), ' ', value.slice(cursorPos)].join('');

            setSuggestionPos(false);

            isTypedRef.current = true;
            setValue(newValue);

            setSelectionRange(cursorPos + tag.length);
          }}
        />
      ) : null}
      <textarea
        ref={ref}
        value={value}
        style={{
          width: '100%',
          minHeight: '100%',
          cursor: disabled ? 'not-allowed' : 'inherit',
        }}
        onChange={handleChange}
        disabled={disabled}
      />
    </Box>
  );
};

export default TextareaBlock;
