import React from 'react';

import { useAppSelector } from 'store';
import { getIsOnline, getUserAuthData } from 'store/reducers/auth';
import { BadConnectionScreen } from 'components/screens';

import { ElectronProvider, OFNotificationProvider, EmojiProvider } from '../../providers';
import { isElectron } from '../../helpers';

import ElectronContainerView from './ElectronContainerView';

interface IElectronContainer {
  children: React.ReactNode;
}

const ElectronContainer = ({ children }: IElectronContainer) => {
  const isOnline = useAppSelector(getIsOnline);
  const isAuth = useAppSelector(getUserAuthData);

  if (!isOnline) {
    return <BadConnectionScreen />;
  }

  if (!isElectron || !isAuth) {
    return <>{children}</>;
  }

  return (
    <ElectronProvider>
      <OFNotificationProvider>
        <EmojiProvider>
          <ElectronContainerView>{children}</ElectronContainerView>
        </EmojiProvider>
      </OFNotificationProvider>
    </ElectronProvider>
  );
};

export default ElectronContainer;
