export enum AgencyType {
  TEAM = 'TEAM',
  SOLO = 'SOLO',
}

export interface IAgencyFormData {
  agencyName?: string;
  zone?: string;
  type?: string;
  ownerId?: string;
  name?: string;
}

export interface ISignUpLinkRequest {
  memberToken?: string;
}

export interface ISignUpRequest extends IAgencyFormData, ISignUpLinkRequest {
  password: string;
  name: string;
  email: string;
}

export interface ISignInRequest {
  password: string;
  email: string;
  isStepForSignUp?: boolean;
}

export interface IAuthTokenData {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
}

export interface IConfirmPassword {
  email: string;
  password?: string;
  code: string;
}

export enum SignInStep {
  REQUST_FORM,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
  SUCCESS,
}

// export interface IAuthData {
//   createAt?: string;
//   name: string;
//   updateAt?: string;
//   agencyId: string;
//   id: string;
//   roleId: string;
//   email: string;
//   stripeCustomerId: string;
// }
