import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { ISelectedPlanData } from 'models/billing';
import { ApiErrorCode, getErrorMessage } from 'constants/messages';
import { billingService } from 'services/BillingService';
import { useAppDispatch, useAppSelector } from 'store';
import { getBillingBalance, loadCurrentBalance, loadSubscriptions } from 'store/reducers/billing';
import { loadCreatorsList } from 'store/reducers/creators';
import { logEvent } from 'utils/analytics';
import { GAAction, GACategory } from 'constants/analytics';

const useSubscription = (plans: ISelectedPlanData[]) => {
  const { enqueueSnackbar } = useSnackbar();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const balance = useAppSelector(getBillingBalance);
  const dispatch = useAppDispatch();

  const getPlanTotals = useCallback(() => {
    const totalWithDiscount = plans.reduce((total, data) => total + (data?.discount || data?.regular || 0), 0);
    const total = plans.reduce((total, data) => total + (data?.regular || 0), 0);
    const savedTotal = total - totalWithDiscount;
    return { totalWithDiscount, total, savedTotal };
  }, [plans]);

  const getSelectedPlans = (value: ISelectedPlanData) => {
    if (paymentLoading || value.disabled) return;

    const updatedSelectedPlans = [...plans];
    const index = updatedSelectedPlans.findIndex((item) => item?.creatorId === value?.creatorId);

    // If any plan is selected for cretor. Check if you clicked on the same plan (toggle) or another from the one creator
    if (index !== -1) {
      // Toggle implementation. When you click on the same plan to turn it off
      if (updatedSelectedPlans[index]?.plan === value.plan) {
        updatedSelectedPlans.splice(index, 1);
      } else {
        updatedSelectedPlans[index] = value;
      }
    } else updatedSelectedPlans.push(value);

    return updatedSelectedPlans;
  };

  const createPaymentSubscription = async () => {
    const { totalWithDiscount } = getPlanTotals();
    if (totalWithDiscount > balance || balance === 0) {
      enqueueSnackbar(getErrorMessage(ApiErrorCode.INSUFFICIENT_BALANCE_ERROR), {
        variant: 'error',
      });
      return;
    }

    setPaymentLoading(true);
    try {
      const data = plans.map(({ creatorId, plan }) => ({
        creatorId,
        plan,
      }));

      await billingService.createPaymentSubscriptions(data);
      dispatch(loadCurrentBalance());
      dispatch(loadSubscriptions());
      dispatch(loadCreatorsList());
      logEvent(
        GACategory.Platform,
        GAAction.SuccessPayment,
        `Value: ${totalWithDiscount} | Selected Plans: ${plans.map(({ plan }) => plan).join(', ')}`,
        {
          totalWithDiscount: String(totalWithDiscount),
          plans: plans.map(({ plan }) => plan).join(', '),
        },
      );
      enqueueSnackbar('Payment Successful', { variant: 'success' });
    } catch (e) {}
    setPaymentLoading(false);
  };

  return {
    paymentLoading,
    getPlanTotals,
    createPaymentSubscription,
    getSelectedPlans,
  };
};

export default useSubscription;
