import React, { useState, useEffect } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { getCatchErrorMessage } from '../utils/error';

type LoadDataType<T> = (params?: any, configs?: AxiosRequestConfig) => Promise<T>;
interface IUseFetchReturn<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
  callLoadData: (params?: any, configs?: AxiosRequestConfig) => void;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useFetch = <T>(loadData: LoadDataType<T>): IUseFetchReturn<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const axiosAbortControllerRef = React.useRef<any>();
  const loadDataPromiseRef = React.useRef<any>();

  const callLoadData = React.useCallback(
    (params = {}, configs = {}) => {
      if (loadDataPromiseRef.current && axiosAbortControllerRef.current) {
        axiosAbortControllerRef.current.abort();
      }

      setLoading(true);

      axiosAbortControllerRef.current = new AbortController();

      loadDataPromiseRef.current = loadData(params, {
        ...configs,
        signal: axiosAbortControllerRef.current ? axiosAbortControllerRef.current.signal : null,
      })
        .then((data: any) => {
          loadDataPromiseRef.current = null;

          //-1 -  skip state update
          if (data !== -1) {
            setData(data !== undefined ? data : []);
          }

          setLoading(false);
        })
        .catch((err: any) => {
          if (err?.code !== AxiosError.ERR_CANCELED) {
            setLoading(false);
            loadDataPromiseRef.current = null;
          } else {
            setError(getCatchErrorMessage(err));
          }
        });
    },
    [loadData],
  );

  useEffect(() => {
    return () => {
      axiosAbortControllerRef?.current?.abort();
    };
  }, [callLoadData]);

  return { data, loading, error, callLoadData, setData, setLoading };
};

export default useFetch;
