import { Props } from 'react-apexcharts';

import { StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { formatNumberWithPrecision } from 'utils/convert';

import { ChartType } from '../Chart/Chart';

const useColumnStatistics = () => {
  const getConvertedColumnSettings = async (filters: StatisticFilters, initialSettings: Props, name: string) => {
    try {
      const { data } =
        name === ChartType.BEST_HOUR
          ? await statisticsService.getCreatorBestHour(filters)
          : await statisticsService.getCreatorBestDay(filters);

      const updatedSeries = data?.map((item) => Number(item.sum));
      const updatedCategories = data?.map((item) => item.value);
      const commonSum = updatedSeries?.reduce((sum, value) => sum + (value || 0), 0);

      const deepUpdatedSettings = JSON.parse(JSON.stringify(initialSettings));
      deepUpdatedSettings.options.xaxis.categories = updatedCategories;
      deepUpdatedSettings.series[0].data = commonSum > 0 ? updatedSeries : [];
      deepUpdatedSettings.options.xaxis.labels.rotate = name === ChartType.BEST_HOUR ? -90 : -45;
      deepUpdatedSettings.options.yaxis.labels.formatter = (val: string | number | number[]): string => {
        return `$ ${Number(val).toFixed()}`;
      };

      deepUpdatedSettings.options.tooltip = {
        marker: {
          show: false,
        },
        y: {
          formatter: (value: number) => `$ ${formatNumberWithPrecision(value)}`,
        },
      };
      //(!commonSum || commonSum <= deepUpdatedSettings.options.yaxis.stepSize) &&
      //  (deepUpdatedSettings.options.yaxis.tickAmount = 3);
      return deepUpdatedSettings;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getConvertedColumnSettings,
  };
};

export default useColumnStatistics;
