import { useCallback } from 'react';

import { fanNotesService } from 'services/FanNotesService';

import { TAGS } from '../constants';

interface INote {
  text: string;
  tag: string;
}

type UseFanNotesType = () => {
  updateNotes: (creatorId: string, chatterId: string, notes: string) => Promise<void>;
  divideText: (noteString: string) => INote[] | null;
  getFilteredNotes: (noteString: string, activeTag: string) => string;
  prepareSaveNotes: (noteString: string, activeTag: string, value: string) => string;
};

const useFanNotes: UseFanNotesType = () => {
  const updateNotes = useCallback(async (creatorId: string, chatterId: string, notes: string) => {
    try {
      await fanNotesService.updateFanNotes(creatorId, chatterId, { notes });
    } catch (e) {}
  }, []);

  const divideText = useCallback((noteString: string) => {
    const tags = TAGS.map((tag) => tag.label);
    const regex = /#(\w+)/g;
    const matches: string[] | null = noteString.match(regex);

    if (!matches) {
      return null;
    }

    const filteredMatches = matches.filter((item) => tags.includes(item));

    if (!filteredMatches) {
      return null;
    }

    const noteList: INote[] = [];
    let updatedStr = noteString;

    for (let i = 0; i <= filteredMatches.length - 1; i++) {
      const startIdx = 0;
      const endIdx = updatedStr.indexOf(filteredMatches[i]);
      const text = updatedStr.substring(startIdx, endIdx).trim();

      updatedStr = updatedStr.slice(endIdx + filteredMatches[i].length);

      noteList.push({ text, tag: filteredMatches[i] } as INote);
    }

    updatedStr = updatedStr.trim();

    if (updatedStr.length > 0) {
      noteList.push({ text: updatedStr, tag: '' } as INote);
    }

    return noteList;
  }, []);

  const getFilteredNotes = (noteString: string, activeTag: string) => {
    const notes = divideText(noteString);

    if (notes === null) {
      return noteString;
    }

    let filtered = [];
    if (activeTag) {
      filtered = notes.filter((note) => note.tag === activeTag);
    } else {
      filtered = notes;
    }

    return filtered.map(({ text, tag }) => `${text} ${tag}`).join('\n\n');
  };

  const prepareSaveNotes = (noteString: string, activeTag: string, value: string) => {
    let newValueSrt = '';
    if (activeTag) {
      const notes = divideText(noteString);
      if (notes === null) {
        newValueSrt = value;
      } else {
        //TODO: fix the issue of typing while filter is active
        const newValue = notes
          .map(({ text, tag }) => {
            if (tag === activeTag || !tag) {
              return '';
            } else {
              return `${text}${tag ? ' ' + tag : ''}`;
            }
          })
          .join(' ');

        newValueSrt = `${newValue}\n\n${value.trim()}`;
      }
    } else {
      newValueSrt = value;
    }

    return newValueSrt;
  };

  return {
    updateNotes,
    divideText,
    getFilteredNotes,
    prepareSaveNotes,
  };
};

export default useFanNotes;
