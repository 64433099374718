import { ForceUpdateStatus } from 'models/subscribers';

type MessageType = 'default' | 'info' | 'error' | 'success' | 'warning';

export const STATUS_MESSAGES: Record<ForceUpdateStatus, { title: string; text: string; type: MessageType }> = {
  [ForceUpdateStatus.IN_PROGRESS]: {
    title: 'Data is still being updated',
    text: 'Please wait',
    type: 'info',
  },
  [ForceUpdateStatus.PROCEED]: {
    title: 'The data is up to date',
    text: '',
    type: 'info',
  },
  [ForceUpdateStatus.PROCEED_ERROR]: {
    title: `Sorry, could not update media`,
    text: '',
    type: 'error',
  },
  [ForceUpdateStatus.REQUESTED]: {
    title: 'The upgrade process has begun',
    text: 'Please wait 5 Minutes',
    type: 'info',
  },
};
