import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AITrainingStatus,
  AccountsTableColumns,
  CreatorsActions,
  CreatorsSessionStatus,
  creatorsSessionConfig,
} from 'constants/creators';
import { ModalType } from 'constants/modal';
import { WebviewURL } from 'constants/externalLinks';
import { classNames } from 'utils/classNames';
import { Button, ThemeButton } from 'components/Button/Button';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { IColumn } from 'models/table';
import { modalActions } from 'store/reducers/modal';
import { useAppDispatch, useAppSelector } from 'store';
import { getCreatorsInited, getCreatorsLoading } from 'store/reducers/creators';
import { ICreators, NOT_SET_PROXY, SubscriptionStatus } from 'models/creators';
import { creatorsService } from 'services/CreatorsService';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import usePermissions from 'hooks/usePermissions';

import { RoutePath } from '../../config/routeConfig';
import { useChatterCharms } from '../../providers/ChatterCharmsProvider';
import { useElectronContext } from '../../modules/electron/providers';

import styles from './Accounts.module.scss';

const columns: IColumn[] = [
  {
    id: AccountsTableColumns.CREATOR,
    fieldName: 'creator',
    label: 'Creator',
  },
  {
    id: AccountsTableColumns.SUBS_PRICE,
    fieldName: 'subscriptionPrice',
    label: 'Subscription Price',
    align: 'center',
    width: '15%',
    isFixed: true,
  },
  {
    id: AccountsTableColumns.SESSION_STATUS,
    fieldName: 'sessionStatus',
    label: 'Session Status',
    align: 'center',
    width: '35%',
    isFixed: true,
  },
  //{
  //  id: AccountsTableColumns.AI_STATUS,
  //  fieldName: 'training',
  //  label: 'AI Status',
  //  align: 'center',
  //  width: 200,
  //},
  {
    id: AccountsTableColumns.ACTIONS,
    fieldName: 'actions',
    label: 'Actions',
    width: '20%',
    isFixed: true,
  },
];

interface AccountsProps {
  className?: string;
}

const Accounts = memo(({ className }: AccountsProps) => {
  const navigate = useNavigate();

  const loading = useAppSelector(getCreatorsLoading);
  const inited = useAppSelector(getCreatorsInited);
  const { isSuperAdminPermission } = usePermissions();
  const dispatch = useAppDispatch();

  const { creators: items } = useChatterCharms();

  const { setOfUrl, setActiveCreator, setActivateLoginCreatorId, setActivateLoginSessionId, setIsActivatingModel } =
    useElectronContext();

  const [connectLoadingList, setConnectLoadingList] = useState<string[]>([]);

  const onEditAccount = useCallback((id: string) => {
    navigate(`${RoutePath.editAccount}?id=${id}`);
  }, []);

  const onClickActionButton = useCallback(async (creator: ICreators) => {
    const { sessionStatus, id } = creator;
    if (!id) {
      return;
    }

    if (sessionStatus === CreatorsSessionStatus.INACTIVE || sessionStatus === CreatorsSessionStatus.NOT_VERIFIED) {
      setConnectLoadingList((prev) => [...prev, id]);

      const { sessionId, creatorId } = await creatorsService.connectCreator(id);

      setConnectLoadingList((prev) => prev.filter((item) => item !== id));
      setOfUrl(WebviewURL.OF);
      setActivateLoginCreatorId(creatorId);
      setActivateLoginSessionId(sessionId);
    } else {
      setIsActivatingModel(true);
      setActiveCreator(creator);
      setOfUrl(WebviewURL.OF);
    }
  }, []);

  const getAITrainingStatus = (item: ICreators) => {
    const isActiveSubscription =
      item?.subscription?.status === SubscriptionStatus.PAID || item?.subscription?.plan === SubscriptionStatus.TRIAL;

    const trainingValue = Number(item.training?.status || 0);

    if ((item.sessionStatus === CreatorsSessionStatus.INACTIVE && trainingValue > 0) || !isActiveSubscription) {
      return AITrainingStatus.AWAITING;
    }

    if (item.sessionStatus === CreatorsSessionStatus.ACTIVE && trainingValue > 0) {
      return AITrainingStatus.IN_PROGRESS;
    }

    return AITrainingStatus.NOT_STARTED;
  };

  const onRenderColumnItem = useCallback(
    (item: ICreators, index: number, column: IColumn) => {
      const fieldName = column?.fieldName;

      switch (column?.id) {
        case AccountsTableColumns.CREATOR: {
          const { name, imageLink, ofNick, id } = item;

          return (
            <div className={styles.creator}>
              <div className={styles.avatar}>{imageLink && <img src={imageLink} alt="Avatar" />}</div>
              <div className={styles.info}>
                <p>{name}</p>
                <p className={styles.secondary}>{`@${ofNick}`}</p>
                {isSuperAdminPermission && (
                  <Button
                    onClick={() => id && onEditAccount(id)}
                    className={styles.manageBtn}
                    theme={ThemeButton.DARK_OUTLINE}
                  >
                    Manage
                  </Button>
                )}
              </div>
            </div>
          );
        }
        case AccountsTableColumns.SESSION_STATUS: {
          const { sessionStatus = CreatorsSessionStatus.INACTIVE, subscription } = item;
          const priorityStatus =
            subscription?.status === SubscriptionStatus.UNPAID ? CreatorsSessionStatus.UNPAID : sessionStatus;

          const { name, className } = creatorsSessionConfig[priorityStatus];
          return <span className={classNames(styles.sessionStatus, {}, [styles[className]])}>{name}</span>;
        }
        case AccountsTableColumns.AI_STATUS: {
          const status = getAITrainingStatus(item);
          return (
            <p className={styles.training}>
              {status} <span>{status !== AITrainingStatus.NOT_STARTED && `${item.training?.status || 0}%`}</span>
            </p>
          );
        }
        case AccountsTableColumns.ACTIONS: {
          const { sessionStatus = CreatorsSessionStatus.NOT_VERIFIED, id, proxy, proxyId, subscription } = item;

          const action =
            sessionStatus === CreatorsSessionStatus.INACTIVE ? CreatorsActions.RECONNECT : CreatorsActions.OPEN_OF;
          const isUnpaid = subscription?.status === SubscriptionStatus.UNPAID;
          const isSync = sessionStatus === CreatorsSessionStatus.DATA_SYNC;
          const disabled = isUnpaid || !proxy || proxyId === NOT_SET_PROXY;

          const tooltipTitle = isUnpaid
            ? 'Your subscription has expired, go to billing to renew it'
            : 'Your proxy is not active.';

          return (
            <div className={styles.actions}>
              <Button
                onClick={() => onClickActionButton(item)}
                className={styles.ofButton}
                theme={ThemeButton.PRIMARY}
                loading={connectLoadingList.includes(id!)}
                disabled={disabled || isSync}
              >
                {action}
              </Button>
              {disabled && (
                <Tooltip className={styles.tooltip} placement="top" title={tooltipTitle}>
                  <InfoIcon className={styles.info} />
                </Tooltip>
              )}
            </div>
          );
        }
        case AccountsTableColumns.SUBS_PRICE: {
          const { subscriptionPrice = 0 } = item;
          return <p>{+subscriptionPrice === 0 ? 'Free' : `Paid ($${subscriptionPrice})`}</p>;
        }
        default: {
          if (fieldName && item) {
            return <p>{(item as any)[fieldName]}</p>;
          }
          return <></>;
        }
      }
    },
    [isSuperAdminPermission, connectLoadingList, onClickActionButton],
  );

  const connectButton = useMemo(
    () =>
      isSuperAdminPermission ? (
        <Button
          theme={ThemeButton.PRIMARY}
          className={styles.connectBtn}
          onClick={() => dispatch(modalActions.setModalType({ type: ModalType.СONNECT_CREATOR }))}
        >
          Connect
        </Button>
      ) : (
        <></>
      ),
    [isSuperAdminPermission],
  );

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Accounts</h1>
        {items.length > 0 && connectButton}
      </div>
      <div className={styles.content}>
        <CustomizedTable
          items={items}
          columns={columns}
          onRenderColumnItem={onRenderColumnItem}
          className={styles.table}
          loading={loading && !inited}
          emptyElement={connectButton}
        />
      </div>
    </div>
  );
});

export default Accounts;
