import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/billing/successful.svg';

import styles from './SuccessfullyUpdated.module.scss';

interface SuccessfullyUpdatedProps {
  data?: { text?: string };
  onClose?: () => void;
}

export const SuccessfullyUpdated = ({ data = {}, onClose }: SuccessfullyUpdatedProps) => {
  const { text } = data;

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>
      <div className={styles.body}>
        <SuccessIcon className={styles.icon} />
        <p className={styles.text}>{text || 'Successfully updated!'}</p>
      </div>
    </div>
  );
};
