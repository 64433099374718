import React from 'react';
import { Stack } from '@mui/material';

import ElectronSidebar from '../ElectronSidebar';

import useStyles from './useStyles';

interface IElectronWrapper {
  children: React.ReactNode;
}

const ElectronWrapper = ({ children }: IElectronWrapper) => {
  const styles = useStyles();

  return (
    <Stack display="flex" flexDirection="row" sx={styles.root}>
      <ElectronSidebar />
      {children}
    </Stack>
  );
};

export default ElectronWrapper;
