import { useMemo } from 'react';
import { min, isSameDay } from 'date-fns';

import { useAppSelector } from 'store';
import { getBillingSubscriptions } from 'store/reducers/billing';

import useCreators from './useCreators';

const useSubscriptions = () => {
  const { items: allListSubscriptions } = useAppSelector(getBillingSubscriptions);
  const { creatorMap } = useCreators();

  const subscriptions = useMemo(() => {
    return allListSubscriptions.filter(({ creatorId }) => creatorMap?.get(creatorId));
  }, [creatorMap, allListSubscriptions]);

  const nextPaymentDate = useMemo(() => {
    const endDateList = subscriptions.map(({ endDate }) => new Date(endDate));
    return endDateList.length > 0 ? min(endDateList) : undefined;
  }, [subscriptions]);

  const nextPaymentAccounts = useMemo(() => {
    if (!nextPaymentDate) return;

    const list = subscriptions.filter(({ endDate }) => isSameDay(nextPaymentDate, new Date(endDate)));
    return list.map(({ creatorUsername }) => creatorUsername);
  }, [subscriptions, nextPaymentDate]);

  return {
    subscriptions,
    nextPaymentDate,
    nextPaymentAccounts,
  };
};

export default useSubscriptions;
