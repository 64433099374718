import { ReactComponent as NotFoundPageIcon } from 'assets/images/404.svg';

import Layout from '../Layout/Layout';

import styles from './NotFoundScreen.module.scss';

const NotFoundScreen = () => {
  return (
    <Layout>
      <div className={styles.section}>
        <div className={styles.icon}>
          <NotFoundPageIcon />
        </div>
        <h1>Oops something went wrong</h1>
        <p>Sorry we can't find the page you're looking for</p>
      </div>
    </Layout>
  );
};

export default NotFoundScreen;
