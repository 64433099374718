import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import DashboardTab from './DashboardTab';
import { IDashboardTabs } from './interfaces';
import useStyles from './useStyles';

const DashboardTabs = ({ activeTab, tabs, onAddTab, onDeleteTab, onChange, allowAddMore }: IDashboardTabs) => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.root}>
        {tabs.map((tab, key) => (
          <DashboardTab
            key={tab.id}
            tab={{ ...tab, title: `Tab ${key + 1}` }}
            isActive={activeTab.id === tab.id}
            onChange={onChange}
            onDeleteTab={onDeleteTab}
          />
        ))}
        {allowAddMore ? (
          <IconButton onClick={onAddTab} sx={styles.addButton}>
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default DashboardTabs;
