import React, { useState, useCallback, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';

import { ReactComponent as PlusIcon } from 'assets/images/add.svg';

import { membersActions } from '../../../store/reducers/members';
import { modalActions } from '../../../store/reducers/modal';
import { ModalType } from '../../../constants/modal';
import { MemberAction } from '../interfaces';
import { IMember } from '../../../models/members';
import { useAppDispatch } from '../../../store';
import styles from '../Members.module.scss';

interface IActionsCell {
  item: IMember;
  onActiveActionChange: React.Dispatch<React.SetStateAction<MemberAction | null>>;
}

const ActionCell = ({ item, onActiveActionChange }: IActionsCell) => {
  const dispatch = useAppDispatch();

  const [actionMenuElement, setActionMenuElement] = useState<Element | null>(null);

  const isOpenActionMenu = useMemo(() => Boolean(actionMenuElement), [actionMenuElement]);

  const onToggleActionMenu = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setActionMenuElement((prev) => (!prev ? event.currentTarget : null));
  }, []);

  return (
    <div className={styles.action}>
      <MoreVertIcon onClick={onToggleActionMenu} />
      <Popover
        open={isOpenActionMenu}
        anchorEl={actionMenuElement}
        onClose={onToggleActionMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.actionMenu} onClick={() => setActionMenuElement(null)}>
          <button
            className={styles.item}
            onClick={() => {
              dispatch(membersActions.setMemberActiveModal(item));
              dispatch(modalActions.setModalType({ type: ModalType.ASSIGN_CREATOR }));
            }}
          >
            <PlusIcon className={styles.assignIcon} /> Assign Account
          </button>
          <button
            className={styles.item}
            onClick={() => {
              dispatch(membersActions.setMemberActiveModal(item));
              dispatch(modalActions.setModalType({ type: ModalType.MEMBERS_ROLE }));
            }}
          >
            <GroupIcon /> Change Role
          </button>
          <button
            className={styles.item}
            onClick={() => {
              dispatch(membersActions.setMemberActiveModal(item));
              onActiveActionChange(MemberAction.Delete);
            }}
          >
            <DeleteIcon /> Delete
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default ActionCell;
