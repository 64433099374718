import { useTheme } from '@mui/material';

import { ScreenSizes, ElectronSidebarSize } from '../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: ElectronSidebarSize.Big,
      background: '#363A3E',
      borderRight: '1px solid #515151',
      color: 'var(--color-white)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '16px',
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 1,
      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: ElectronSidebarSize.Small,
      },
    },
    home: {
      textTransform: 'none',
      width: '100%',
      minWidth: 'unset',
      borderBottom: '1px solid #515151',
      padding: '30px 0 16px',
      marginBottom: '62px',
      '& svg': {
        width: '30px',
        height: '30px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
    },

    button: (isActive: boolean) => ({
      color: 'var(--color-white)',
      textTransform: 'none',
      width: '45px',
      height: '45px',
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: '#D3D3D3',
      minWidth: 'unset',
      padding: 0,
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: '30px',
        height: '30px',
      },
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      },
      position: 'relative',
      ...(isActive
        ? { border: '2px solid #5BC0EB' }
        : {
            '&:after': {
              content: '""',
              position: 'absolute',
              background: 'rgba(255,255,255,0.5)',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          }),
    }),
  };
};

export default useStyles;
