import * as Sentry from '@sentry/react';

import { checkIsLocalhost } from 'utils/path';

export enum SentryEnvironment {
  Development = 'development',
  Local = 'local',
  Production = 'production',
}

export const getSentryEnvironment = (): SentryEnvironment => {
  const isLocalhost = checkIsLocalhost();

  if (isLocalhost) return SentryEnvironment.Local;

  const isDev = process.env.REACT_APP_CC_ENV === 'DEV';

  if (isDev) return SentryEnvironment.Development;

  return SentryEnvironment.Production;
};

export const captureExceptionWithErrorCode = (exception: any, errorCode: string) => {
  Sentry.captureException(exception, (scope) => {
    scope.setTag('errorCode', errorCode);
    return scope;
  });
};

export const captureMessageWithTag = (message: string, tag: string) => {
  console.log('message', message);
  Sentry.withScope((scope) => {
    scope.setTag('messageTag', tag);
    Sentry.captureMessage(message);
  });
};
