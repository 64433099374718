import { useEffect } from 'react';

import { CreatorsSessionStatus } from 'constants/creators';
import { modalActions } from 'store/reducers/modal';
import { ModalType } from 'constants/modal';

import { useElectronContext } from '../providers';
import { creatorsService } from '../../../services/CreatorsService';
import { loadCreatorsList } from '../../../store/reducers/creators';
import { useAppDispatch } from '../../../store';
import { useChatterCharms } from '../../../providers/ChatterCharmsProvider';

const useMainListener = () => {
  const dispatch = useAppDispatch();

  const { creators: creatorsList } = useChatterCharms();

  const {
    activateLoginCreatorId,
    activateLoginSessionId,
    setActivateLoginSessionId,
    setActivateLoginCreatorId,
    setActivateLoginActivated,
    setActiveCreator,
    deactivateCreatorSession,
    backToAdmin,
  } = useElectronContext();

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'creator-success-login';

    const cb = () => {
      window.electronAPI.send(`${eventName}-response`, {
        payload: {
          creatorId: activateLoginCreatorId,
          sessionId: activateLoginSessionId,
        },
      });
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activateLoginCreatorId, activateLoginSessionId]);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'clear-creator-session-successful';

    const cb = () => {
      const creator = creatorsList.find((item) => item.id === activateLoginCreatorId);

      setActivateLoginSessionId(null);
      setActivateLoginCreatorId(null);

      if (creator) {
        setActiveCreator(creator);
        setActivateLoginActivated(true);
      }

      dispatch(loadCreatorsList());

      window.electronAPI.send(`${eventName}-response`, {
        payload: true,
      });
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activateLoginCreatorId, creatorsList]);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }
    const eventName = 'activate-creator-session-url';

    const cb = async (_: IpcRendererEvent, payload: any) => {
      try {
        const { creatorId, ...rest } = payload;
        const { sessionStatus } = creatorsList.find((item) => item.id === creatorId) || {};

        if (sessionStatus === CreatorsSessionStatus.NOT_VERIFIED || sessionStatus === CreatorsSessionStatus.DATA_SYNC) {
          backToAdmin();
          dispatch(modalActions.setModalType({ type: ModalType.SUCCESSFUL_INFO }));
        }

        await creatorsService.activateCreatorSessionUrl(creatorId, rest);
      } catch (error: any) {
        console.log({ error });
      }

      window.electronAPI.send(`${eventName}-response`, {
        payload: true,
      });
    };
    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activateLoginCreatorId]);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }
    const eventName = 'deactivate-creator';

    const cb = async (_: IpcRendererEvent, payload: any) => {
      try {
        const { creatorId, sessionId } = payload;
        await deactivateCreatorSession({ creatorId, sessionId, reason: 'SAVING_SESSION' });
      } catch (error: any) {
        console.log({ error });
      }

      window.electronAPI.send(`${eventName}-response`, {
        payload: true,
      });
    };
    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [deactivateCreatorSession]);
};

export default useMainListener;
