import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { components } from 'react-select';
import Select, { SelectProps } from '@mui/material/Select';

import { classNames } from 'utils/classNames';
import { ThemeField } from 'models/fields';

import { NamedField } from '../NamedField/NamedField';

import styles from './Dropdown.module.scss';

type IDropdownProps = Omit<SelectProps, 'Variant' | 'variant'> & {
  theme?: ThemeField;
  fieldName?: string;
  mandatory?: boolean;
  className?: string;
  options?: string[];
  onRenderItem?: (item: string) => JSX.Element;
};

const Dropdown: React.FC<IDropdownProps> = ({
  theme = ThemeField.DARK,
  fieldName,
  mandatory,
  className,
  options,
  onRenderItem,
  ...props
}) => {
  const { DownChevron } = components;

  return (
    <NamedField
      fieldName={fieldName}
      mandatory={mandatory}
      className={classNames(styles.field, {}, [className, theme])}
    >
      <Select
        displayEmpty
        IconComponent={DownChevron}
        MenuProps={{
          className: styles.menu,
        }}
        {...props}
      >
        {options?.map((item) => (
          <MenuItem key={item} value={item}>
            {onRenderItem?.(item) || item}
          </MenuItem>
        ))}
      </Select>
    </NamedField>
  );
};

export default Dropdown;
