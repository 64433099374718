import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ModalType } from 'constants/modal';
import { modalActions } from 'store/reducers/modal';

import { useAppDispatch } from '../store';

const withModalParam =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: any) => {
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    const modalName = searchParams.get('modal');

    useEffect(() => {
      if (modalName === null) {
        return;
      }

      const modal = modalName.toUpperCase().replace('-', '_');
      const indexOfModal = Object.keys(ModalType).indexOf(modal);

      if (indexOfModal === -1) {
        return;
      }

      dispatch(modalActions.setModalType({ type: Object.values(ModalType)[indexOfModal] }));
    }, [modalName]);

    return <Component {...props} />;
  };

export default withModalParam;
