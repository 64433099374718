import React, { useCallback, useEffect, useRef } from 'react';

import { creatorsService } from 'services/CreatorsService';
import { delay } from 'utils/delay';

import { useElectronContext } from '../../../../providers';
import { useDashboardContext } from '../../providers';

const useCreatorAutoLogin = ({ isReady, ref }: { isReady: boolean; ref: React.MutableRefObject<any | null> }) => {
  const unmountedRef = useRef(false);

  const {
    activeCreator,
    activateLoginCreatorId,
    activateLoginSessionId,
    setIsActivatingModel,
    deactivateCreatorSession,
  } = useElectronContext();

  const { creatorSession, isSessionActive, handleLoginError, setIsWebviewActive, isWebviewActive } =
    useDashboardContext();

  const handleSetLocalStorage = useCallback(async (bcTokenSha: string, _grecaptcha: string) => {
    if (!ref.current || unmountedRef.current) {
      return;
    }

    await ref.current?.executeJavaScript(
      `localStorage.setItem("bcTokenSha", "${bcTokenSha}"); localStorage.setItem("_grecaptch", "${_grecaptcha}"); `,
    );

    return;
  }, []);

  const handleReloadPage = useCallback(async () => {
    if (!ref.current || unmountedRef.current) {
      return;
    }

    await ref.current.executeJavaScript(`location.reload();`);
  }, []);

  const handleCheckIfLoginForm = useCallback(async () => {
    if (!ref.current || unmountedRef.current) {
      return false;
    }

    return ref?.current?.executeJavaScript(`document.querySelector('.login_content') ? true : false`);
  }, []);

  useEffect(() => {
    if (!isReady || !activeCreator?.id || !isSessionActive || creatorSession == null || isWebviewActive) {
      return;
    }

    const setTokenAndRecaptcha = async ({ _grecaptcha, bcTokenSha }: { _grecaptcha?: string; bcTokenSha?: string }) => {
      if (unmountedRef.current) {
        return null;
      }
      console.log('LOGIN - 2: Set bcTokenSha or _grecaptcha');
      if (bcTokenSha && _grecaptcha) {
        await handleSetLocalStorage(bcTokenSha, _grecaptcha);
        return true;
      } else {
        throw new Error('bcTokenSha or _grecaptcha');
      }
    };

    const reloadPage = async () => {
      if (unmountedRef.current) {
        return;
      }

      console.log('LOGIN - 3: RELOADING');
      await handleReloadPage();
      await delay(1);
      if (unmountedRef.current) {
        return;
      }
      await handleReloadPage();
      await delay(3);
    };

    const validateSession = async (creatorId: string) => {
      if (unmountedRef.current) {
        return;
      }
      const { isLoggedIn } = await creatorsService.sessionValidate(creatorId);
      console.log(`LOGIN - 4: if login on backend - ${isLoggedIn}`);

      return isLoggedIn;
    };

    const checkIfLoginForm = async () => {
      if (unmountedRef.current) {
        return null;
      }

      const ifLoginForm = await handleCheckIfLoginForm();
      console.log(`LOGIN - 5: final check form: ${ifLoginForm}`);

      return ifLoginForm;
    };

    const run = async () => {
      console.log('LOGIN: Run');

      const { sessionId, _grecaptcha, bcTokenSha } = creatorSession;
      const { id: creatorId } = activeCreator;

      try {
        await setTokenAndRecaptcha({ _grecaptcha, bcTokenSha });

        await reloadPage();

        const isLoggedIn = await validateSession(creatorId);
        if (isLoggedIn === false && !unmountedRef.current) {
          deactivateCreatorSession({ creatorId, sessionId, reason: 'SESSION_VALIDATE' });
        }

        const ifLoginForm = await checkIfLoginForm();
        if (ifLoginForm && !unmountedRef.current) {
          deactivateCreatorSession({ creatorId, sessionId, reason: 'SESSION_VALIDATE_LOGIN_FORM' });
        }

        if (!unmountedRef.current) {
          setIsActivatingModel(false);
          setIsWebviewActive(true);
        }
      } catch (error: any) {
        handleLoginError({ error, sessionId, creatorId });
      }

      console.log('LOGIN: End');
    };

    run();
  }, [
    activeCreator,
    isReady,
    handleSetLocalStorage,
    handleReloadPage,
    deactivateCreatorSession,
    handleCheckIfLoginForm,
    creatorSession,
    isSessionActive,
    isWebviewActive,
  ]);

  useEffect(() => {
    if (!activateLoginCreatorId || !activateLoginSessionId) {
      return;
    }

    const run = async () => {
      console.log('Should be fixed invokeCookieSet');
      // await handleSetLocalStorage('', '');
      await window.electronAPI.invokeCookieSet({
        payload: [],
      });
    };

    run();
  }, [activateLoginCreatorId, activateLoginSessionId]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);
};

export default useCreatorAutoLogin;
