import React from 'react';
import { Box } from '@mui/material';

import { useElectronContext } from '../../providers';
import { BrandCanvas } from '../../../../components/BrandCanvas/BrandCanvas';
import EmojiDashboard from '../EmojiDashboard';

import OFDashboardView from './OFDashboardView';
import useStyles from './useStyles';

const OFDashboard = () => {
  const styles = useStyles();
  const { isActivatingModel, activeCreator, activateLoginCreatorId } = useElectronContext();

  return (
    <>
      <OFDashboardView key={activateLoginCreatorId ?? activeCreator?.id} />
      <EmojiDashboard />
      {isActivatingModel ? (
        <Box sx={styles.loader}>
          <BrandCanvas isInitProccess />
        </Box>
      ) : null}
    </>
  );
};

export default OFDashboard;
