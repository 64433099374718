import { Props } from 'react-apexcharts';

import variables from 'assets/styles/variables.module.scss';

export const colors = ['#5BC0EB40', '#5BC0EB60', '#5BC0EB80', '#5DC9F1'];

export default {
  options: {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        style: {
          colors: '#A3ADB1',
        },
        formatter: (value) => String(value)?.substring(0, 3), // TODO Fix
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: '#A3ADB1',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    colors: ['#5BC0EB'],
    plotOptions: {
      heatmap: {
        enableShades: false,
        radius: 6,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: variables.primaryColor,
            },
          ],
        },
      },
    },
    stroke: {
      width: 4,
      colors: [variables.secondaryColor],
    },
  },
  series: [
    {
      data: [0],
    },
  ],
} as Props;
