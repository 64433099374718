import { SyntheticEvent } from 'react';

import fallbackSrc from 'assets/images/fallback-image.svg';

interface IMediaImage {
  src: string;
  alt: string;
}

const MediaImage = ({ src, alt }: IMediaImage) => {
  const handleError = (event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.target as HTMLImageElement;
    img.src = fallbackSrc;
    img.onerror = null;
  };

  return <img src={src} alt={alt} onError={handleError} />;
};

export default MediaImage;
