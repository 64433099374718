import React, { MutableRefObject, useEffect, useRef } from 'react';

import { IMedia } from 'models/media';
import { useElectronContext } from 'modules/electron/providers';

import { useWebviewContext } from '../../../../../../../providers';

const INITIAL_POLLING_INTERVAL = 60 * 1000;
const LIMIT = 10;

interface IUseHistoryPolling {
  loadMedia: (data: { creatorId: string; chatterId: string; offset: number; limit?: number }) => Promise<IMedia[]>;
  setMedia: React.Dispatch<React.SetStateAction<IMedia[]>>;
  count: number;
}

const useHistoryPolling = ({ loadMedia, setMedia, count }: IUseHistoryPolling) => {
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;

  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const stopInterval = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    if (!activeChatterId || !activeCreator?.id || !count) {
      return;
    }

    let mounted = true;

    timerRef.current = setInterval(async () => {
      //media url is available for 60 sec, to make sure that url is valid we should re-fetch when history is open
      const num = Math.ceil(count / LIMIT);

      const requests = Array.from({ length: num }, (_, i) =>
        loadMedia.bind(null, {
          creatorId: activeCreator?.id,
          chatterId: activeChatterId,
          offset: i * LIMIT,
          limit: LIMIT,
        }),
      );

      try {
        const mediaResponse = await Promise.all(requests.map((request) => request()));

        if (!mounted) {
          return;
        }

        const newMediaList = mediaResponse.flat(1);

        if (!newMediaList.length) {
          return;
        }

        setMedia(newMediaList);
      } catch (e) {}
    }, INITIAL_POLLING_INTERVAL);

    return () => {
      mounted = false;
      stopInterval();
    };
  }, [count, loadMedia, setMedia, activeChatterId, activeCreator?.id]);

  useEffect(() => {
    return stopInterval;
  }, []);
};

export default useHistoryPolling;
