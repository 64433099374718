import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import Chart from 'react-apexcharts';

import { classNames } from 'utils/classNames';
import { IEarningsStats, IEarningsStatsItem, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { useIsVisible } from 'hooks/useIsVisible';
import { getCurrentTimezoneOffsetInSec } from 'utils/date';

import styles from './EarningsStats.module.scss';
import initialOptions from './settings';

interface EarningsStatsProps {
  filters: StatisticFilters;
  className?: string;
}

export const EarningsStats = ({ filters, className }: EarningsStatsProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IEarningsStats>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersectedOnce, resetIntersectedOnce } = useIsVisible(containerRef);
  const timezoneOffset = getCurrentTimezoneOffsetInSec();

  const getAsyncAndConvertChartData = async () => {
    try {
      const response = await statisticsService.getCreatorEarningStats({ ...filters, timezoneOffset });
      setData(response);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      if (!isIntersectedOnce) return;
      await getAsyncAndConvertChartData();
      setLoading(false);
    })();
  }, [isIntersectedOnce]);

  useEffect(() => {
    setLoading(true);
    resetIntersectedOnce();
  }, [filters]);

  const renderItem = (title: string, info: IEarningsStatsItem | undefined) => {
    const { delta, chart } = info || {};
    const roundedDelta = (delta && parseInt(delta)) || 0;
    const isLossStatus = roundedDelta < 0;

    const settings = JSON.parse(JSON.stringify(initialOptions));
    const series = chart?.map((item) => Number(item.sum));
    const isSingeValue = series?.length === 1;
    const commonSum = series?.reduce((sum, value) => sum + (value || 0), 0);

    if (isSingeValue) {
      series.push(series[0]);
    }

    settings.series[0].data = series;

    const colors = settings.options.stroke.colors;
    isLossStatus && (settings.options.stroke.colors = [colors[1]]);
    !commonSum && (settings.options.stroke.colors = [colors[2]]);

    const prefixSymbol = roundedDelta > 0 ? '+' : '';

    const deltaValue = !commonSum ? '0' : `${prefixSymbol}${roundedDelta}%`;

    return (
      <div className={styles.item}>
        <div className={styles.info}>
          {loading ? (
            <Skeleton />
          ) : (
            <div className={styles.earningValues} style={{ color: settings.options.stroke.colors[0] }}>
              {deltaValue}
            </div>
          )}
          <div className={styles.typeTitle}>{title}</div>
        </div>
        <div className={styles.chart}>
          <Chart width={120} height={60} options={settings.options} series={settings.series} type="line" />
        </div>
      </div>
    );
  };

  return (
    <div ref={containerRef} className={classNames(styles.container, {}, [className])}>
      {renderItem('Subscriptions', data?.subscription)}
      {renderItem('Messages', data?.message)}
      {renderItem('Recurring Subscription', data?.rSubscription)}
      {renderItem('Tips', data?.tip)}
      {renderItem('Stream', data?.stream)}
      {renderItem('Posts', data?.post)}
    </div>
  );
};
