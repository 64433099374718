import { useCallback } from 'react';

import { Button, ThemeButton } from 'components/Button/Button';
import { ReactComponent as BrandHeaderIcon } from 'assets/images/defaultBrandLogo.svg';
import { useAppDispatch } from 'store';
import { logoutApp } from 'store/reducers/auth';

import Layout from '../Layout/Layout';

import styles from './NoPermissionsScreen.module.scss';

const NoPermissionsScreen = () => {
  const dispatch = useAppDispatch();

  const onLogout = useCallback(() => {
    dispatch(logoutApp());
  }, []);

  return (
    <Layout>
      <div className={styles.box}>
        <h1>
          <BrandHeaderIcon />
          <span>Chatter</span>Charms
        </h1>
        <p className={styles.title}>You don't have permission</p>
        <p className={styles.description}>Contact the administrator so that he can give them to you</p>
        <Button className={styles.logOut} theme={ThemeButton.PRIMARY} onClick={onLogout}>
          Log out
        </Button>
      </div>
    </Layout>
  );
};

export default NoPermissionsScreen;
