import { RefObject, useCallback, useEffect, useState } from 'react';

export function useIsVisible(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [isIntersectedOnce, setIsIntersectedOnce] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);

      if (entry.isIntersecting && !isIntersectedOnce) {
        setIsIntersectedOnce(true);
      }
    });

    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, isIntersectedOnce]);

  const resetIntersectedOnce = useCallback(() => {
    setIsIntersectedOnce(false);
  }, []);

  return { isIntersecting, isIntersectedOnce, resetIntersectedOnce };
}
