import { memo } from 'react';

import { Button, ThemeButton } from 'components/Button/Button';

import styles from './SuccessfulInfo.module.scss';

interface SuccessfulInfoProps {
  onClose?: () => void;
}

export const SuccessfulInfo = memo(({ onClose }: SuccessfulInfoProps) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Success!</h2>
      <p className={styles.subtitle}>Please note:</p>
      <div className={styles.description}>
        The account typically links within 5-15 minutes. Afterward, data synchronization might take some time depending
        on the size of the Creator's account. Once your account is ready, the Session Status will change to Active, and
        you can start using all ChatterCharms features!
      </div>
      <div className={styles.actions}>
        <Button theme={ThemeButton.PRIMARY} onClick={onClose}>
          Ok
        </Button>
      </div>
    </div>
  );
});
