import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from '../../store';
import { RoutePath } from '../../config/routeConfig';
import {
  creatorsActions,
  getCreator,
  loadCreatorById,
  loadCreatorsDictionary,
  loadCreatorsList,
} from '../../store/reducers/creators';
import Loader from '../../modules/electron/components/Loader';
import { Button, ThemeButton } from '../Button/Button';
import ConfirmationDialog from '../ConfirmationDialog';
import { creatorsService } from '../../services/CreatorsService';

import { CurrentPlan } from './CurrentPlan/CurrentPlan';
import { ProxySettings } from './ProxySettings/ProxySettings';
import { AssignedMembers } from './AssignedMembers/AssignedMembers';
import { AccountInfoForm } from './AccountInfoForm/AccountInfoForm';
import styles from './EditAccount.module.scss';

export const EditAccount = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const creator = useAppSelector(getCreator);
  const { enqueueSnackbar } = useSnackbar();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationDialog, setIsConfirmationDialog] = useState(false);

  const accountId = useMemo(() => searchParams.get('id'), [searchParams]);

  const onDeleteCreator = useCallback(async () => {
    if (!accountId) return;

    setIsDeleting(true);
    await creatorsService.deleteCreator(accountId);
    dispatch(loadCreatorsList());
    setIsDeleting(false);
    enqueueSnackbar('The account was successfully deleted', { variant: 'success' });
    navigate(RoutePath.accounts);
  }, [accountId]);

  useEffect(() => {
    if (accountId) {
      dispatch(loadCreatorsDictionary());
      dispatch(loadCreatorById({ id: accountId }));
    } else {
      navigate(RoutePath.settings);
    }

    return () => {
      dispatch(creatorsActions.setSelectedCreator(undefined));
    };
  }, [accountId]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Edit Account</h1>
          <Button
            onClick={() => setIsConfirmationDialog(true)}
            className={styles.btn}
            theme={ThemeButton.DARK_OUTLINE}
            disabled={isDeleting}
            loading={isDeleting}
          >
            Delete creator
          </Button>
        </div>
        {!creator ? (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        ) : (
          <div className={styles.forms}>
            <AccountInfoForm data={creator} />
            <div className={styles.rightBox}>
              <CurrentPlan data={creator} />
              <AssignedMembers data={creator} />
              <ProxySettings data={creator} />
            </div>
          </div>
        )}
      </div>
      {isConfirmationDialog ? (
        <ConfirmationDialog
          title="Confirm removing account"
          message="Are you sure that you want to remove the account?"
          cancelLabel="Cancel"
          confirmLabel="Remove"
          onConfirm={onDeleteCreator}
          onClose={() => setIsConfirmationDialog(false)}
        />
      ) : null}
    </>
  );
};
