import { memo, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import Chart, { Props } from 'react-apexcharts';

import { StatisticFilters } from 'models/statistics';
import { debounce } from 'utils/debounce';
import useLineStatistics from 'components/Statistics/hooks/useLineStatistics';

import initialSettings from './settings';

const DEBOUNCE_GET_INTERVAL = 1000;

interface LineChartProps {
  filters: StatisticFilters;
}

const LineChart = ({ filters }: LineChartProps) => {
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState(initialSettings);
  const { getConvertedLineSettings } = useLineStatistics();

  // Memoization of debounce to avoid dependence on re-rendering when changing filters
  const debouncedGetChartData = useMemo(
    () =>
      debounce(async (filters: StatisticFilters, initialSettings: Props) => {
        const convertedSettings = await getConvertedLineSettings(filters, initialSettings);
        setSettings(convertedSettings);
        setLoading(false);
      }, DEBOUNCE_GET_INTERVAL),
    [],
  );

  useEffect(() => {
    setLoading(true);
    debouncedGetChartData(filters, initialSettings);
  }, [filters]);

  if (loading) return <Skeleton height={400} />;

  return <Chart options={settings.options} series={settings.series} height={400} type="line" />;
};

export default memo(LineChart);
