import React, { useCallback, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { logEvent } from 'utils/analytics';
import { GAAction, GACategory } from 'constants/analytics';

import { captureMessageWithTag } from '../../../../utils/sentry';
import { scrappingService } from '../../../../services/ScrapingService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getUserAuthData } from '../../../../store/reducers/auth';
import useFetch from '../../../../hooks/useFetch';
import { creatorsService } from '../../../../services/CreatorsService';
import { loadCreatorsList } from '../../../../store/reducers/creators';
import { ICreators } from '../../../../models/creators';
import { useChatterCharms } from '../../../../providers/ChatterCharmsProvider';
import { CreatorsSessionStatus } from '../../../../constants/creators';

import { IContentContext, IContentProviderProps } from './interfaces';

const DefaultContext = {} as IContentContext;

export const ContentContext = React.createContext<IContentContext>(DefaultContext);

export const useElectronContext = () => React.useContext(ContentContext);

export const ElectronProvider = ({ children }: IContentProviderProps) => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(getUserAuthData);

  const { creators } = useChatterCharms();

  const [activeCreator, setActiveCreator] = useState<ICreators | null>(null);
  const [activeCreatorStatus, setActiveCreatorStatus] = useState<CreatorsSessionStatus | ''>('');

  const [ofUrl, setOfUrl] = useState<string>('');

  const [isActivatingModel, setIsActivatingModel] = useState(false);

  //Activation profile on the first login
  const [activateLoginActivated, setActivateLoginActivated] = useState(false);
  const [activateLoginCreatorId, setActivateLoginCreatorId] = useState<null | string>(null);
  const [activateLoginSessionId, setActivateLoginSessionId] = useState<null | string>(null);

  const openOF = useCallback((data: string) => {
    logEvent(GACategory.OF, GAAction.MoveToOF);
    setOfUrl(data);
  }, []);

  const backToAdmin = useCallback(() => {
    setActiveCreator(null);
    logEvent(GACategory.Platform, GAAction.MoveToPlatform);
    setOfUrl('');
    setIsActivatingModel(false);
  }, []);

  const fetchOnlineChatters = useCallback(
    async ({ creatorId }: { creatorId: string }, config: AxiosRequestConfig = {}) => {
      const { subscribers } = await scrappingService.getOnlineSubscribers(creatorId);
      return subscribers ?? [];
    },
    [],
  );

  const {
    data: onlineSubscribers,
    setData: setOnlineSubscribers,
    loading: isOnlineSubscribersLoading,
    callLoadData: loadOnlineChatters,
  } = useFetch(fetchOnlineChatters);

  const deactivateCreatorSession = useCallback(
    async ({ creatorId, sessionId, reason }: { creatorId: string; sessionId: string; reason: string }) => {
      await creatorsService.getDeactivateCreatorSessionUrl(creatorId, {
        sessionId,
      });

      captureMessageWithTag(
        `deactivateCreatorSessionUrl due to ${reason}: creatorId=${creatorId} sessionId=${sessionId}`,
        reason,
      );

      logEvent(GACategory.OF, GAAction.LogOut, `Reason: ${reason}  Creator: ${creatorId} Session: ${sessionId}`, {
        creatorId: creatorId,
        sessionId: sessionId,
        reason: reason,
      });
      backToAdmin();
      dispatch(loadCreatorsList());
    },
    [dispatch, backToAdmin],
  );

  useEffect(() => {
    if (!activeCreator?.id) {
      return;
    }

    //Update activeCreator data if creators is updated
    const newCreatorData = creators.find((item) => item.id === activeCreator?.id);

    setActiveCreatorStatus(newCreatorData?.sessionStatus ?? '');
  }, [creators, activeCreator?.id]);

  useEffect(() => {
    if (!isAuth || !activeCreator || !activeCreator.id) {
      setOnlineSubscribers([]);
      return;
    }

    loadOnlineChatters({ creatorId: activeCreator.id });
  }, [isAuth, activeCreator, loadOnlineChatters]);

  return (
    <ContentContext.Provider
      value={{
        isOnlineSubscribersLoading,
        activeCreator,
        onlineSubscribers: onlineSubscribers ?? [],
        ofUrl,
        activateLoginCreatorId,
        activateLoginSessionId,
        activateLoginActivated,
        setActiveCreator,
        setOfUrl: openOF,
        setActivateLoginSessionId,
        setActivateLoginCreatorId,
        setActivateLoginActivated,

        loadOnlineChatters,
        isActivatingModel,
        setIsActivatingModel,
        backToAdmin,
        deactivateCreatorSession,

        activeCreatorStatus,
        setActiveCreatorStatus,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
