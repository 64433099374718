import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { IForceUpdatedData, IGetChatterInfo } from './interfaces';

const INTERVAL_FORCE_UPDATE = 30 * 1000;

type UseForceUpdateChattersType = (data: { activeChatterId: string | null }) => {
  forceUpdatedData: Record<string, IForceUpdatedData>;
  callForceUpdate: (data: IGetChatterInfo, callback: () => void) => void;
};

const useForceUpdateChatters: UseForceUpdateChattersType = ({ activeChatterId }) => {
  const timerForceUpdateRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout> | null>;

  const [forceUpdatedData, setForceUpdatedData] = useState<Record<string, IForceUpdatedData>>({});

  const clearTimer = useCallback(() => {
    if (timerForceUpdateRef.current) {
      clearTimeout(timerForceUpdateRef.current);
      timerForceUpdateRef.current = null;
    }
  }, []);

  const callForceUpdate = useCallback((props: IGetChatterInfo, callback: () => void) => {
    const { creatorId, chatterId } = props;

    setForceUpdatedData((prevState) => ({
      ...prevState,
      [`${creatorId}:${chatterId}`]: { date: new Date() },
    }));

    clearTimer();
    timerForceUpdateRef.current = setTimeout(callback, INTERVAL_FORCE_UPDATE);
  }, []);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    clearTimer();
  }, [activeChatterId]);

  return { forceUpdatedData, callForceUpdate };
};

export default useForceUpdateChatters;
