import { Props } from 'react-apexcharts';

import { IEarningsHeatmap, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';

const useHeatmapStatistics = () => {
  const findMaxY = (data: IEarningsHeatmap[]) => {
    let maxY = -Infinity;

    data.forEach((day) => {
      day.data.forEach((dataPoint) => {
        const yValue = parseFloat(dataPoint.y);
        if (!isNaN(yValue) && yValue > maxY) {
          maxY = yValue;
        }
      });
    });

    return maxY;
  };

  const areAllYValuesZero = (data: IEarningsHeatmap[]) => {
    return data.every((day) => {
      return day.data.every((dataPoint) => {
        const yValue = parseFloat(dataPoint.y);
        return isNaN(yValue) || yValue === 0;
      });
    });
  };

  const generateRanges = (maxValue: number, colors: string[]) => {
    const numColors = colors.length;
    const rangeSize = maxValue / numColors;

    const ranges = [];
    let from = 1; // because there is a starting color from 0 to 0
    for (let i = 0; i < numColors; i++) {
      const to = (i + 1) * rangeSize;
      ranges.push({ from, to, color: colors[i] });
      from = to;
    }

    return ranges;
  };
  const getConvertedHeatmapSettings = async (filters: StatisticFilters, initialSettings: Props, colors: string[]) => {
    try {
      const { data } = await statisticsService.getCreatorEarningHeatmap(filters);
      const deepUpdatedSettings = JSON.parse(JSON.stringify(initialSettings));
      deepUpdatedSettings.series = data;
      const maxValue = findMaxY(data);
      const ranges = !areAllYValuesZero(data) ? generateRanges(maxValue, colors) : [];
      const defaultRanges = [...deepUpdatedSettings.options.plotOptions.heatmap.colorScale.ranges];
      deepUpdatedSettings.options.plotOptions.heatmap.colorScale.ranges = [...defaultRanges, ...ranges];

      return deepUpdatedSettings;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getConvertedHeatmapSettings,
  };
};

export default useHeatmapStatistics;
