import React, { useEffect, useState } from 'react';

import useActiveChatterInfo from 'modules/electron/hooks/useActiveChatterInfo';
import { useElectronContext } from 'modules/electron/providers';

import { IContentContext, IContentProviderProps } from './interfaces';

const DefaultContext = {} as IContentContext;

export const ContentContext = React.createContext<IContentContext>(DefaultContext);

export const useWebviewContext = () => React.useContext(ContentContext);

export const WebviewProvider = ({ children, onTabChange }: IContentProviderProps) => {
  const { ofUrl, activeCreator } = useElectronContext();

  const [activeChatterId, setActiveChatterId] = useState<string | null>(null);

  const [webviewUrl, setWebviewUrl] = useState<string | null>(null);

  const {
    chatter,
    loading: isChatterLoading,
    getChatterInfo: loadChatterInfo,
    forceUpdatedData: forceUpdatedChatterInfo,
  } = useActiveChatterInfo({ activeCreatorId: activeCreator?.id, activeChatterId: activeChatterId });

  useEffect(() => {
    //Reset Chatter if creator has been changed
    setActiveChatterId(null);
  }, [activeCreator]);

  useEffect(() => {
    setWebviewUrl(null);
  }, [ofUrl]);

  useEffect(() => {
    if (!webviewUrl) {
      return;
    }

    onTabChange({ url: webviewUrl.charAt(0) === '/' ? webviewUrl.substring(1) : webviewUrl });
  }, [webviewUrl, onTabChange]);

  return (
    <ContentContext.Provider
      value={{
        activeChatterId,

        webviewUrl,
        setWebviewUrl,

        setActiveChatterId,
        chatter,
        isChatterLoading,
        loadChatterInfo,

        forceUpdatedChatterInfo,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
