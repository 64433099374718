import { Box } from '@mui/material';

import { ReactComponent as BundleIcon } from 'assets/images/multiple-files.svg';

import useStyles from './useStyles';

const BundleTag = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.bundle}>
      <BundleIcon />
    </Box>
  );
};

export default BundleTag;
