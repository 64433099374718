import React from 'react';
import { Box } from '@mui/material';

import { UserSquare } from '../../../../../icons';

import { IProps, StyleEnum } from './interfaces';
import useStyles from './useStyles';

const OnlineFans = ({ total = 0, style = StyleEnum.Default }: IProps) => {
  const styles = useStyles();

  return (
    <Box sx={styles.root} style={{ color: style ?? 'inherit' }}>
      <UserSquare color={style} />
      <p>Online Fans: {total}</p>
    </Box>
  );
};

export default OnlineFans;
