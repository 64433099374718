import { useTheme } from '@mui/material';

import { ScreenSizes } from 'modules/electron/constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    collapseInit: (isOpen: boolean) => ({
      overflow: isOpen ? 'unset' : 'hidden',
    }),

    collapse: (isOpen: boolean) => ({
      '@keyframes open-collapse': {
        '0%': {
          overflow: 'hidden',
        },
        '100%': {
          overflow: 'unset',
        },
      },
      '@keyframes close-collapse': {
        '0%': {
          overflow: 'unset',
        },
        '100%': {
          overflow: 'hidden',
        },
      },

      transition: 'height 0.2s ease',
      animation: `${isOpen ? 'open-collapse' : 'close-collapse'} 0.2s ease`,
      animationFillMode: 'forwards',
    }),

    content: {
      padding: '28px 0 22px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        padding: '15px 0 15px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: '12px 0 11px',
      },
    },

    button: (isActive: boolean) => ({
      color: isActive ? '#00AFF0' : '#90A5BE',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      textTransform: 'none',
      padding: 0,

      '&:before': {
        position: 'absolute',
        content: '""',
        top: 0,
        bottom: 0,
        left: '-28px',
        width: '3px',
        backgroundColor: isActive ? '#00AFF0' : '#90A5BE',
        [breakpoints.down(ScreenSizes.screenXXl)]: {
          left: '-21px',
          width: '2px',
        },
        [breakpoints.down(ScreenSizes.screenXl)]: {
          left: '-15px',
          width: '2px',
        },
      },
    }),

    title: {
      color: 'inherit',
      fontSize: '22px',
      margin: 0,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },

    iconTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '16px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '13px',

        '& svg': {
          transform: 'scale(0.8)',
        },
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '7px',

        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },

    arrow: (isActive: boolean) => ({
      transition: 'transform 150ms ease',
      transform: isActive ? 'rotate(180deg)' : '',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        transform: isActive ? 'scale(0.75) rotate(180deg)' : 'scale(0.65)',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        transform: isActive ? 'scale(0.65) rotate(180deg)' : 'scale(0.65)',
      },
    }),
  };
};

export default useStyles;
