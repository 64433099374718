import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useAppSelector } from 'store';
import { getBillingAIBalance } from 'store/reducers/billing';

import { useWebviewContext } from '../../../providers';
import { OF_URL } from '../../../constants';

import AICopilot from './AICopilot';

const useAI = ({ ref }: { ref: React.MutableRefObject<any | null> }) => {
  const { webviewUrl } = useWebviewContext();

  const { balanceAI } = useAppSelector(getBillingAIBalance);

  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;

  const initAI = useCallback(() => {
    const runScript = (AICopilot: CustomElementConstructor) => {
      if (customElements.get('ai-copilot')) {
        return;
      }

      customElements.define('ai-copilot', AICopilot);
    };

    ref.current?.executeJavaScript(`(${runScript})(${AICopilot})`);
  }, []);

  const addAI = useCallback(() => {
    const runScript = (balanceAI = 0) => {
      const form = document.getElementById('make_post_form');

      if (!form) {
        return;
      }

      const iaElement = form.querySelector('ai-copilot');

      if (iaElement) {
        return;
      }

      const ai = document.createElement('ai-copilot');

      ai.setAttribute('balanceAI', `${balanceAI}`);
      form.prepend(ai);
    };

    initAI();
    ref.current?.executeJavaScript(`(${runScript})(${balanceAI})`);
  }, [initAI, balanceAI]);

  useEffect(() => {
    if (!window.electronAPI || !ref.current || !webviewUrl) {
      return;
    }

    if (webviewUrl.indexOf(OF_URL.CHAT) !== -1) {
      addAI();

      timerRef.current = setInterval(addAI, 5000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [webviewUrl, addAI]);
};

export default useAI;
