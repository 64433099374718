import { ref, string, boolean, object, array, StringSchema, AnyObject } from 'yup';

import { AgencyType } from 'models/auth';

const passwordRegsExp = {
  uppercaseLetters: new RegExp('^(?=.*?[A-Z])'),
  lowercaseLetters: new RegExp('^(?=.*?[a-z])'),
  oneDigit: new RegExp('^(?=.*?[0-9])'),
  specialCharacter: new RegExp('^(?=.*?[#?!@$%^&*-])'),
  minCharacters: new RegExp('.{8,}$'),
};
const commonPasswordRegExp = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}');

//const passwordRegExp = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
const ofRegExp = new RegExp('https://onlyfans.com/');

const STRING_IS_TOO_SHORT = 'String is too short';
const getShortStringMessage = (characters: number, field?: string) =>
  `${field || 'This field'} is too short. Please add more characters.`;
const IS_FIELD_TOO_LONG = 'This field is too long';
const IS_REQUIRED_FIELD_ERROR = 'This field is required';
const IS_ONLYFANS_LINK = 'The link must be from OnlyFans';
const IS_REQUIRED_EMAIL_ERROR = 'This field must be email';
const IS_INVALID_PASSWORD_ERROR = 'Invalid password. ';
const IS_INCORRECT_PASSWORD_ERROR = 'Incorrect Password. Please retry.';
const IS_INVALID_EMAIL_ERROR = 'Please enter a valid email address';
const IS_INCORRECT_PASSWORD_MASK_ERROR =
  'Please ensure your password includes at least 8 characters, an uppercase letter, a lowercase letter, and a special character (e. g., #?!@$%).';

const PASSWORD_UPPERCASE_LETTER = 'The password must contain at least one capital letter';
const PASSWORD_LOWERCASE_LETTER = 'The password must contain at least one lowercase letter';
const PASSWORD_ONE_NUMBER = 'The password must contain at least one number';
const PASSWORD_SPECIAL_SYMBOL = 'The password must contain at least one special character (ex. #?!@$%^&*-)';
const PASSWORD_MIN_LENGTH = 'The password must contain at least 8 characters';

// [TODO]
const commonSchemas: Record<string, StringSchema<string, AnyObject, undefined, ''>> = {
  password: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .matches(passwordRegsExp.uppercaseLetters, IS_INVALID_PASSWORD_ERROR + PASSWORD_UPPERCASE_LETTER)
    .matches(passwordRegsExp.lowercaseLetters, IS_INVALID_PASSWORD_ERROR + PASSWORD_LOWERCASE_LETTER)
    .matches(passwordRegsExp.oneDigit, IS_INVALID_PASSWORD_ERROR + PASSWORD_ONE_NUMBER)
    .matches(passwordRegsExp.specialCharacter, IS_INVALID_PASSWORD_ERROR + PASSWORD_SPECIAL_SYMBOL)
    .matches(passwordRegsExp.minCharacters, IS_INVALID_PASSWORD_ERROR + PASSWORD_MIN_LENGTH),
};

export const signInFormSchema = object().shape({
  password: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .matches(commonPasswordRegExp, IS_INCORRECT_PASSWORD_ERROR),
  email: string().email(IS_INVALID_EMAIL_ERROR).required(IS_REQUIRED_FIELD_ERROR),
});

export const signUpFormSchema = object().shape({
  password: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .matches(commonPasswordRegExp, IS_INCORRECT_PASSWORD_MASK_ERROR),
  email: string().email(IS_INVALID_EMAIL_ERROR).required(IS_REQUIRED_FIELD_ERROR),
  name: string().trim().required(IS_REQUIRED_FIELD_ERROR).min(3, 'Name must be at least 3 characters long.').max(30),
});

export const forgotPasswordSchema = object().shape({
  email: string().email('Invalid email. Please enter your registered email').required(IS_REQUIRED_FIELD_ERROR),
});

export const confirmPasswordSchema = object().shape({
  newPassword: commonSchemas.password,
  repeatedPassword: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .oneOf([ref('newPassword')], 'Password and confirm password does not match'),
});

export const agencyFormSchema = object().shape({
  agencyName: string().when('type', {
    is: AgencyType.TEAM,
    then: (schema) => schema.trim().required(IS_REQUIRED_FIELD_ERROR),
  }),
  zone: string().trim().required(IS_REQUIRED_FIELD_ERROR),
  type: string().trim().required(IS_REQUIRED_FIELD_ERROR),
});

export const connectCreatorSchema = {
  requestStep: object().shape({
    ofLink: string().trim().required(IS_REQUIRED_FIELD_ERROR).matches(ofRegExp, IS_ONLYFANS_LINK),
  }),
  basicInfoStep: object().shape({
    name: string().trim().min(3, STRING_IS_TOO_SHORT).max(30).required(IS_REQUIRED_FIELD_ERROR),
    sex: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    birthDate: string()
      .trim()
      .required(IS_REQUIRED_FIELD_ERROR)
      .test({
        name: 'is-valid-age',
        message: 'Invalid age',
        test: (value) => {
          const numericValue = Number(value);
          return /^\d+$/.test(value) && numericValue >= 0 && numericValue <= 200;
        },
      })
      .test({
        name: 'is-valid-min-age',
        message: 'Creator must be at least 18 years old',
        test: (value) => {
          const numericValue = Number(value);
          return numericValue >= 18;
        },
      }),
    country: string().required(IS_REQUIRED_FIELD_ERROR),
    city: string().trim().min(3, getShortStringMessage(3)).required(IS_REQUIRED_FIELD_ERROR),
  }),
  proxyStep: object().shape({
    proxyCountry: string().required(IS_REQUIRED_FIELD_ERROR),
  }),
  aboutStep: object().shape({
    conversationalStyle: string().required(IS_REQUIRED_FIELD_ERROR),
    fetish: array().min(1, 'You must select at least 1 permission').required(IS_REQUIRED_FIELD_ERROR),
  }),
};

export const rolesSchema = {
  requestStep: object().shape({
    name: string().trim().min(3, STRING_IS_TOO_SHORT).max(30).required(IS_REQUIRED_FIELD_ERROR),
    description: string().trim().max(100, IS_FIELD_TOO_LONG),
  }),
  rolePermissionsStep: object().shape({
    permissions: array().min(1, 'You must select at least 1 permission').required(IS_REQUIRED_FIELD_ERROR),
  }),
};

export const paymentPopupSchema = {
  accountStep: object().shape({
    email: string().trim().email(IS_REQUIRED_EMAIL_ERROR).required(IS_REQUIRED_FIELD_ERROR),
    phone: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    name: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    country: string().required(IS_REQUIRED_FIELD_ERROR),
    isBusiness: boolean().required(IS_REQUIRED_FIELD_ERROR),
    postalCode: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    //province: string().when('isBusiness', {
    //    is: true,
    //    then: (schema) => schema.trim().required(IS_REQUIRED_FIELD_ERROR),
    //}),
  }),
  businessPurchaseStep: object().shape({
    city: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    businessName: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    businessId: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    fullAddress: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    postalCode: string().trim().required(IS_REQUIRED_FIELD_ERROR),
  }),
};

export const settingsFormSchema = object().shape({
  email: string().email(IS_INVALID_EMAIL_ERROR).required(IS_REQUIRED_FIELD_ERROR),
  name: string().trim().required(IS_REQUIRED_FIELD_ERROR),
});

export const settingsPasswordFormSchema = object().shape({
  oldPassword: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .matches(commonPasswordRegExp, IS_INCORRECT_PASSWORD_ERROR),
  newPassword: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .matches(commonPasswordRegExp, IS_INCORRECT_PASSWORD_MASK_ERROR),
  repeatedPassword: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .oneOf([ref('newPassword')], 'Password and confirm password does not match'),
});

export const ownerSettingsFormSchema = {
  isSolo: object().shape({
    timezone: string().trim().required(IS_REQUIRED_FIELD_ERROR),
  }),
  isAgency: object().shape({
    agencyName: string().trim().required(IS_REQUIRED_FIELD_ERROR),
    timezone: string().trim().required(IS_REQUIRED_FIELD_ERROR),
  }),
};

export const editAccountFormSchema = object().shape({
  name: string().trim().min(3, STRING_IS_TOO_SHORT).max(30).required(IS_REQUIRED_FIELD_ERROR),
  sex: string().trim().required(IS_REQUIRED_FIELD_ERROR),
  birthDate: string()
    .trim()
    .required(IS_REQUIRED_FIELD_ERROR)
    .test({
      name: 'is-valid-age',
      message: 'Invalid age',
      test: (value) => {
        const numericValue = Number(value);
        return /^\d+$/.test(value) && numericValue >= 0 && numericValue <= 200;
      },
    })
    .test({
      name: 'is-valid-min-age',
      message: 'Creator must be at least 18 years old',
      test: (value) => {
        const numericValue = Number(value);
        return numericValue >= 18;
      },
    }),
  country: string().required(IS_REQUIRED_FIELD_ERROR),
  city: string().trim().min(3, getShortStringMessage(3)).required(IS_REQUIRED_FIELD_ERROR),
  conversationalStyle: string().required(IS_REQUIRED_FIELD_ERROR),
});
