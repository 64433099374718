import React, { useCallback, useEffect, useState } from 'react';

import { Button, ThemeButton } from '../../../Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getModalLoading } from '../../../../store/reducers/modal';
import { getMembersState, removeMemberCreator } from '../../../../store/reducers/members';
import { ICreators } from '../../../../models/creators';

import styles from './Creator.module.scss';

const Creator = ({ creator }: { creator: ICreators }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getModalLoading);
  const { memberActiveModal } = useAppSelector(getMembersState);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleRemoveCreator = useCallback(async () => {
    if (!memberActiveModal?.id || !creator?.id) {
      return;
    }

    setIsProcessing(true);

    dispatch(
      removeMemberCreator({
        memberId: memberActiveModal.id,
        creatorId: creator.id,
      }),
    );
  }, [memberActiveModal?.id, creator?.id]);

  useEffect(() => {
    if (!loading) {
      setIsProcessing(false);
    }
  }, [loading]);

  return (
    <div className={styles.creator} key={creator.id}>
      <div className={styles.info}>
        <div className={styles.avatar}>{creator.imageLink && <img src={creator.imageLink} alt="Avatar creator" />}</div>
        <div className={styles.username}>
          <p className={styles.name}>{creator.name}</p>
          <p className={styles.ofNick}>@{creator.ofNick}</p>
        </div>
      </div>
      <Button
        theme={ThemeButton.DARK_OUTLINE}
        className={styles.removeBtn}
        onClick={handleRemoveCreator}
        disabled={loading}
        loading={isProcessing}
      >
        Remove
      </Button>
    </div>
  );
};

export default Creator;
