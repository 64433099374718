import { Box } from '@mui/material';

import useStyles from './useStyles';

const Loader = () => {
  const styles = useStyles();
  return <Box sx={styles.root} />;
};

export default Loader;
