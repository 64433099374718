import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    wrapper: {
      maxWidth: '100%',
      overflow: 'auto',
    },

    root: {
      display: 'flex',
      boxSizing: 'border-box',
      position: 'relative',
      fontSize: '12px',
      overflow: 'auto',
      alignItems: 'center',
      borderBottom: '1px solid #E7E7E7',
      maxWidth: '100%',
      minWidth: '100%',
    },

    tab: (isActive: boolean) => ({
      position: 'relative',
      maxWidth: '258px',
      display: 'flex',
      gap: '8px',
      padding: '12px 16px',
      borderRadius: '0',
      borderBottom: `2px solid ${isActive ? '#00AFF0' : 'transparent'}`,
      color: isActive ? '#00AFF0' : '#A3ADB1',
      cursor: 'pointer',
      alignItems: 'center',
      minWidth: '90px',
    }),

    label: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1.25,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },

    tabIcon: {
      width: '16px',
      height: '16px',
    },

    addButton: {
      marginLeft: '10px',
      padding: '5px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },

    action: {
      color: 'rgba(0, 0, 0, 0.54)',
      display: 'flex',
      padding: '4px',
      '& svg': {
        width: '14px',
        height: '14px',
      },

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%',
      },
    },
  };
};

export default useStyles;
