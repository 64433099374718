import React from 'react';
import * as Sentry from '@sentry/react';

import { ErrorScreen } from 'components/screens';

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary fallback={() => <ErrorScreen />}>{children}</Sentry.ErrorBoundary>
);

export default ErrorBoundary;
