import { useFormik } from 'formik';
import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { isAxiosError } from 'axios';

import { ConnectCreatorStep } from 'constants/creators';
import { WebviewURL } from 'constants/externalLinks';
import { ModalType } from 'constants/modal';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { classNames } from 'utils/classNames';
import { useAppDispatch, useAppSelector } from 'store';
import {
  creatorsActions,
  getCreatorData,
  getCreatorsDictionary,
  loadCreatorsDictionary,
} from 'store/reducers/creators';
import { ICreators } from 'models/creators';
import { ModalConfig, getModalLoading, modalActions } from 'store/reducers/modal';
import { creatorsService } from 'services/CreatorsService';
import { ErrorData } from 'models/fields';
import { logEvent } from 'utils/analytics';
import { useElectronContext } from 'modules/electron/providers';
import { GAAction, GACategory } from 'constants/analytics';

import { AI_TEMPLATE_TEXT } from '../../../constants/templates';

import { AboutStep } from './AboutStep/AboutStep';
import { BasicInfoStep } from './BasicInfoStep/BasicInfoStep';
import { ProxyStep } from './ProxyStep/ProxyStep';
import styles from './ConnectCreator.module.scss';
import { RequestStep } from './RequestStep/RequestStep';

interface ConnectCreatorProps {
  config?: ModalConfig[ModalType.СONNECT_CREATOR];
  onClose?: () => void;
}

export const ConnectCreator = memo(({ config, onClose }: ConnectCreatorProps) => {
  const [activeStep, setActiveStep] = useState(ConnectCreatorStep.REQUEST);
  const dictionary = useAppSelector(getCreatorsDictionary);
  const loading = useAppSelector(getModalLoading);
  const data = useAppSelector(getCreatorData);
  const [apiErrors, setApiErrors] = useState<ErrorData[]>([]);

  const dispatch = useAppDispatch();

  const { setOfUrl, setActivateLoginCreatorId, setActivateLoginSessionId } = useElectronContext();

  useEffect(() => {
    dispatch(loadCreatorsDictionary());
  }, []);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { ...data, notes: data?.notes ?? AI_TEMPLATE_TEXT } as ICreators,
    onSubmit: async (values) => {
      dispatch(modalActions.setModalLoading(true));
      try {
        const requestData = values;
        const { creator } = await creatorsService.createCreators(requestData);

        if (creator?.id) {
          dispatch(creatorsActions.addItemToCreatorsList(creator));

          logEvent(
            GACategory.Platform,
            GAAction.ConnectCreator,
            `Connected: ${creator.name} | Proxy: ${creator?.proxy ? JSON.stringify(creator?.proxy) : 'None'}`,
            {
              creatorName: creator.name,
              proxy: `${creator?.proxy ? JSON.stringify(creator?.proxy) : 'None'}`,
            },
          );

          if (creator?.proxy) {
            const { sessionId, creatorId } = await creatorsService.connectCreator(creator.id);
            setOfUrl(WebviewURL.OF);
            setActivateLoginCreatorId(creatorId);
            setActivateLoginSessionId(sessionId);
          }

          dispatch(modalActions.setModalType({ type: ModalType.NONE }));
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const { data } = error.response ?? {};
          data?.errors && setApiErrors(data?.errors);
        }
      }
      dispatch(modalActions.setModalLoading(false));
    },
    enableReinitialize: true,
  });

  const steps = useMemo(() => Object.keys(ConnectCreatorStep) as ConnectCreatorStep[], []);
  const backButtonShown = useMemo(
    () => activeStep !== steps[0] && activeStep !== steps[steps.length - 1] && activeStep !== ConnectCreatorStep.PROXY,
    [activeStep, steps],
  );

  const onNextStep = useCallback(() => {
    const indexStep = steps.indexOf(activeStep);
    const nextStep = steps[indexStep + 1];

    if (!nextStep) return;
    setActiveStep(nextStep);
  }, [activeStep, steps]);

  const onBackStep = useCallback(() => {
    if (loading) return;

    const indexStep = steps.indexOf(activeStep);
    const prevStep = steps[indexStep - 1];

    if (!prevStep) return;
    setActiveStep(prevStep);
  }, [activeStep, steps, loading]);

  const onSubmit = useCallback(() => handleSubmit(), [handleSubmit]);

  const onChangeData = useCallback(
    (value: any, targetName?: string) => {
      targetName && setFieldValue(targetName, value);
    },
    [setFieldValue],
  );

  const render = (): JSX.Element | null => {
    switch (activeStep) {
      case ConnectCreatorStep.REQUEST: {
        const { ofLink } = values;
        return (
          <RequestStep data={{ ofLink }} apiErrors={apiErrors} onChangeData={onChangeData} onNextStep={onNextStep} />
        );
      }
      case ConnectCreatorStep.PROXY: {
        const { proxyCountry } = values;
        return (
          <ProxyStep
            data={{ proxyCountry }}
            dictionary={dictionary}
            apiErrors={apiErrors}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }

      case ConnectCreatorStep.BASIC_INFO: {
        const { name, birthDate, country, city, notes, sex } = values;
        return (
          <BasicInfoStep
            data={{ name, birthDate, country, city, notes, sex }}
            dictionary={dictionary}
            apiErrors={apiErrors}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }
      case ConnectCreatorStep.ABOUT: {
        const { conversationalStyle, fetish } = values;
        return (
          <AboutStep
            data={{ conversationalStyle, fetish }}
            apiErrors={apiErrors}
            onChangeData={onChangeData}
            onSubmit={onSubmit}
            dictionary={dictionary}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.withoutBackBtn]: !backButtonShown,
        })}
      >
        <span onClick={onBackStep}>
          <ArrowIcon />
        </span>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>

      {render()}
      {activeStep !== ConnectCreatorStep.SUCCESS && (
        <div className={styles.dots}>
          {steps.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.dot, {
                [styles.active]: item === activeStep,
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
});
