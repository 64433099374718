import { useCallback } from 'react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { useEmojiProviderContext } from '../../../providers';
import Modal from '../../Modal';

const ModalEmojiPicker = () => {
  const { isEmojiModalOpen: isOpen, setIsEmojiModalOpen, setEmojiItem } = useEmojiProviderContext();

  const handleClose = useCallback(() => {
    setIsEmojiModalOpen(false);
  }, []);

  const handleEmojiClick = useCallback((emoji: EmojiClickData) => {
    setEmojiItem(emoji);
    handleClose();
  }, []);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} sx={{ paddingTop: '60px' }}>
      <EmojiPicker onEmojiClick={handleEmojiClick} />
    </Modal>
  );
};

export default ModalEmojiPicker;
