import { memo, ReactNode } from 'react';

import { classNames } from '../../../utils/classNames';

import styles from './Card.module.scss';

interface CardProps {
  title: string | ReactNode;
  icon: ReactNode;
  children: ReactNode;
  isResetStyles?: boolean;
}

const Card = ({ title, icon, children, isResetStyles }: CardProps) => (
  <div className={classNames(styles.box, { [styles.resetBox]: isResetStyles })}>
    <div className={styles.titleBox}>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.title}>{title}</h3>
    </div>
    {children}
  </div>
);

export default memo(Card);
