export const Emojis = [
  {
    emoji: '😘',
    unified: '1f618',
  },
  {
    emoji: '😉',
    unified: '1f609',
  },
  {
    emoji: '👉',
    unified: '1f449',
  },
  {
    emoji: '💋',
    unified: '1f48b',
  },
  {
    emoji: '🙄',
    unified: '1f644',
  },
  {
    emoji: '😎',
    unified: '1f60e',
  },
];
