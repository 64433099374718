import { useCallback, useEffect } from 'react';

import { classNames } from 'utils/classNames';
import {
  billingActions,
  loadAIBalance,
  loadAISubscriptions,
  loadCurrentBalance,
  loadSubscriptions,
} from 'store/reducers/billing';
import { useAppDispatch } from 'store';
import usePolling from 'hooks/usePolling';

import { modalActions } from '../../store/reducers/modal';
import { ModalType } from '../../constants/modal';
import { AI_COPILOT_NOTIFY } from '../../constants/billing';

import { SubscriptionTable } from './SubscriptionTable/SubscriptionTable';
import styles from './Billing.module.scss';
import { PaymentBalance } from './PaymentBalance/PaymentBalance';
import { AISubscription } from './AISubscription/AISubscription';

const INITIAL_POLLING_INTERVAL = 30 * 1000;

interface BillingTableProps {
  className?: string;
}

export const Billing = ({ className }: BillingTableProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!localStorage.getItem(AI_COPILOT_NOTIFY)) {
      dispatch(
        modalActions.setModalType({
          type: ModalType.AI_COPILOT,
        }),
      );
    }

    getCurrentBalance(false);
    dispatch(loadAIBalance());
    dispatch(loadSubscriptions());
    dispatch(loadAISubscriptions());

    return () => {
      dispatch(billingActions.reset());
    };
  }, []);

  const getCurrentBalance = useCallback(async (isSilently = true) => {
    dispatch(loadCurrentBalance({ isSilently }));
  }, []);

  usePolling(INITIAL_POLLING_INTERVAL, getCurrentBalance, false);

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Billing</h1>
      </div>
      <PaymentBalance />
      <AISubscription />
      <SubscriptionTable />
    </div>
  );
};
