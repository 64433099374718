import { memo, useCallback, useEffect, useState } from 'react';
import { yupToFormErrors } from 'formik';

import { ReactComponent as AccountsIcon } from 'assets/images/accounts.svg';
import Input from 'components/fields/Input/Input';
import { Button, ThemeButton } from 'components/Button/Button';
import { ICreators } from 'models/creators';
import { creatorsService } from 'services/CreatorsService';
import { connectCreatorSchema } from 'utils/validators';
import { IValidateFlags } from 'models/validation';
import { ErrorData } from 'models/fields';
import { getApiErrorMessageByField } from 'constants/messages';
import { WebviewURL } from 'constants/externalLinks';

import styles from './RequestStep.module.scss';

type RequestDataType = Pick<ICreators, 'ofLink'>;

interface RequestStepProps {
  data: RequestDataType;
  apiErrors: ErrorData[];
  onChangeData: (value: string, targetName?: string) => void;
  onNextStep: () => void;
}

export const RequestStep = memo(({ data, apiErrors, onChangeData, onNextStep }: RequestStepProps) => {
  const [loading, setLoading] = useState(false);
  const [validateErrors, setValidateErrors] = useState<IValidateFlags<RequestDataType>>({});

  const { ofLink } = data;

  useEffect(() => {
    setValidateErrors({});
  }, [data]);

  const onClickContinue = useCallback(async () => {
    setLoading(true);

    try {
      await connectCreatorSchema.requestStep.validate({ ofLink }, { abortEarly: false });

      const { isCreator } = await creatorsService.checkLinkCreators(ofLink!);

      if (isCreator) {
        onNextStep();
      } else {
        setValidateErrors({ ofLink: '*The entered user is not a creator' });
      }
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
    setLoading(false);
  }, [ofLink, onNextStep, setLoading]);

  const onChangeOFLink = (text: string, targetName?: string) => {
    const value = text.trim();
    onChangeData(value, targetName);
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <AccountsIcon />
      </div>
      <h2 className={styles.title}>Connect Account</h2>
      <p className={styles.description}>
        Make sure you link to a Creator's account. Once done, we'll establish a new, distinct session for it.
      </p>
      <div className={styles.actions}>
        <Input
          name="ofLink"
          placeholder={`${WebviewURL.OF}u....`}
          onChange={onChangeOFLink}
          value={ofLink}
          className={styles.input}
          disabled={loading}
          error={validateErrors.ofLink || getApiErrorMessageByField('ofLink', apiErrors)}
        />
        <Button theme={ThemeButton.PRIMARY} onClick={onClickContinue} loading={loading} disabled={!ofLink}>
          Continue
        </Button>
      </div>
    </div>
  );
});
