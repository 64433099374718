import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFileUploaderData {
  name: string | null;
  size: number | null;
  type: string | null;
  contents: File | null;
}

export interface IFileUploaderState {
  fileTextError: string | null;
  data: IFileUploaderData;
}

const initialState: IFileUploaderState = {
  fileTextError: null,
  data: {
    name: null,
    size: null,
    type: null,
    contents: null,
  },
};

export const fileUploaderSlice = createSlice({
  name: 'fileUploader',
  initialState,
  reducers: {
    setFileData: (state: IFileUploaderState, action: PayloadAction<IFileUploaderData>): IFileUploaderState => {
      return {
        data: action.payload,
        fileTextError: null,
      };
    },
    setFileTextError: (state: IFileUploaderState, action: PayloadAction<string>): IFileUploaderState => {
      return {
        ...initialState,
        fileTextError: action.payload,
      };
    },
    resetFileData: (): IFileUploaderState => {
      return initialState;
    },
  },
});

export const { setFileData, setFileTextError, resetFileData } = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;
