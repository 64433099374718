import React, { useRef, useState } from 'react';

import Webview, { IWebviewRef } from '../../Webview';

import {
  useWebView,
  useCreatorAutoLogin,
  useUIHelpers,
  useUiEmojis,
  useUserPermissions,
  useCreatorLogout,
  useNotification,
  useVault,
  useAI,
} from './hooks';

interface IProps {
  src: string;
}

const OFWebview = ({ src }: IProps) => {
  const ref = useRef<IWebviewRef | null>(null);

  const [isReady, setIsReady] = useState(false);

  useUIHelpers({ ref, isReady });
  useNotification({ ref, isReady });
  useUiEmojis({ ref, isReady });
  useWebView({ ref });
  useCreatorAutoLogin({ isReady, ref });
  useUserPermissions({ ref, isReady });
  useCreatorLogout({ ref });
  useVault({ ref });
  useAI({ ref });

  return (
    <Webview
      ref={ref}
      src={src}
      preload={`file://${window.versions.dirname()}/webview-preload.cjs`}
      onReady={setIsReady}
    />
  );
};

export default OFWebview;
