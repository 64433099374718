import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Box, Typography } from '@mui/material';

import { ArrowUp } from '../../../../../../icons';

import { IProps } from './interfaces';
import useStyles from './useStyles';

const Collapsible = ({ children, title, icon, open = false }: IProps) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const initRef = useRef<boolean>(true);

  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<string | number>(open ? 'unset' : 0);

  useEffect(() => {
    if (initRef.current) {
      return;
    }

    const newHeight = isOpen ? ref.current?.getBoundingClientRect().height : 0;
    setHeight(newHeight ?? 0);
  }, [isOpen]);

  const handleClick = useCallback(() => {
    initRef.current = false;
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  return (
    <div>
      <Button onClick={handleClick} sx={styles.button(isOpen)}>
        <Box sx={styles.iconTitle}>
          {icon ?? null}
          <Typography sx={styles.title} component="h2">
            {title}
          </Typography>
        </Box>
        <Typography component="span" sx={styles.arrow(isOpen)}>
          <ArrowUp />
        </Typography>
      </Button>

      <Box sx={initRef.current ? styles.collapseInit(isOpen) : styles.collapse(isOpen)} style={{ height }}>
        <Box ref={ref} sx={styles.content}>
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default Collapsible;
