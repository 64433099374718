import { Box } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as AudioIcon } from 'assets/images/audio.svg';
import { ReactComponent as NotSupportedIcon } from 'assets/images/not-supported.svg';
import { IMedia, MEDIA_NOT_SET, MediaType } from 'models/media';

import Tag from './../Tag';
import PriceTag from './PriceTag';
import BundleTag from './BundleTag';
import MediaImage from './MediaImage';
import useStyles from './useStyles';

interface IMediaProps {
  media: IMedia;
}

const Media = ({ media }: IMediaProps) => {
  const styles = useStyles();

  const { type, status, price, text } = media;
  const mediaPrice = price ? +price : 0;

  const printMedia = () => {
    if (type === MediaType.AUDIO) {
      return <AudioIcon />;
    }

    if (type === MediaType.UNSUPPORTED_MEDIA_TYPE) {
      return <NotSupportedIcon />;
    }

    return <MediaImage src={media.image} alt={media.ofMediaId} />;
  };

  const isBg = [MediaType.PHOTO, MediaType.VIDEO].includes(type);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.media(isBg)}>
        {printMedia()}
        {mediaPrice > 0 ? <PriceTag status={status} price={mediaPrice} /> : null}
        {media.isBundle ? <BundleTag /> : null}
      </Box>
      <Box>
        <Tag name={status} />
        <Box sx={styles.text}>{text === MEDIA_NOT_SET ? '' : text}</Box>
        <Box sx={styles.date}>{format(new Date(media.createdAt + 'Z'), 'MMM dd yyyy hh:mm a')}</Box>
      </Box>
    </Box>
  );
};

export default Media;
