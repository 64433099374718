import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { Country, ICountry } from 'country-state-city';
import { startOfDay } from 'date-fns';

import { debounce } from 'utils/debounce';
import { scrapingMetadataService } from 'services/ScrapingMetadataService';
import { IScrapinMetadata } from 'models/scraping';
import { DateInputPicker } from 'components/fields/DatePicker/DatePicker';
import Select from 'components/fields/Select/Select';
import { convertToDropdownItem } from 'utils/convert';
import { useElectronContext } from 'modules/electron/providers';

import { useWebviewContext } from '../../../../../providers';

import styles from './MainAccountForm.module.scss';

const DEBOUNCE_GET_INTERVAL = 800;

const MainAccountForm = ({ birthday, location }: { birthday: string; location: string }) => {
  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const updateMetadata = useCallback(
    async (data: IScrapinMetadata) => {
      if (!activeCreator?.id || !activeChatterId) {
        return;
      }

      try {
        const preparedData = { ...data };
        if (!preparedData.birthday) preparedData.birthday = undefined;

        await scrapingMetadataService.updateMetadata(activeCreator?.id, activeChatterId, preparedData);
      } catch (e) {
        console.log('Can not update metadata: ', e);
      }
    },
    [activeCreator?.id, activeChatterId],
  );

  const handleUpdateMetadata = useMemo(() => debounce(updateMetadata, DEBOUNCE_GET_INTERVAL), []);

  const { values, setFieldValue } = useFormik({
    initialValues: { birthday: birthday, location: location },
    onSubmit: async (values) => {
      await handleUpdateMetadata(values);
    },
    enableReinitialize: true,
  });

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        ...convertToDropdownItem(country.name),
        ...country,
      })),
    [],
  );

  const onChangeData = useCallback(
    async (value?: string, targetName?: string) => {
      if (!targetName) {
        return;
      }

      setFieldValue(targetName, value);
      handleUpdateMetadata({ ...values, [targetName]: value });
    },
    [setFieldValue, values],
  );

  return (
    <div className={styles.box}>
      <DateInputPicker
        name="birthday"
        fieldName="Birthday"
        value={values.birthday ? new Date(values.birthday) : undefined}
        onChangeValue={onChangeData}
        maxDate={startOfDay(new Date())}
        inputReadOnly
        className={styles.birthday}
      />
      <Select
        name="location"
        fieldName="Counry/Region"
        placeholder="Unknown"
        value={convertToDropdownItem(values.location)}
        options={countryOptions}
        onChange={(value, actionMeta) => {
          const targetName = actionMeta.name;
          const updatedValue = value as ICountry;
          onChangeData(updatedValue.name, targetName!);
        }}
        onInputChange={(newValue, actionMeta) => {
          onChangeData(newValue, 'location');
        }}
        className={styles.location}
      />
    </div>
  );
};

export default MainAccountForm;
