import React, { useCallback, useEffect } from 'react';

import { useOFNotificationContext, NotificationStatusEnum } from 'modules/electron/providers';

import { useWebviewContext } from '../../providers';
import { OF_URL } from '../../constants';

const useNotification = ({ ref, isReady }: { ref: React.MutableRefObject<any | null>; isReady: boolean }) => {
  const { activeChatterId, webviewUrl } = useWebviewContext();
  const { setStatus, triggerFlag, setIsProcessing } = useOFNotificationContext();

  const triggerButtonClick = useCallback(() => {
    if (!isReady) {
      return;
    }

    const runScript = () => {
      const button = document.querySelector('[at-attr="mute_chat"]');
      if (!button) {
        return null;
      }

      //@ts-ignore
      button.click();
    };

    setIsProcessing(true);

    if (!ref.current) {
      return;
    }

    ref.current.executeJavaScript(`(${runScript})()`);
  }, [isReady]);

  const isNotificationOn = async () => {
    const runScript = () => {
      const button = document.querySelector('[at-attr="mute_chat"] svg');
      if (!button) {
        return null;
      }

      return button.getAttribute('data-icon-name') === 'icon-notification';
    };

    if (!ref.current) {
      return;
    }

    const isNotificationOn = await ref.current.executeJavaScript(`(${runScript})()`);

    if (isNotificationOn) {
      setStatus(NotificationStatusEnum.ON);
    } else if (isNotificationOn === null) {
      setStatus(NotificationStatusEnum.NONE);
    } else {
      setStatus(NotificationStatusEnum.OFF);
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    if (!isReady || !activeChatterId || !window.electronAPI) {
      return;
    }

    //Fired when status has been changed in webview
    const eventName = 'notification-button';

    const cb = () => {
      isNotificationOn();
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [isReady, activeChatterId]);

  useEffect(() => {
    if (!window.electronAPI || !ref.current || !webviewUrl) {
      return;
    }

    //Fired on first load
    if (webviewUrl.indexOf(OF_URL.CHAT) !== -1) {
      isNotificationOn();
    }
  }, [webviewUrl, isNotificationOn]);

  useEffect(() => {
    if (!triggerFlag) {
      return;
    }
    triggerButtonClick();
  }, [triggerFlag, triggerButtonClick]);
};

export default useNotification;
