const MessageEdit = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6666 26.5417H3.49992C2.37992 26.5417 1.45825 25.62 1.45825 24.5V9.33334C1.45825 4.17668 4.17659 1.45834 9.33325 1.45834H18.6666C23.8233 1.45834 26.5416 4.17668 26.5416 9.33334V18.6667C26.5416 23.8233 23.8233 26.5417 18.6666 26.5417ZM9.33325 3.20834C5.15659 3.20834 3.20825 5.15668 3.20825 9.33334V24.5C3.20825 24.6633 3.33659 24.7917 3.49992 24.7917H18.6666C22.8433 24.7917 24.7916 22.8433 24.7916 18.6667V9.33334C24.7916 5.15668 22.8433 3.20834 18.6666 3.20834H9.33325Z"
        fill="currentColor"
      />
      <path
        d="M9.2747 20.7083C8.72637 20.7083 8.2247 20.51 7.85137 20.1483C7.40804 19.705 7.20968 19.075 7.31468 18.3983L7.64134 16.0883C7.71134 15.5867 8.02636 14.945 8.38803 14.5833L14.443 8.52833C16.5197 6.45167 18.328 7.385 19.4714 8.52833C20.3697 9.42667 20.778 10.3717 20.6847 11.3167C20.6147 12.0867 20.2064 12.81 19.4714 13.5567L13.4164 19.6117C13.0547 19.9733 12.4247 20.2883 11.9113 20.37L9.60137 20.6967C9.49637 20.6967 9.3797 20.7083 9.2747 20.7083ZM16.9514 9.04167C16.5197 9.04167 16.1347 9.32167 15.6914 9.75333L9.63637 15.8083C9.54303 15.9017 9.40303 16.1933 9.3797 16.3217L9.05303 18.6317C9.04137 18.7483 9.05301 18.8533 9.09968 18.9C9.14634 18.9467 9.25136 18.9583 9.36802 18.9467L11.678 18.62C11.818 18.5967 12.098 18.4567 12.1914 18.3633L18.2464 12.3083C18.6897 11.865 18.923 11.48 18.958 11.13C18.993 10.7333 18.7597 10.2667 18.2464 9.75333C17.733 9.26333 17.3247 9.04167 16.9514 9.04167Z"
        fill="currentColor"
      />
      <path
        d="M17.9899 14.6767C17.9082 14.6767 17.8265 14.665 17.7565 14.6416C15.6449 14.0466 13.9532 12.3666 13.3582 10.2433C13.2299 9.77665 13.4982 9.29832 13.9649 9.15832C14.4315 9.02998 14.9099 9.29832 15.0382 9.76498C15.4699 11.2933 16.6949 12.5183 18.2232 12.95C18.6899 13.0783 18.9582 13.5683 18.8299 14.035C18.7249 14.42 18.3749 14.6767 17.9899 14.6767Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageEdit;
