import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { BillingColumns, getEarningColor } from 'constants/billing';
import { classNames } from 'utils/classNames';
import { IColumn } from 'models/table';
import { Button, SizeButton, ThemeButton } from 'components/Button/Button';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { useAppSelector } from 'store';
import { getBillingSubscriptions } from 'store/reducers/billing';
import { ISelectedPlanData, ISubscriptions, PlanAccount, subscriptionStatusMap } from 'models/billing';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import { useChatterCharms } from 'providers/ChatterCharmsProvider';
import { formatNumberWithPrecision } from 'utils/convert';

import { SubscriptionStatus } from '../../../models/creators';
import { Tooltip } from '../../Tooltip/Tooltip';
import useSubscription from '../hooks/useSubscription';

import styles from './SubscriptionTable.module.scss';

const columns: IColumn[] = [
  {
    id: BillingColumns.ACCOUNT,
    label: 'Account',
    width: 300,
    isFixed: true,
  },
  {
    id: BillingColumns.EARNINGS,
    label: 'Earnings',
    width: 150,
    isFixed: true,
  },
  {
    id: BillingColumns.PLAN,
    label: 'Status',
    width: 200,
    isFixed: true,
    align: 'center',
  },
  {
    id: BillingColumns.M1,
    fieldName: PlanAccount.M1,
    label: '1 Month',
    width: 200,
    align: 'center',
    isFixed: true,
  },
  {
    id: BillingColumns.M3,
    fieldName: PlanAccount.M3,
    label: '3 Months',
    width: 200,
    align: 'center',
    isFixed: true,
  },
  {
    id: BillingColumns.M6,
    fieldName: PlanAccount.M6,
    label: '6 Months',
    width: 200,
    //tooltipContent: '6 Months plan provides top priority for AI Copilot development',
    isOpenTooltip: true,
    tooltipTimeout: 5000,
    align: 'center',
    isFixed: true,
  },
];

export const SubscriptionTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedPlans, setSelectedPlans] = useState<ISelectedPlanData[]>([]);

  const { items: subscriptions, loading } = useAppSelector(getBillingSubscriptions);
  const { creatorMap } = useChatterCharms();
  const { paymentLoading, getPlanTotals, getSelectedPlans, createPaymentSubscription } = useSubscription(selectedPlans);

  useEffect(() => {
    enqueueSnackbar('Lock your cost today', { variant: 'info', text: 'We will increase our prices shortly!' });
  }, []);

  const items = useMemo(
    () => subscriptions.filter(({ creatorId }) => creatorMap?.get(creatorId)),
    [creatorMap, subscriptions],
  );

  const { totalWithDiscount, savedTotal } = getPlanTotals();

  const onClickMonthPlanBtn = (value: ISelectedPlanData) => {
    const updatedSelectedPlans = getSelectedPlans(value) || [];
    setSelectedPlans(updatedSelectedPlans);
  };

  const onClickMakePayment = async () => {
    if (!selectedPlans.length) return;
    await createPaymentSubscription();
    setSelectedPlans([]);
  };

  const onRenderColumnItem = (item: ISubscriptions, index: number, column: IColumn) => {
    const fieldName = column?.fieldName;

    switch (column?.id) {
      case BillingColumns.ACCOUNT: {
        const { creatorName, creatorUsername, creator } = item;
        return (
          <div className={styles.account}>
            <div className={styles.avatar}>
              <img src={creator?.imageLink} alt="" />
            </div>
            <div className={styles.info}>
              <p className={styles.name}>{creatorName}</p>
              <p className={styles.ofNick}>@{creatorUsername}</p>
            </div>
          </div>
        );
      }
      case BillingColumns.EARNINGS: {
        const { stats30D } = item;
        return <p className={styles.earnings}>$ {formatNumberWithPrecision(Number(stats30D) || 0)}</p>;
      }
      case BillingColumns.PLAN: {
        const { status, endDate } = item;

        return (
          <div className={styles.statusPlan}>
            <p className={styles.status}>{subscriptionStatusMap[status]}</p>
            {status !== SubscriptionStatus.UNPAID && (
              <p className={styles.expiry}>Expiry {format(new Date(endDate), 'MMMM dd, yyyy')}</p>
            )}
          </div>
        );
      }
      case BillingColumns.M1:
      case BillingColumns.M3:
      case BillingColumns.M6: {
        const creatorId = item.creatorId;
        const color = getEarningColor(item.stats30D);

        const disabled = false;
        // для MVP было решено разрешать покупать без ограничений
        // const disabled = item.status === SubscriptionStatus.PAID;

        const planPrices = item[fieldName as PlanAccount] || {};
        const { discount = 0, regular = 0 } = planPrices;
        const value = discount || regular;

        const isSelected =
          selectedPlans.findIndex((item) => item?.creatorId === creatorId && item.plan === fieldName) !== -1;

        return (
          <>
            <span
              style={{
                borderColor: color,
                backgroundColor: isSelected ? color : undefined,
              }}
              className={classNames(styles.plan, {
                [styles.disabled]: disabled,
              })}
              onClick={() =>
                onClickMonthPlanBtn({
                  plan: fieldName as PlanAccount,
                  creatorId,
                  discount,
                  regular,
                  disabled,
                })
              }
            >
              {!!discount && <span className={styles.discount}>${regular}</span>}
              <span>${value}</span>
            </span>
            {disabled && (
              <Tooltip
                className={styles.tooltip}
                placement="top"
                title="Subscription may be renewed after the end of the current one"
              >
                <InfoIcon className={styles.info} />
              </Tooltip>
            )}
          </>
        );
      }
      default: {
        if (fieldName && item) {
          return <p>{(item as any)[fieldName]}</p>;
        }
        return <></>;
      }
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.top}>
        <p className={styles.title}>Subscriptions</p>
        <p>Select a Plan For Each Account</p>
      </div>
      <CustomizedTable items={items} columns={columns} onRenderColumnItem={onRenderColumnItem} loading={loading} />
      <div className={styles.footer}>
        <div className={styles.totals}>
          <p>
            Total Amount to Pay: <span>${totalWithDiscount}</span>
          </p>
          <p>Money Saved: ${savedTotal}</p>
        </div>
        <Button
          className={styles.makePaymentBtn}
          theme={ThemeButton.PRIMARY}
          onClick={onClickMakePayment}
          loading={paymentLoading}
          size={SizeButton.M}
          disabled={!selectedPlans.length}
        >
          Make payment
        </Button>
      </div>
    </div>
  );
};
