import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      borderRadius: '10px',
      border: '1px solid #E7E7E7',
      background: 'var(--white-color)',
      padding: '12px 22px',

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
        borderColor: 'rgba(0, 175, 240, 0.70)',
        background: 'rgba(0, 175, 240, 0.03)',
      },
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        padding: '8px 17px',
        fontSize: '16px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: '6px 10px',
        fontSize: '12px',
      },
    },
    button: {
      border: 'none',
      width: '100%',
      display: 'block',
      backgroundColor: 'transparent',
      padding: 0,
    },
    group: {
      display: 'flex',
      alignItems: 'center',
      gap: '36px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '15px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '12px',
      },
    },
    name: {
      color: '#000',
      fontSize: '19px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '16px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },
    image: {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: '#F3F4F6',

      '& img': {
        minHeight: '100%',
        width: '100%',
        objectFit: 'cover',
      },
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: '28px',
        height: '28px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: '25px',
        height: '25px',
      },
    },
    active: {
      borderColor: 'rgba(0, 175, 240, 0.70)',
      background: 'rgba(0, 175, 240, 0.03)',
    },
    price: {
      color: '#242628',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '16px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },
  };
};

export default useStyles;
