export interface IDropdownOption {
  label: string;
  value: string;
}

export enum ThemeField {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ErrorData {
  field: string;
  message: string;
}
