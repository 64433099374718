import { useMemo } from 'react';

import { useAppSelector } from '../store';
import { getCreatorsList } from '../store/reducers/creators';
import { getMembersState } from '../store/reducers/members';
import { getUserAuthData } from '../store/reducers/auth';

import usePermissions from './usePermissions';

const useCreators = () => {
  const user = useAppSelector(getUserAuthData);

  const { items: creators } = useAppSelector(getCreatorsList);
  const { membersCreators } = useAppSelector(getMembersState);

  const { isSuperAdminPermission } = usePermissions();

  const filteredCreators = useMemo(() => {
    if (!user?.id) {
      return [];
    }

    if (isSuperAdminPermission) {
      return creators;
    }

    const assignedCreatorIds = membersCreators
      .filter((item) => item.memberId === user?.id)
      .map((item) => item.creatorId);

    return creators.filter((creator) => creator?.id && assignedCreatorIds.includes(creator.id));
  }, [isSuperAdminPermission, creators, membersCreators, user?.id]);

  const creatorMap = useMemo(() => {
    if (!creators.length) return null;

    return new Map(creators.map((item) => [item.id!, item]));
  }, [creators]);

  return { creators: filteredCreators ?? [], creatorMap };
};

export default useCreators;
