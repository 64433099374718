import React from 'react';
import { format } from 'date-fns';

import InfoBox from '../InfoBox/InfoBox';

import classes from './SubscriptionDetails.module.scss';
import { ISubscriptionDetailsProps } from './interfaces';

const SubscriptionDetails = ({ details }: ISubscriptionDetailsProps) => {
  const preparedData = [
    {
      title: 'Subscribed',
      value: details.hasActivePaidSubscriptions ? 'Yes' : 'No',
    },
    {
      title: 'Auto-Renewal',
      value: details.subscribed ? 'Yes' : 'No',
    },
    {
      title: 'Subscription Duration',
      value: details.subscribeAt,
    },
    {
      title: 'Last Response',
      value: details.lastResponse ? format(new Date(details.lastResponse), 'MMM dd,yyyy') : '***',
    },
  ];

  return (
    <div className={classes.root}>
      {preparedData.map(({ title, value }, key) => (
        <InfoBox title={title} value={value} key={key} />
      ))}
    </div>
  );
};

export default SubscriptionDetails;
