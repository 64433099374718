import { IGetScrapingInfo, IScrapingMedia, IScrapingPagination } from 'models/scraping';

import {
  getStatisticsCreatorOnlineApi,
  getStatisticsCreatorChatterInfoApi,
  getStatisticsCreatorChatterMediaApi,
  getStatisticsCreatorChatterVaultApi,
  getStatisticsCreatorChatterHelperApi,
} from '../config/api';
import { ISubscriber, ISubscriberInfo } from '../models/subscribers';
import { ISubscriberMedia } from '../models/media';
import { IHelper } from '../models/helpers';
import { IVaults } from '../models/vault';

import { BaseService } from './BaseService';

class ScrapingService extends BaseService {
  public async getOnlineSubscribers(creatorId: string, config = {}): Promise<{ subscribers: ISubscriber[] }> {
    return this.get(getStatisticsCreatorOnlineApi(creatorId), config);
  }

  public async getChatterInfo(
    { creatorId, chatterId }: IGetScrapingInfo,
    config = {},
  ): Promise<{ info: ISubscriberInfo }> {
    return this.get(getStatisticsCreatorChatterInfoApi(creatorId, chatterId), config);
  }

  public async forceUpdateChatterInfo(
    { creatorId, chatterId }: IGetScrapingInfo,
    config = {},
  ): Promise<{ info: ISubscriberInfo }> {
    return this.put(getStatisticsCreatorChatterInfoApi(creatorId, chatterId), {}, config);
  }

  public async getMedia(
    {
      creatorId,
      chatterId,
      offset = 0,
      limit = 10,
      status = null,
    }: IGetScrapingInfo & IScrapingPagination & IScrapingMedia,
    config = {},
  ): Promise<ISubscriberMedia> {
    return this.get(getStatisticsCreatorChatterMediaApi(creatorId, chatterId), {
      query: { offset, limit, ...(status ? { status } : {}) },
      ...config,
    });
  }

  public async getVault({ creatorId, chatterId }: IGetScrapingInfo, config = {}): Promise<IVaults> {
    return this.get(getStatisticsCreatorChatterVaultApi(creatorId, chatterId), config);
  }

  public async getHelpers({
    creatorId,
    chatterIds,
  }: Omit<IGetScrapingInfo, 'chatterId'> & { chatterIds: string[] }): Promise<{
    items: IHelper[];
  }> {
    return this.get(getStatisticsCreatorChatterHelperApi(creatorId), {
      query: {
        chatterIds,
      },
    });
  }
}

export const scrappingService = new ScrapingService();
