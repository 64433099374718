import { Navigate, useLocation } from 'react-router-dom';

import { getClosestSidebarPathIntersection } from 'constants/sidebar';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import { RoutePath } from 'config/routeConfig';
import { useAppSelector } from 'store';
import { getUserAuthData } from 'store/reducers/auth';
import usePermissions, { PermissionStatus } from 'hooks/usePermissions';
import { NoPermissionsScreen } from 'components/screens';

interface IAuthProvider {
  children: React.ReactNode;
  pagePermissions?: string[] | null;
}

export const AuthProvider = ({ children, pagePermissions }: IAuthProvider) => {
  const isAuth = useAppSelector(getUserAuthData);
  const location = useLocation();

  const { userPermissions, isSuperAdminPermission, permissionStatus } = usePermissions();

  if (!isAuth) return <Navigate to={RoutePath.signIn} state={{ from: location }} replace />;

  if (!isSuperAdminPermission && permissionStatus !== PermissionStatus.Idle) {
    // if user dont have permissions, need show no permission component/page
    if (!userPermissions?.length) return <NoPermissionsScreen />;

    // Check if current page available by permissions
    const intersection = pagePermissions?.filter((permission) => userPermissions?.includes(permission));

    if (!intersection?.length && location.pathname !== RoutePath.settings) {
      // if current page unavailable by permission,
      // redirect to closest possible page (on sidebar) OR settings (if not intersections)
      const path = getClosestSidebarPathIntersection(userPermissions);
      return <Navigate to={path} state={{ from: location }} replace />;
    }
  }

  return (
    <>
      <Sidebar />
      <main className="page-wrapper">
        <Header />
        {children}
      </main>
    </>
  );
};
