import { useTheme } from '@mui/material';

import { ScreenSizes } from 'modules/electron/constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',

      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '8px',
      },
    },
    boxes: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '10px',

      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '4px',
      },
    },
  };
};

export default useStyles;
