export enum ScreenSizes {
  screenXl = 1280,
  screenXXl = 1920,
}

export enum OFSidebarSize {
  Small = '290px',
  Middle = '375px',
  Big = '500px',
}
export enum ElectronSidebarSize {
  Big = '75px',
  Small = '50px',
}

export const USER_AGENT =
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36';
