import React, { useEffect, useContext, useState, useCallback } from 'react';

import usePolling from 'hooks/usePolling';
import { loadCreatorsList } from 'store/reducers/creators';
import { loadMembersCreatorsList, loadMembersList } from 'store/reducers/members';
import { loadAIBalance } from 'store/reducers/billing';

import { useAppDispatch, useAppSelector } from '../store';
import { getRolesList, checkSingleRoles } from '../store/reducers/roles';
import useHandleApiErrors from '../hooks/useHandleApiErrors';
import useCreators from '../hooks/useCreators';
import { ICreators } from '../models/creators';
import { IRoles } from '../models/roles';

type mapType<T> = Map<string, T>;

interface IAppProvider {
  children: React.ReactNode;
}

interface IAppContext {
  creatorMap: mapType<ICreators> | null;
  rolesMap: mapType<IRoles> | null;
  creators: ICreators[];
}

export const AppContext = React.createContext<IAppContext>({
  creatorMap: null,
  rolesMap: null,
  creators: [],
});

export const useChatterCharms = () => useContext(AppContext);

const INITIAL_POLLING_INTERVAL = 60 * 1000;

export const ChatterCharmsProvider = ({ children }: IAppProvider) => {
  const { items: roles } = useAppSelector(getRolesList);
  const dispatch = useAppDispatch();
  const [rolesMap, setRolesMap] = useState<mapType<IRoles> | null>(null);

  const { creators, creatorMap } = useCreators();

  // TODO: Move to useCase hook or replace to initAuthData thunk
  const loadInitData = useCallback(() => {
    dispatch(loadCreatorsList());
    dispatch(checkSingleRoles());
    dispatch(loadMembersCreatorsList());
    dispatch(loadMembersList());
    dispatch(loadAIBalance({ isSilently: true }));
  }, []);

  useHandleApiErrors();
  usePolling(INITIAL_POLLING_INTERVAL, loadInitData);

  // TODO: Replace to useRoles hook
  useEffect(() => {
    if (!roles.length) {
      return;
    }

    const rolesMap = new Map(roles.map((item) => [item.id!, item]));

    setRolesMap(rolesMap);
  }, [roles]);

  return <AppContext.Provider value={{ creatorMap, rolesMap, creators }}>{children}</AppContext.Provider>;
};
