import { useTheme } from '@mui/material';

import { OFSidebarSize, ScreenSizes } from '../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: (isSidebar: boolean) => ({
      width: '100%',
      maxWidth: isSidebar ? `calc(100% - ${OFSidebarSize.Big})` : 'unset',
      //paddingRight: isSidebar ? OFSidebarSize.Big : '0px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        maxWidth: isSidebar ? `calc(100% - ${OFSidebarSize.Middle})` : 'unset',
        //paddingRight: isSidebar ? OFSidebarSize.Middle : '0px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        maxWidth: isSidebar ? `calc(100% - ${OFSidebarSize.Small})` : 'unset',
        //paddingRight: isSidebar ? OFSidebarSize.Small : '0px',
      },
    }),
  };
};

export default useStyles;
