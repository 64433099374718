export enum MediaStatusEnum {
  Free = 'Free',
  NotPurchased = 'NotPurchased',
  Paid = 'Paid',
}

export enum MediaType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
}

export const MEDIA_NOT_SET = 'NOT_SET';

export interface IMedia {
  createdAt: string;
  image: string;
  price: string;
  ofMediaId: string;
  ofChatterId: string;
  text: string;
  status: MediaStatusEnum;
  type: MediaType;
  isBundle: boolean;
}

export interface ISubscriberMedia {
  media: IMedia[];
}
