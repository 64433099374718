import React, { useCallback } from 'react';

import { useWebviewContext } from '../../../providers';

const useWebviewHandlers = ({ ref }: { ref: React.MutableRefObject<any | null> }) => {
  const { setActiveChatterId } = useWebviewContext();

  const insertMessage = useCallback((text: string, checkWhitespace = false) => {
    if (!text) {
      return;
    }

    const runScript = (text: string, checkWhitespace: boolean) => {
      const form = document.getElementById('make_post_form');

      if (!form) {
        return;
      }

      const inputField = form.querySelector('textarea');

      if (!inputField) {
        return;
      }

      if (checkWhitespace) {
        inputField.value += inputField.value.length ? ' ' : '';
      }

      inputField.value += text;
      inputField.dispatchEvent(new Event('input', { bubbles: true }));
      inputField.focus();
    };

    if (!ref.current) {
      return;
    }

    ref.current?.executeJavaScript(`(${runScript})(${JSON.stringify(text)}, ${checkWhitespace})`);
  }, []);

  const handleBackButton = useCallback(() => {
    setActiveChatterId(null);
  }, []);

  return { insertMessage, handleBackButton };
};

export default useWebviewHandlers;
