import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack';
import React from 'react';

import { ReactComponent as CrossIcon } from 'assets/images/notification/cross.svg';
import { classNames } from 'utils/classNames';

import styles from './Snackbar.module.scss';

export enum SnackbarTheme {
  BLACK = 'black',
  WHITE = 'white',
}

interface StyledSnackbarProps extends CustomContentProps {
  text: string;
  theme?: SnackbarTheme;
}

export const StyledSnackbar = React.forwardRef<HTMLDivElement, StyledSnackbarProps>((props, ref) => {
  const {
    id,
    message,
    text,
    iconVariant,
    variant,
    anchorOrigin,
    autoHideDuration,
    persist,
    hideIconVariant,
    theme = SnackbarTheme.BLACK,
    ...other
  } = props;

  const onClose = () => {
    closeSnackbar(id);
  };

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      {...other}
      className={classNames(styles.root, {}, [styles[variant], styles[theme] ? styles[theme] : ''])}
      onClick={onClose}
    >
      <div className={styles.icon}>{iconVariant[variant]}</div>
      <div className={styles.content}>
        <h1 className={styles.title}>{message}</h1>
        {text && <p className={styles.text}>{text}</p>}
      </div>

      <div className={styles.close}>
        <CrossIcon />
      </div>
    </SnackbarContent>
  );
});
