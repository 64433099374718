import React from 'react';

import { classNames } from 'utils/classNames';

import styles from './NamedField.module.scss';

export interface INamedFieldProps {
  fieldName?: string;
  mandatory?: boolean;
  error?: string;
  className?: string;
  children?: JSX.Element;
  withoutErrorMessage?: boolean;
  isOptional?: boolean;
}

export const NamedField = ({
  fieldName,
  mandatory,
  error,
  children,
  withoutErrorMessage,
  className,
  isOptional,
}: INamedFieldProps) => {
  return (
    <div className={classNames(styles.field, { [styles.error]: error }, [className])}>
      {fieldName && (
        <div className={styles.fieldName}>
          {fieldName}
          {mandatory && <span className={styles.mandatory}> *</span>}
          {isOptional && <span className={styles.optional}> (optional)</span>}
        </div>
      )}
      {children}
      {error && !withoutErrorMessage && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};
