import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useElectronContext } from 'modules/electron/providers';
import useFetch from 'hooks/useFetch';
import { scrappingService } from 'services/ScrapingService';
import { IVault } from 'models/vault';
import { IGetScrapingInfo } from 'models/scraping';

import { useWebviewContext } from '../WebviewProvider';

import { IVaultProviderContext } from './interfaces';

const DefaultContext = {} as IVaultProviderContext;

export const ContentContext = React.createContext<IVaultProviderContext>(DefaultContext);

export const useVaultContext = () => React.useContext(ContentContext);

export const VaultProvider = ({ children }: { children: ReactNode }) => {
  const [isVaultOpen, setIsVaultOpen] = useState(false);

  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const loadVault = useCallback(
    async ({ creatorId, chatterId }: IGetScrapingInfo, config: AxiosRequestConfig = {}): Promise<IVault[]> => {
      const { vault } = await scrappingService.getVault(
        {
          creatorId,
          chatterId,
        },
        config,
      );

      return vault;
    },
    [],
  );

  const { data: vault, callLoadData, setData } = useFetch(loadVault);

  useEffect(() => {
    if (!activeCreator?.id || !activeChatterId) {
      setData([]);
      return;
    }

    //callLoadData({ creatorId: activeCreator?.id, chatterId: activeChatterId });
  }, [activeCreator?.id, activeChatterId, setData]);

  useEffect(() => {
    if (!isVaultOpen || !activeCreator?.id || !activeChatterId) {
      return;
    }

    callLoadData({ creatorId: activeCreator?.id, chatterId: activeChatterId });
  }, [isVaultOpen, callLoadData, activeCreator?.id, activeChatterId]);

  useEffect(() => {
    setIsVaultOpen(false);
    setData([]);
  }, [activeCreator?.id]);

  return (
    <ContentContext.Provider value={{ vault: vault ?? [], isVaultOpen, setIsVaultOpen }}>
      {children}
    </ContentContext.Provider>
  );
};
