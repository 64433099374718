import { ROLES_API, ROLES_PERMISSIONS_API } from 'config/api';
import { IRolePermissions, IRoles } from 'models/roles';

import { BaseService } from './BaseService';

class RoleService extends BaseService {
  public async getRoles(): Promise<{ roles: IRoles[] }> {
    return this.get(ROLES_API);
  }

  public async createRoles(data: IRoles): Promise<{ role: IRoles }> {
    return this.post(ROLES_API, data);
  }

  public async updateRoles(data: IRoles): Promise<{ role: IRoles }> {
    return this.patch(ROLES_API + `/${data.id}`, data);
  }

  public async removeRoles(id: string, agencyId: string) {
    console.log('CheckAgency', agencyId);
    return this.delete(ROLES_API + `/${id}`, { agencyId });
  }

  public async getRolePermissions(): Promise<IRolePermissions> {
    return this.get(ROLES_PERMISSIONS_API);
  }
}

export const roleService = new RoleService();
