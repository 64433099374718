import RouterProvider from 'providers/RouterProvider';
import { usePayment } from 'hooks/usePayment';
import useInitApp from 'hooks/useInitApp';
import ModalWindow from 'components/Modal/Modal';

import { BrandCanvas } from './BrandCanvas/BrandCanvas';

const App = () => {
  const inited = useInitApp();

  usePayment();

  if (!inited) {
    return <BrandCanvas isInitProccess />;
  }

  return (
    <div className="app">
      <RouterProvider />
      <ModalWindow />
    </div>
  );
};

export default App;
