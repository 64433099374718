import { Props } from 'react-apexcharts';

export default {
  options: {
    dataLabels: {
      enabled: false,
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 2,
      colors: ['#47AF2D', '#F2383A', '#FFFFFF'],
    },
  },
  series: [
    {
      data: [0],
    },
  ],
} as Props;
