import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Box } from '@mui/material';

import { useElectronContext } from '../../../../../../providers';
import { useWebviewContext } from '../../../../providers';
import { useOfSidebarContext } from '../../../providers';
import Dashboard from '../../Dashboard';
import OnlineFans, { StyleEnum } from '../../OnlineFans';

import useStyles from './useStyles';

const AccountHead = () => {
  const styles = useStyles();

  const { onlineSubscribers } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const { isActiveOFOpen: isOpen, setActiveOFOpen: setIsOpen } = useOfSidebarContext();

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(isOpen ? (window.innerHeight - 90) * 0.7 : 0);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [activeChatterId]);

  const handleClick = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <Box sx={styles.root}>
      <Button onClick={handleClick} sx={styles.button(isOpen)}>
        <OnlineFans style={isOpen ? StyleEnum.Default : StyleEnum.Gray} total={onlineSubscribers.length} />
      </Button>
      <Box sx={styles.collapse} style={{ height }}>
        <Dashboard disableOnlyFansIndicator />

        <Button onClick={handleClick} sx={styles.hideButton}>
          Hide
        </Button>
      </Box>
    </Box>
  );
};

export default AccountHead;
