import ReactGA from 'react-ga4';

import { GAAction, GACategory, GAEventValue } from 'constants/analytics';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const isProduction = process.env.REACT_APP_CC_ENV === 'PROD';

export const initGA = (): void => {
  if (isProduction) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
  }
};

export const setUserId = (userId: string | null): void => {
  if (isProduction) {
    ReactGA.set({ user_id: userId });
  }
};

export const logEvent = (category: GACategory, action: GAAction, label?: string, params?: GAEventValue): void => {
  if (isProduction) {
    ReactGA.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      ...params,
    });
  }
};

// Draft: Will remove it
/*export const initGA = (): void => {
  console.log('isProduction', isProduction, process.env.REACT_APP_GA_ID);
  if (isProduction && !window.gtag) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
    script.async = true;
    document.body.appendChild(script);

    const dataLayerScript = document.createElement('script');

    dataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', ${process.env.REACT_APP_GA_ID});
    `;

    document.body.appendChild(dataLayerScript);
  }
};

export const logPageView = (url: string): void => {
  if (isProduction && window.gtag) {
    window.gtag('config', process.env.REACT_APP_GA_ID, {
      page_path: url,
    });
  }
};

export const logEvent = (category: string, action: string, label = ''): void => {
  if (isProduction && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
};

export const logException = (description: string, fatal = false): void => {
  if (isProduction && window.gtag) {
    window.gtag('event', 'exception', {
      description: description,
      fatal: fatal,
    });
  }
};*/
