import { ReactComponent as NoConnectionIcon } from 'assets/images/no-internet.svg';

import Layout from '../Layout/Layout';

import styles from './BadConnectionScreen.module.scss';

const BadConnectionScreen = () => {
  return (
    <Layout>
      <div className={styles.section}>
        <div className={styles.icon}>
          <NoConnectionIcon />
        </div>
        <h1>Oops no internet connection found</h1>
        <p>Check your connection or try again</p>
      </div>
    </Layout>
  );
};

export default BadConnectionScreen;
