import React from 'react';
import { Box, Typography } from '@mui/material';

import { IProps } from './interfaces';
import useStyles from './useStyles';

const InfoBox = ({ title, value }: IProps) => {
  const styles = useStyles();
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title}>{value}</Typography>
      <Typography sx={styles.text}>{title}</Typography>
    </Box>
  );
};

export default InfoBox;
