const Reload = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.994 15.8981C14.9871 15.8979 14.9802 15.8979 14.9733 15.8979L3.56961 15.8979L3.78332 15.6826C4.19158 15.2713 4.18914 14.6069 3.77787 14.1987C3.36659 13.7904 2.70223 13.7929 2.29396 14.2041L0.304849 16.2079C-0.101291 16.6171 -0.101291 17.2772 0.304849 17.6864L2.29396 19.6902C2.70223 20.1014 3.36659 20.1039 3.77787 19.6956C4.18914 19.2874 4.19158 18.623 3.78332 18.2117L3.56961 17.9964L14.9493 17.9964C14.9573 17.9966 14.9653 17.9967 14.9734 17.9967C17.7572 17.9967 20.0009 15.7254 20.0009 12.9398V10.1774C20.0009 9.59789 19.5311 9.12811 18.9516 9.12811C18.3721 9.12811 17.9023 9.59789 17.9023 10.1774V12.9398C17.9023 14.5739 16.595 15.8869 14.994 15.8981Z"
        fill="#00AFF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00691 4.10217C5.01376 4.1023 5.02064 4.10236 5.02752 4.10236L16.4312 4.10237L16.2175 4.31765C15.8093 4.72893 15.8117 5.39329 16.223 5.80155C16.6343 6.20982 17.2986 6.20737 17.7069 5.7961L19.696 3.7923C20.1021 3.38317 20.1021 2.723 19.696 2.31386L17.7069 0.310063C17.2986 -0.101212 16.6343 -0.103655 16.223 0.304607C15.8117 0.712869 15.8093 1.37723 16.2175 1.78851L16.4312 2.0038L5.05153 2.0038C5.04354 2.00362 5.03553 2.00353 5.0275 2.00353C2.24362 2.00352 -1.52588e-05 4.27485 -1.52588e-05 7.0604V9.82283C-1.52588e-05 10.4023 0.469765 10.8721 1.04927 10.8721C1.62877 10.8721 2.09855 10.4023 2.09855 9.82283V7.0604C2.09855 5.42628 3.40592 4.11334 5.00691 4.10217Z"
        fill="#00AFF0"
      />
    </svg>
  );
};

export default Reload;
