import { useCallback, useEffect, useState, useRef, MutableRefObject } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SnackbarTheme } from 'components/Snackbar/Snackbar';
import { ForceUpdateStatus } from 'models/subscribers';
import { useElectronContext } from 'modules/electron/providers';
import { DollarSquare, MessageEdit, PersonalCard } from 'modules/electron/icons';

import { useWebviewContext } from '../../../../../../providers';
import Collapsible from '../../../Collapsible';
import MainAccount from '../../../MainAccount';
import PurchasingBehavior from '../../../PurchasingBehavior';
import SubscriptionDetails from '../../../SubscriptionDetails';
import Loader from '../../../../../../../Loader';

import useStyles from './useStyles';
import { STATUS_MESSAGES } from './constants';

const INTERVAL = 60 * 1000;

const FanInsights = () => {
  const { enqueueSnackbar } = useSnackbar();

  const styles = useStyles();
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout> | null>;

  const { activeCreator } = useElectronContext();
  const { isChatterLoading, chatter, activeChatterId, loadChatterInfo, forceUpdatedChatterInfo } = useWebviewContext();

  const [disableRefreshButton, setDisableRefreshButton] = useState(false);
  //const [refreshNotificationShown, setRefreshNotificationShown] = useState(false);

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const updateChatterInfo = useCallback(async () => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }
    setDisableRefreshButton(true);

    const { text, type = 'info', title = 'Media Status' } = STATUS_MESSAGES[ForceUpdateStatus.REQUESTED] ?? {};
    enqueueSnackbar(title, { variant: type, text, theme: SnackbarTheme.WHITE });

    loadChatterInfo({ creatorId: activeCreator.id, chatterId: activeChatterId, forceUpdate: true });

    clearTimer();
    timerRef.current = setTimeout(() => {
      setDisableRefreshButton(false);
    }, INTERVAL);
  }, [activeCreator?.id, activeChatterId]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    setDisableRefreshButton(false);
    clearTimer();
  }, [activeChatterId]);

  useEffect(() => {
    if (!activeCreator?.id || !activeChatterId) {
      return;
    }

    const forceUpdateDate = forceUpdatedChatterInfo[`${activeCreator?.id}:${activeChatterId}`];

    if (!forceUpdateDate) {
      return;
    }

    if (forceUpdateDate.date < new Date(+new Date() - INTERVAL)) {
      setDisableRefreshButton(false);
    } else {
      setDisableRefreshButton(true);

      clearTimer();
      timerRef.current = setTimeout(
        () => {
          setDisableRefreshButton(false);
        },
        new Date(+forceUpdateDate.date + INTERVAL).getTime() - new Date().getTime(),
      );
    }
  }, [forceUpdatedChatterInfo, activeChatterId, activeCreator?.id]);

  // Temporarily commented out
  /*useEffect(() => {
    if (chatter?.forceUpdateStatus) {
      const { text, type = 'info', title = 'Media Status' } = STATUS_MESSAGES[chatter.forceUpdateStatus] ?? {};
      enqueueSnackbar(title, { variant: type, text, theme: SnackbarTheme.WHITE });
    }
  }, [chatter]);*/

  if (isChatterLoading) {
    return <Loader />;
  }

  if (!chatter) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Collapsible title="Personal Information" icon={<PersonalCard />} open>
        <MainAccount
          details={chatter.personalInfo}
          isLoading={isChatterLoading}
          updateChatterInfo={updateChatterInfo}
          isRefreshDisabled={disableRefreshButton}
        />
      </Collapsible>
      <Collapsible title="Subscription Details" icon={<MessageEdit />}>
        <SubscriptionDetails details={chatter.subscriptionDetails} />
      </Collapsible>
      <Collapsible title="Purchasing Behavior" icon={<DollarSquare />}>
        <PurchasingBehavior details={chatter.purchasingBehaviour} />
      </Collapsible>
    </Box>
  );
};

export default FanInsights;
