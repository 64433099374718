import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useAppSelector } from 'store';
import { getUserAuthData } from 'store/reducers/auth';

const usePolling = (interval: number, asyncPollingCallback: () => Promise<void> | void, needInitLoading = true) => {
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;
  const isAuth = useAppSelector(getUserAuthData);

  const stopPolling = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const loadData = useCallback(() => {
    if (!isAuth) {
      return stopPolling();
    }
    asyncPollingCallback();
  }, [isAuth, asyncPollingCallback]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      loadData();
    }, interval);

    // Initial first request
    needInitLoading && loadData();

    return stopPolling;
  }, [interval, loadData]);
};

export default usePolling;
