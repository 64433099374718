import { useTheme } from '@mui/material';

import { ScreenSizes, OFSidebarSize } from '../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: OFSidebarSize.Big,
      height: '100vh',
      overflow: 'hidden',
      backgroundColor: '#FDFDFD',
      paddingTop: '86px',
      transition: 'width .5s ease-in-out',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: OFSidebarSize.Middle,
        paddingTop: '64px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: OFSidebarSize.Small,
        paddingTop: '56px',
      },

      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
    },
    overlay: (isActive: boolean) => ({
      height: '100%',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(118, 118, 118, 0.15)',
        backdropFilter: 'blur(3px)',
        display: isActive ? 'block' : 'none',
      },
    }),
  };
};

export default useStyles;
