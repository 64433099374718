import { memo, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';

import { MainStatisticsFieldNames, mainStatisticsList } from 'constants/statistics';
import { statisticsService } from 'services/StatisticsService';
import { StatisticFilters } from 'models/statistics';
import { debounce } from 'utils/debounce';
import { formatNumberToMln, formatNumberWithPrecision } from 'utils/convert';

import styles from './MainStatistics.module.scss';

interface MainStatisticsProps {
  className?: string;
  filters: StatisticFilters;
}

const MainStatistics = ({ filters }: MainStatisticsProps) => {
  const [data, setData] = useState(mainStatisticsList);
  const [loading, setLoading] = useState(false);

  const getAsyncAndConvertChartData = async (filtersArg: StatisticFilters) => {
    const updatedData = { ...data };
    const response = await statisticsService.getMainStatistics(filtersArg);
    Object.entries(response?.data).forEach(([key, value]) => {
      const isMoney = updatedData[key as MainStatisticsFieldNames].isMoney;
      const precisionValue = formatNumberWithPrecision(Number(value || 0));
      const cleanedNumber = precisionValue?.replace(/,/g, '');
      const convertedValue = formatNumberToMln(Number(cleanedNumber || 0)) || '';
      updatedData[key as MainStatisticsFieldNames].value = isMoney ? `$ ${convertedValue}` : convertedValue;
    });
    setData(updatedData);
    setLoading(false);
  };

  // Memoization of debounce to avoid dependence on re-rendering when changing filters
  const debouncedGetChartData = useMemo(() => debounce(getAsyncAndConvertChartData, 700), []);

  useEffect(() => {
    setLoading(true);
    debouncedGetChartData(filters);
  }, [filters]);

  return (
    <div className={styles.container}>
      {Object.values(data).map(({ title, value, Icon }, index) => (
        <div key={index} className={styles.tile}>
          <Icon className={styles.icon} />

          <div className={styles.info}>
            <p>{title}</p>
            <div className={styles.data}>
              {!loading ? (
                <p className={styles.value}>{value}</p>
              ) : (
                <Skeleton variant="rectangular" sx={{ height: '90%' }} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(MainStatistics);
