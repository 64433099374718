import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { scrappingService } from 'services/ScrapingService';
import { splitArrayToChunks } from 'utils/convert';
import usePolling from 'hooks/usePolling';
import { useElectronContext } from 'modules/electron/providers';
import { IHelper } from 'models/helpers';

import { useWebviewContext } from '../../../providers';
import { OF_URL } from '../../../constants';

const INITIAL_POLLING_INTERVAL = 60 * 1000;

interface IUseUIHelpersLoadData {
  ref: React.MutableRefObject<any | null>;
  helperIdsRef: React.MutableRefObject<(string | number)[]>;
  helpersRef: React.MutableRefObject<IHelper[]>;
}

const useUIHelpersLoadData = ({ ref, helperIdsRef, helpersRef }: IUseUIHelpersLoadData) => {
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;

  const { activeCreator } = useElectronContext();
  const { webviewUrl } = useWebviewContext();

  const loadHelpers = useCallback(
    async (chatterIds: string[]) => {
      if (!activeCreator?.id || !chatterIds.length) {
        return [];
      }

      try {
        const data = await scrappingService.getHelpers({
          creatorId: activeCreator.id,
          chatterIds: Object.assign({}, chatterIds),
        });

        return data?.items ?? [];
      } catch (e) {
        return [];
      }
    },
    [activeCreator?.id],
  );

  const updateHelpers = useCallback(async () => {
    const ids = helperIdsRef.current;
    if (!ids.length) {
      return;
    }

    const chunks = splitArrayToChunks(ids, 15);
    const results = await Promise.all(chunks.map((chunkIds: string[]) => loadHelpers(chunkIds)));
    const responseIds = results.flat(1);

    if (!responseIds?.length) {
      return;
    }

    helpersRef.current = responseIds;
  }, [loadHelpers, splitArrayToChunks]);

  const addIds = useCallback(
    (ids: string[]) => {
      helperIdsRef.current = [...new Set([...helperIdsRef.current, ...ids])];
      updateHelpers();
    },
    [updateHelpers],
  );

  usePolling(INITIAL_POLLING_INTERVAL, updateHelpers);

  // useEffect(() => {
  //   if (!window.electronAPI) {
  //     return;
  //   }
  //
  //   //Event from a main process
  //   const eventName = 'ui:helpers';
  //
  //   const cb = (_: IpcRendererEvent, { payload: { ids } }: { payload: any }) => {
  //     addIds(JSON.parse(ids));
  //   };
  //
  //   window.electronAPI.receive(eventName, cb);
  //
  //   return () => {
  //     window.electronAPI.receiveOff(eventName);
  //   };
  // }, [addIds]);

  useEffect(() => {
    if (!ref.current || !webviewUrl || webviewUrl.indexOf(OF_URL.CHATS) === -1) {
      return;
    }

    timerRef.current = setInterval(async () => {
      const ids = await ref.current?.executeJavaScript(
        `[...document.querySelectorAll('.b-chats__item')]?.map((element) => element.id).filter((id) => !!id)`,
      );

      if (ids && ids.length) {
        const diff = ids.filter((id: string) => !helperIdsRef?.current.includes(id));

        if (!diff.length) {
          return;
        }

        addIds(diff);
      }
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [webviewUrl, addIds]);
};

export default useUIHelpersLoadData;
