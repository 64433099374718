import React, { useCallback, useEffect } from 'react';

import usePermissions from 'hooks/usePermissions';
import { OFPermissions } from 'constants/roles';

import { useWebviewContext } from '../../providers';
import { OF_URL } from '../../constants';

interface IUseUserPermissions {
  ref: React.MutableRefObject<any | null>;
  isReady: boolean;
}

const useUserPermissions = ({ ref, isReady }: IUseUserPermissions) => {
  const { userPermissions, isSuperAdminPermission } = usePermissions();
  const { webviewUrl } = useWebviewContext();

  const handleHideDeleteAccountButton = useCallback(() => {
    ref.current?.insertCSS(`.b-settings__menu a[href^="/my/settings/account/delete"] { display: none !important }`);
  }, []);

  const handleHideBlocks = useCallback(() => {
    const permissions: string[] = userPermissions ?? [];

    const hideBlocks = () => {
      if (!ref.current) {
        return;
      }

      if (!permissions.includes(OFPermissions.CARDS_BANK)) {
        ref.current?.insertCSS(
          `.m-main-container a[href^="/my/payments"], .m-main-container a[href^="/my/banking"] { display: none !important }`,
        );
      }

      if (!permissions.includes(OFPermissions.CHAT_HIDING_FAN_BLOCKING)) {
        ref.current?.insertCSS(
          `ul.dropdown-menu li[role='presentation']:nth-of-type(10).m-danger, ul.dropdown-menu li[role='presentation']:nth-of-type(14).m-danger { display: none !important }`,
        );
      }

      if (!permissions.includes(OFPermissions.LOGOUT_BUTTON)) {
        ref.current?.insertCSS(`.m-main-container button[at-attr="logout"] { display: none !important }`);
      }

      if (!permissions.includes(OFPermissions.SETTINGS)) {
        ref.current?.insertCSS(`.m-main-container a[href^="/my/settings"] { display: none !important }`);
      }

      if (!permissions.includes(OFPermissions.STATEMENTS)) {
        ref.current.insertCSS(
          `.m-main-container a[href^="/my/statements"], .m-main-container a[href^="/my/statistics/statements"] { display: none !important }`,
        );
      }

      if (!permissions.includes(OFPermissions.OF_STATISTICS)) {
        ref.current?.insertCSS(
          `.m-main-container a[href^="/my/statistics"], .m-main-container a[href^="/my/settings/subscription"], .m-main-container a[href^="/my/statements/earnings"], .m-main-container a[href^="/my/stats/earnings"], .b-table.m-responsive.m-earnings { display: none !important }`,
        );
      }

      if (!permissions.includes(OFPermissions.VAULT)) {
        ref.current?.insertCSS(
          `.b-placeholder-item-selected button[at-attr='delete_item'] { display: none !important }`,
        );
      }
    };

    hideBlocks();
  }, [userPermissions]);

  useEffect(() => {
    //TODO: maybe there is no need to use webviewUrl and change it to isReady would be enough
    if (!window.electronAPI || !ref.current || !webviewUrl || isSuperAdminPermission) {
      return;
    }

    handleHideBlocks();
    setTimeout(handleHideBlocks, 5000); //in case page is loading too long
  }, [isSuperAdminPermission, webviewUrl, handleHideBlocks]);

  useEffect(() => {
    if (!window.electronAPI || !ref.current || !webviewUrl) {
      return;
    }

    if (webviewUrl.indexOf(OF_URL.SETTINGS_ACCOUNT) !== -1) {
      handleHideDeleteAccountButton();
      setTimeout(handleHideDeleteAccountButton, 5000); //in case page is loading too long
    }
  }, [handleHideDeleteAccountButton, webviewUrl]);
};

export default useUserPermissions;
