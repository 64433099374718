import { useCallback, useState, memo, useMemo } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { ModalType } from 'constants/modal';
import { AddOrUpdateStep } from 'constants/roles';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { classNames } from 'utils/classNames';
import { useAppDispatch, useAppSelector } from 'store';
import { getModalLoading, modalActions } from 'store/reducers/modal';
import { IRoles } from 'models/roles';
import { createOrUpdateRoles, getRolesData } from 'store/reducers/roles';
import { logEvent } from 'utils/analytics';
import { GAAction, GACategory } from 'constants/analytics';

import styles from './AddOrUpdateRole.module.scss';
import { RequestStep } from './RequestStep/RequestStep';
import { RolePermissionsStep } from './RolePermissionsStep/RolePermissionsStep';
interface AddOrUpdateRoleProps {
  isEdit?: boolean;
  onClose?: () => void;
}

export const AddOrUpdateRole = memo(({ isEdit, onClose }: AddOrUpdateRoleProps) => {
  const [activeStep, setActiveStep] = useState(AddOrUpdateStep.REQUEST);
  const data = useAppSelector(getRolesData);
  const loading = useAppSelector(getModalLoading);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const steps = useMemo(() => Object.keys(AddOrUpdateStep) as AddOrUpdateStep[], []);
  const backButtonShown = useMemo(() => activeStep !== steps[0], [activeStep, steps]);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: data as IRoles,
    onSubmit: async (values) => {
      try {
        const requestData = values;
        const response = await dispatch(createOrUpdateRoles(requestData));
        if (response.meta.requestStatus === 'fulfilled') {
          if (!isEdit) {
            logEvent(
              GACategory.Platform,
              GAAction.AddRole,
              `Role: ${values.name} | Params: ${JSON.stringify(values)}`,
              { role: values.name, params: JSON.stringify(values) },
            );
          }
          dispatch(modalActions.setModalType({ type: ModalType.NONE }));

          const message = `The role has been ${isEdit ? 'modified' : 'created'}!`;
          enqueueSnackbar(message, { variant: 'success' });
        }
      } catch {}
    },
    enableReinitialize: true,
  });

  const onChangeData = useCallback(
    (value: any, targetName?: string) => {
      targetName && setFieldValue(targetName, value);
    },
    [setFieldValue],
  );

  const onNextStep = useCallback(() => {
    const indexStep = steps.indexOf(activeStep);
    const nextStep = steps[indexStep + 1];

    if (!nextStep) return;
    setActiveStep(nextStep);
  }, [activeStep, steps]);

  const onBackStep = useCallback(() => {
    if (loading) return;

    const indexStep = steps.indexOf(activeStep);
    const prevStep = steps[indexStep - 1];

    if (!prevStep) return;
    setActiveStep(prevStep);
  }, [activeStep, steps, loading]);

  const render = (): JSX.Element | null => {
    switch (activeStep) {
      case AddOrUpdateStep.REQUEST: {
        const { name, description, color } = values;

        return (
          <RequestStep
            isEdit={isEdit}
            data={{ name, description, color }}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }
      case AddOrUpdateStep.ROLE_PERMISSIONS: {
        const { permissions } = values;
        return (
          <RolePermissionsStep
            isEdit={isEdit}
            data={{ permissions }}
            onChangeData={onChangeData}
            onSubmit={handleSubmit}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, { [styles.withoutBackBtn]: !backButtonShown })}>
        <span onClick={onBackStep}>
          <ArrowIcon />
        </span>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>

      {render()}
    </div>
  );
});
