import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      color: 'var(--black-color)',
      fontSize: '19px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '16px',
        '& svg': {
          transform: 'scale(0.8)',
        },
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
        '& svg': {
          transform: 'scale(0.8)',
        },
      },
    },
  };
};

export default useStyles;
