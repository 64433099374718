import { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosError, isAxiosError } from 'axios';

import { ICreatorSession, ICreatorSessionItem } from 'models/sessions';
import { creatorsService } from 'services/CreatorsService';
import { captureExceptionWithErrorCode, captureMessageWithTag } from 'utils/sentry';
import { useElectronContext } from 'modules/electron/providers';
import { CreatorsSessionStatus } from 'constants/creators';
import { delay } from 'utils/delay';

import { useProxy } from './index';

const useSession = () => {
  const unmountedRef = useRef(false);

  const { isProxy, setProxy, clearProxy } = useProxy();

  const { activeCreator, setIsActivatingModel, backToAdmin, deactivateCreatorSession } = useElectronContext();

  const [isSessionActive, setIsSessionActive] = useState(false);
  const [creatorSession, setCreatorSession] = useState<ICreatorSession | null>(null);

  const getCreatorSession = useCallback(async (creatorId: string): Promise<ICreatorSession | null> => {
    const data = await creatorsService.getCreatorSessionUrl(creatorId);

    const { session } = data;

    return !session.length ? null : data;
  }, []);

  const setCookie = useCallback(async (session: ICreatorSessionItem[], creatorId: string) => {
    const now = new Date().getTime() / 1000;
    const sess = session.find((item) => item.name === 'sess');
    if (sess?.expirationDate && sess?.expirationDate <= now) {
      captureMessageWithTag(`sess expirationDate is expired creatorId=${creatorId}`, 'LOGIN_SESS_EXPIRED');
    }

    //2. Set Cookie (prolong for 10 days in ms)
    const futureDate = new Date().getTime() + 10 * 24 * 60 * 60 * 1000;

    if (unmountedRef.current) {
      return null;
    }

    console.log('Set cookie');
    await window.electronAPI.invokeCookieSet({
      payload: session.map(({ expirationDate = null, ...rest }) => ({
        ...rest,
        ...(expirationDate ? { expirationDate: futureDate / 1000 } : {}),
      })),
    });

    return true;
  }, []);

  const clearSessionData = useCallback(async () => {
    //clear all data when Webview is closed
    console.log('Clear All Data');
    await clearProxy();
    await window.electronAPI.invokeCookieSet({
      payload: [],
    });
  }, []);

  const handleLoginError = useCallback(
    ({ error, creatorId, sessionId }: { error: any; creatorId: string; sessionId?: string }) => {
      console.error(`error: ${error}`);
      captureExceptionWithErrorCode(error, 'AUTOLOGIN_FAIL');

      if (!unmountedRef.current) {
        setIsActivatingModel(false);
        if (isAxiosError(error) && error.code === AxiosError.ERR_NETWORK) {
          backToAdmin();
        } else if (sessionId) {
          deactivateCreatorSession({ creatorId: creatorId, sessionId, reason: 'AUTOLOGIN_FAIL' });
        } else {
          backToAdmin();
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (!activeCreator || activeCreator.sessionStatus !== CreatorsSessionStatus.ACTIVE || !activeCreator.proxy) {
      return;
    }

    setIsActivatingModel(true);

    const { id: creatorId, proxy: creatorProxy } = activeCreator;

    (async () => {
      let sessionId;

      clearSessionData();
      await delay(3);

      try {
        const isProxyActivated = await setProxy(creatorProxy);
        if (!isProxyActivated) {
          return;
        }

        const sessionData = await getCreatorSession(creatorId);

        if (sessionData === null) {
          captureMessageWithTag(`LOGIN: creator session is empty creatorId: ${creatorId}`, 'LOGIN_CREATOR_SESSION');
          setIsActivatingModel(false);
          backToAdmin();
          return;
        }
        sessionId = sessionData.sessionId;

        await setCookie(sessionData.session, creatorId);

        setIsSessionActive(true);
        setCreatorSession(sessionData);
      } catch (error) {
        handleLoginError({ error, creatorId, sessionId });
      }
    })();
  }, [activeCreator]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
      clearSessionData();
    };
  }, []);

  return { creatorSession, isSessionActive, isProxy, handleLoginError };
};

export default useSession;
