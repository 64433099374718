import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      height: '100%',
      padding: ' 36px 28px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      background: 'inherit',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        padding: '26px 21px 0',
        gap: '25px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: ' 10px 15px 0',
        gap: '16px',
      },
    },
    list: {
      position: 'relative',
      overflow: 'auto',
      margin: '0 -15px',
      height: 'calc(100% - 200px)',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        height: 'calc(100% - 150px)',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        height: 'calc(100% - 140px)',
      },
    },
    item: {
      padding: '0 15px',
    },
  };
};

export default useStyles;
