import { memo, useState, useEffect } from 'react';
import { format } from 'date-fns';

import { BillingHistoryColumns } from 'constants/billing';
import { classNames } from 'utils/classNames';
import { ReactComponent as HistoryIcon } from 'assets/images/billing/history.svg';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { IColumn } from 'models/table';
import { billingService } from 'services/BillingService';
import { ISubscriptionsHistoryItem } from 'models/billing';

import styles from './BillingHistory.module.scss';

const columns: IColumn[] = [
  {
    id: BillingHistoryColumns.DATE,
    fieldName: 'createdAt',
    label: 'Date',
    width: 150,
  },
  {
    id: BillingHistoryColumns.AMOUNT,
    fieldName: 'total',
    label: 'Amount',
    width: 150,
  },
  {
    id: BillingHistoryColumns.DESCRIPTION,
    fieldName: 'description',
    label: 'Description',
    width: 150,
  },
];

interface MembersRoleProps {
  onClose?: () => void;
}

export const BillingHistory = memo(({ onClose }: MembersRoleProps) => {
  const [items, setItems] = useState<ISubscriptionsHistoryItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const history = await billingService.getSubscriptionsHistory();
      setItems(history.items);
      setLoading(false);
    })();
  }, []);

  const onRenderColumnItem = (item: ISubscriptionsHistoryItem, index: number, column: IColumn) => {
    const fieldName = column?.fieldName;

    switch (column?.id) {
      case BillingHistoryColumns.DATE: {
        return <div className={styles.date}>{format(new Date(item.createdAt), 'MMM dd yyyy, HH:mm')}</div>;
      }
      case BillingHistoryColumns.AMOUNT: {
        const amount = parseInt(item.total) / 100;
        let amountClassName = styles.loss;

        if (amount === 0) {
          amountClassName = styles.zero;
        }

        return <div className={classNames(styles.amount, {}, [amountClassName])}>${amount}</div>;
      }
      default: {
        if (fieldName && item) {
          return <p>{(item as any)[fieldName]}</p>;
        }
        return <></>;
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>

      <div className={styles.body}>
        <HistoryIcon className={styles.icon} />
        <h2 className={styles.title}>Billing History</h2>
        <CustomizedTable
          items={items}
          columns={columns}
          onRenderColumnItem={onRenderColumnItem}
          loading={loading}
          className={styles.table}
        />
      </div>
    </div>
  );
});
