import { SnackbarProvider as DefaultSnackProvider } from 'notistack';

import { StyledSnackbar } from 'components/Snackbar/Snackbar';
import { ReactComponent as ErrorIcon } from 'assets/images/notification/error.svg';
import { ReactComponent as InfoIcon } from 'assets/images/notification/info.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/notification/success.svg';
import { ReactComponent as WarningIcon } from 'assets/images/notification/warning.svg';

declare module 'notistack' {
  interface VariantOverrides {
    info: {
      text?: string;
      theme?: string;
    };
    error: {
      text?: string;
      theme?: string;
    };
    warning: {
      text?: string;
      theme?: string;
    };
    success: {
      text?: string;
      theme?: string;
    };
  }
}

interface SnackProviderProps {
  children?: React.ReactNode;
}

const SnackProvider = ({ children }: SnackProviderProps): JSX.Element => {
  //const variantList: VariantType[] = ['error', 'info', 'success', 'warning'];

  return (
    <DefaultSnackProvider
      autoHideDuration={4000}
      maxSnack={3}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      preventDuplicate={true}
      Components={{ info: StyledSnackbar, success: StyledSnackbar, warning: StyledSnackbar, error: StyledSnackbar }}
      iconVariant={{
        error: <ErrorIcon />,
        info: <InfoIcon />,
        success: <SuccessIcon />,
        warning: <WarningIcon />,
      }}
    >
      {children}
    </DefaultSnackProvider>
  );
};

export default SnackProvider;
