import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useElectronContext } from 'modules/electron/providers';
import { IHelper } from 'models/helpers';
import { WebviewURL } from 'constants/externalLinks';

import { useWebviewContext } from '../../../providers';
import { OF_URL } from '../../../constants';

import useUIHelpersStyles from './useUIHelpersStyles';
import useUIHelpersLoadData from './useUIHelpersLoadData';

const useUIHelpers = ({ ref, isReady }: { ref: React.MutableRefObject<any | null>; isReady: boolean }) => {
  const { activeCreator } = useElectronContext();

  const { setActiveChatterId, webviewUrl, activeChatterId } = useWebviewContext();

  const helpersRef = useRef<IHelper[]>([]);
  const helperIdsRef = useRef<string[]>([]);

  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;
  const isStyledRef = useRef(false);

  useUIHelpersLoadData({ ref, helpersRef, helperIdsRef });
  const { mainStyles, generateStyles } = useUIHelpersStyles();

  useEffect(() => {
    helpersRef.current = [];
    helperIdsRef.current = [];
  }, [activeCreator?.id, isReady]);

  const handleStyles = useCallback(() => {
    ref.current?.insertCSS(mainStyles());
  }, [mainStyles]);

  const handleRunScript = useCallback(
    async (ids: IHelper[]) => {
      // let unmounted = false;

      const styles = ids.reduce((acc, item) => `${acc} ${generateStyles(item)}`, '');
      ref.current?.insertCSS(styles);

      // if (!unmounted && ref.current) {
      //   ref.current.insertCSS(styles);
      // }

      // return () => {
      //   unmounted = true;
      // };
    },
    [generateStyles],
  );

  const handleUIHelpersUpdate = useCallback(() => {
    if (!helpersRef.current.length) {
      return;
    }

    if (!isStyledRef.current) {
      isStyledRef.current = true;
      handleStyles();
    }

    handleRunScript(helpersRef.current);
  }, [handleStyles, handleRunScript]);

  useEffect(() => {
    if (!window.electronAPI || !ref.current || !webviewUrl || webviewUrl.indexOf(OF_URL.CHATS) === -1) {
      return;
    }

    if (webviewUrl.indexOf(OF_URL.CHAT) !== -1) {
      //Chat witch chatter is open
      const chatterId = webviewUrl.replace(OF_URL.CHAT, '').replace('/', '');

      if (chatterId) {
        setActiveChatterId(chatterId);
      }
    }

    timerRef.current = setInterval(handleUIHelpersUpdate, 5000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [webviewUrl, handleUIHelpersUpdate]);

  useEffect(() => {
    return () => {
      isStyledRef.current = false;
    };
  }, [activeCreator?.id]);

  useEffect(() => {
    if (!activeChatterId || !ref.current) {
      return;
    }

    (async () => {
      const location = await ref.current?.executeJavaScript(`window.location.pathname;`);

      if (location.indexOf(`/chats/chat/${activeChatterId}`) === -1) {
        ref.current?.executeJavaScript(`window.location = '${WebviewURL.OF}my/chats/chat/${activeChatterId}/'`);
        isStyledRef.current = false;
      }
    })();
  }, [activeChatterId]);
};

export default useUIHelpers;
