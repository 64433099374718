import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { formatNumberWithPrecision } from 'utils/convert';
import { useElectronContext } from 'modules/electron/providers';

import { useVaultContext, useWebviewContext } from '../../../providers';

import useVaultStyles from './useVaultStyles';

const useVault = ({ ref }: { ref: React.MutableRefObject<any | null> }) => {
  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();
  const { vault, isVaultOpen } = useVaultContext();

  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;
  const isStyledRef = useRef(false);

  const { mainStyles } = useVaultStyles();

  useEffect(() => {
    isStyledRef.current = false;
  }, [activeCreator?.id, activeChatterId]);

  const handleStyles = useCallback(() => {
    ref.current?.insertCSS(mainStyles());
  }, [mainStyles]);

  const runVaultMatch = useCallback(async () => {
    const runScript = (media: any, formatNumberWithPrecision: (number?: number) => string) => {
      //@ts-ignore
      media.forEach(({ thumbName, status, price = 0 }) => {
        const image = document.querySelector(`.b-photos__item:not(.cc-updated) img[src*="${thumbName}"]`);

        if (!image) {
          return;
        }

        const imageWrap = image.parentElement;

        if (!imageWrap) {
          return;
        }

        let total = +price > 0 ? formatNumberWithPrecision(price) + '$' : '';
        if (status === 'Free') {
          total = 'Free';
        } else if (status === 'NotPurchased') {
          total += ' unpaid';
        }

        const priceElement = document.createElement('span');

        priceElement.setAttribute('class', `cc-vault-price ${status.toLowerCase()}`);
        priceElement.appendChild(document.createTextNode(total));

        imageWrap.appendChild(priceElement);
        imageWrap.classList.add('cc-updated');
      });

      return;
    };

    if (!ref.current) {
      return;
    }

    if (vault && vault.length) {
      await ref.current?.executeJavaScript(`(${runScript})(${JSON.stringify(vault)}, ${formatNumberWithPrecision})`);
    }
  }, [vault]);

  const handleVault = useCallback(() => {
    if (!isStyledRef.current) {
      handleStyles();
      isStyledRef.current = true;
    }

    runVaultMatch();
  }, [runVaultMatch, handleStyles]);

  useEffect(() => {
    if (!isVaultOpen || !vault.length) {
      return;
    }

    timerRef.current = setInterval(handleVault, 3000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isVaultOpen, vault, handleVault]);
};

export default useVault;
