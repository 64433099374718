export interface ISubscriber {
  avatar: string | null;
  id: string;
  lastSeen: string;
  name: string;
  totalSpent: number;
  username: string;
}

export interface IPersonalInfo {
  birthday: string;
  name: string;
  location: string;
  avatar: string;
  username: string;
}

export interface ISubscriptionDetails {
  subscribeAt: string;
  subscribed: boolean;
  hasActivePaidSubscriptions: boolean;
  lastResponse?: string;
}

export interface IPurchasingBehaviour {
  highestPrice: number;
  total: number;
  averagePrice: number;
  lastPurchasePrice: number;
  lastPurchaseDate: string;
  buyRate: {
    total: number;
    bought: number;
  };
}

export enum ForceUpdateStatus {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCEED_ERROR = 'PROCEED_ERROR',
  PROCEED = 'PROCEED',
}

export interface ISubscriberInfo {
  ofId: string;
  forceUpdateStatus?: ForceUpdateStatus | null;
  personalInfo: IPersonalInfo;
  subscriptionDetails: ISubscriptionDetails;
  purchasingBehaviour: IPurchasingBehaviour;
  updatedAt?: string | null;
  createdAt?: string | null;
  lastForceUpdatedAt?: string | null;
  creatorId: string;
}
