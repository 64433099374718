// @ts-nocheck
// [TODO]: Rework all type in useImageLoaded
import { classNames } from 'utils/classNames';
import brandLogo from 'assets/images/whiteBrandLogo.png';
import useImageLoaded from 'hooks/useImageLoaded';

import styles from './BrandCanvas.module.scss';

interface BrandCanvasProps {
  className?: string;
  isInitProccess?: boolean;
}

export const BrandCanvas = ({ className, isInitProccess }: BrandCanvasProps) => {
  const [ref] = useImageLoaded();

  return (
    <div className={classNames(styles.BrandCanvas, { [styles.initializing]: isInitProccess }, [className])}>
      <img ref={ref} src={brandLogo} alt="Brand Logo" />
    </div>
  );
};
