import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';

import { ModalType } from 'constants/modal';
import { PaymentMethod } from 'constants/billing';
import { Button, ThemeButton } from 'components/Button/Button';
import { useAppSelector } from 'store';
import {
  billingActions,
  getBillingAmount,
  getBillingBalance,
  getBillingLoading,
  getBillingSubscriptions,
} from 'store/reducers/billing';
import { modalActions } from 'store/reducers/modal';
import { classNames } from 'utils/classNames';
import Input from 'components/fields/Input/Input';
import { ReactComponent as BalanceIcon } from 'assets/images/billing/balance.svg';
import { ReactComponent as FundsIcon } from 'assets/images/billing/funds.svg';
import { ReactComponent as PaymentIcon } from 'assets/images/billing/payment.svg';
import Select from 'components/fields/Select/Select';
import { convertToDropdownArray, convertToDropdownItem, isNumber } from 'utils/convert';
import useSubscriptions from 'hooks/useSubscriptions';

import styles from './PaymentBalance.module.scss';

interface PaymentBalanceProps {
  className?: string;
}

const BillingLimits = {
  Min: 10,
  Max: 50000,
};

export const PaymentBalance = ({ className }: PaymentBalanceProps) => {
  const billingAmount = useAppSelector(getBillingAmount);
  const balance = useAppSelector(getBillingBalance);
  const loading = useAppSelector(getBillingLoading);
  const { loading: nextPaymentLoading } = useAppSelector(getBillingSubscriptions);
  const { nextPaymentDate, nextPaymentAccounts } = useSubscriptions();
  const [error, setError] = useState<string>();

  const dispatch = useDispatch();

  const paymentMethodDropdownOptions = useMemo(() => [PaymentMethod.CREDIT_CARD], []);

  const checkBillingAmount = useCallback(() => {
    if (!isNumber(billingAmount)) {
      setError('*Value must be a number');
      return false;
    }

    if (+billingAmount < +BillingLimits.Min) {
      setError(`*Value must be more than ${BillingLimits.Min}$`);
      return false;
    }

    if (+billingAmount > +BillingLimits.Max) {
      setError(`Value must be less than ${BillingLimits.Max}$`);
      return false;
    }

    return true;
  }, [billingAmount]);

  useEffect(() => {
    setError('');

    if (!billingAmount?.length) {
      return;
    }

    checkBillingAmount();
  }, [billingAmount, checkBillingAmount]);

  const onClickGoToPaymentBtn = () => {
    const isValid = checkBillingAmount();
    isValid && dispatch(modalActions.setModalType({ type: ModalType.BILLING_PAYMENT }));
  };

  return (
    <div className={styles.payment}>
      <div className={classNames(styles.card, {}, [styles.balance])}>
        <div className={styles.icon}>
          <BalanceIcon />
        </div>
        <h3 className={styles.title}>Current Balance</h3>
        <p className={styles.value}>{loading ? <Skeleton variant="rounded" /> : `$${balance}`}</p>
        <p className={styles.description}>
          This balance represents your available credits for purchasing services on our platform.
        </p>
      </div>
      <div className={classNames(styles.card, {}, [styles.funds])}>
        <div className={styles.icon}>
          <FundsIcon />
        </div>
        <h3 className={styles.title}>Add Funds</h3>
        <div className={styles.paymentMethod}>
          <Input
            value={billingAmount}
            onChange={(value) => dispatch(billingActions.setAmount(value))}
            className={classNames(styles.control, {}, [styles.input])}
            placeholder="Amount"
            error={error}
          />
          <Select
            className={classNames(styles.control, {}, [styles.select])}
            placeholder="Payment Method"
            options={convertToDropdownArray(paymentMethodDropdownOptions)}
            defaultValue={PaymentMethod.CREDIT_CARD}
            isSearchable={false}
            value={convertToDropdownItem(PaymentMethod.CREDIT_CARD)}
          />
        </div>
        <Button
          theme={ThemeButton.PRIMARY}
          onClick={onClickGoToPaymentBtn}
          className={styles.paymentBtn}
          disabled={!billingAmount}
        >
          Go to payment
        </Button>
      </div>
      <div className={classNames(styles.card, {}, [styles.billingHistory])}>
        <div className={styles.icon}>
          <PaymentIcon />
        </div>
        <h3 className={styles.title}>Next Payment Due & Billing History</h3>
        <p className={styles.nextPayment}>
          {nextPaymentLoading || !nextPaymentDate ? (
            <Skeleton variant="rounded" />
          ) : (
            `on ${format(nextPaymentDate, 'MMMM dd, yyyy')}`
          )}
        </p>
        <div className={styles.history}>
          <div className={styles.accounts}>
            <p>
              Accounts: <span>{nextPaymentAccounts?.length}</span>
            </p>
            {nextPaymentAccounts?.length && <span>@{nextPaymentAccounts?.join(', ')}</span>}
          </div>
          <Button
            className={styles.billingHistoryBtn}
            theme={ThemeButton.PRIMARY}
            onClick={() => dispatch(modalActions.setModalType({ type: ModalType.BILLING_HISTORY }))}
          >
            Billing History
          </Button>
        </div>
      </div>
    </div>
  );
};
