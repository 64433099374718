import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';

import { MediaStatusEnum } from 'models/media';

import Loader from '../../../../../../../Loader';

import MediaList from './MediaList';
import useHistory from './hooks/useHistory';
import { FILTER_MEDIA_TAGS } from './constants';
import useStyles from './styles';

const History = () => {
  const styles = useStyles();

  const [status, setStatus] = useState(FILTER_MEDIA_TAGS[0].key);

  const setFilter = (status: string): MediaStatusEnum | null =>
    status === FILTER_MEDIA_TAGS[0].key ? null : (status as MediaStatusEnum);

  const { media, loadNextPage, hasNextPage, isLoading } = useHistory(setFilter(status));

  const renderContent = () => {
    if (isLoading && !media?.length) {
      return <Loader />;
    }

    if (!media?.length) {
      return <Typography sx={styles.helperText}>No Data Available</Typography>;
    }

    return (
      <MediaList media={media} isNextPageLoading={isLoading} loadNextPage={loadNextPage} hasNextPage={hasNextPage} />
    );
  };

  return (
    <>
      <Typography sx={styles.title}>Search by:</Typography>
      <Box sx={styles.tags}>
        {FILTER_MEDIA_TAGS.map(({ key, label }) => (
          <Button
            key={key}
            onClick={() => {
              setStatus(key);
            }}
            sx={styles.button(key === status)}
          >
            {label}
          </Button>
        ))}
      </Box>
      <Box sx={styles.gallery}>{renderContent()}</Box>
    </>
  );
};

export default History;
