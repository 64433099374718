import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { OF_SIDEBAR_ROUTE } from '../constants/enums';

interface IContentContext {
  sidebarRoute: OF_SIDEBAR_ROUTE;
  //setSidebarRoute: (route: OF_SIDEBAR_ROUTE) => void;
  setSidebarRoute: Dispatch<SetStateAction<OF_SIDEBAR_ROUTE>>;
  isActiveOFOpen: boolean;
  //setActiveOFOpen: (flag: boolean) => void;
  setActiveOFOpen: Dispatch<SetStateAction<boolean>>;
}

const DefaultContext = {} as IContentContext;

export interface IContentProviderProps {
  children: ReactNode;
}

export const ContentContext = React.createContext<IContentContext>(DefaultContext);

export const useOfSidebarContext = () => React.useContext(ContentContext);

export const OfSidebarProvider = ({ children }: IContentProviderProps) => {
  const [sidebarRoute, setSidebarRoute] = useState(OF_SIDEBAR_ROUTE.HOME);
  const [isActiveOFOpen, setActiveOFOpen] = useState(false);

  return (
    <ContentContext.Provider
      value={{
        sidebarRoute,
        setSidebarRoute,
        isActiveOFOpen,
        setActiveOFOpen,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
