import React from 'react';

import { ModalType } from 'constants/modal';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';
import { Button, ThemeButton } from 'components/Button/Button';
import { logoutApp } from 'store/reducers/auth';
import { ModalConfig } from 'store/reducers/modal';

import { useAppDispatch } from '../../../store';

import styles from './SessionExpired.module.scss';

interface SessionExpiredProps {
  config?: ModalConfig[ModalType.SESSION_EXPIRED];
  onClose: () => void;
}

export const SessionExpired = ({ config, onClose }: SessionExpiredProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(logoutApp());
    onClose();
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <WarningIcon />
        <h2 className={styles.title}>{config?.title}</h2>
        <p className={styles.text}>{config?.description}</p>
        <div className={styles.actions}>
          <Button theme={ThemeButton.PRIMARY} onClick={handleClick}>
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
};
