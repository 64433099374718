import React, { useMemo } from 'react';

import { useAppSelector } from '../../../store';
import { getMembersState } from '../../../store/reducers/members';
import { useChatterCharms } from '../../../providers/ChatterCharmsProvider';
import { CreatorsSessionStatus } from '../../../constants/creators';

const useAssignCreator = () => {
  const { memberActiveModal, membersCreators } = useAppSelector(getMembersState);
  const { creatorMap } = useChatterCharms();

  const activeCreators = useMemo(() => {
    if (creatorMap) {
      const data = [...creatorMap].filter(([_, { sessionStatus }]) => sessionStatus === CreatorsSessionStatus.ACTIVE);
      return data.length ? new Map(data) : null;
    }

    return null;
  }, [creatorMap]);

  const activeMemberCreators = React.useMemo(() => {
    if (!memberActiveModal?.id) {
      return [];
    }

    return membersCreators.filter((item) => item.memberId === memberActiveModal?.id).map((item) => item.creatorId);
  }, [memberActiveModal?.id, membersCreators]);

  const nonAssignedMemberCreators = React.useMemo(() => {
    if (memberActiveModal?.id && activeCreators) {
      return [...activeCreators]
        .filter(([creatorId]) => !activeMemberCreators.includes(creatorId as 'string'))
        .map(([_, creator]) => ({
          value: creator.id,
          label: creator.name,
        }));
    }

    return [];
  }, [memberActiveModal?.id, activeMemberCreators, activeCreators]);

  return { assignedCreators: activeMemberCreators, nonAssignedCreators: nonAssignedMemberCreators };
};

export default useAssignCreator;
