import { useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';

import { classNames } from 'utils/classNames';
import { AIPlanAccount, IAISubscriptionPaymentPlan, ISelectedAIPlanData } from 'models/billing';
import { Button, SizeButton, ThemeButton } from 'components/Button/Button';
import { useAppSelector } from 'store';
import { getBillingAISubscriptions } from 'store/reducers/billing';
import useAISubscription from 'components/Billing/hooks/useAISubscription';

import styles from './BuyAICredits.module.scss';

export const BuyAICredits = () => {
  const [selectedPlan, setSelectedPlan] = useState<ISelectedAIPlanData>();
  const { loading, items } = useAppSelector(getBillingAISubscriptions);
  const { paymentLoading, getPlanTotals, createPaymentAISubscription } = useAISubscription(selectedPlan);

  const [totalWithDiscount, savedTotal] = getPlanTotals();

  const onClickMakePayment = async () => {
    await createPaymentAISubscription();
    setSelectedPlan(undefined);
  };

  const renderPlans = useCallback(
    () =>
      Object.values(items).map((value: IAISubscriptionPaymentPlan, index) => <div key={index}>{value.credits}</div>),
    [items],
  );

  const renderPrices = () =>
    Object.entries(items).map(([plan, info]: [string, IAISubscriptionPaymentPlan], index) => {
      const { discount = 0, regular = 0 } = info.price || {};
      const total = discount || regular;
      const isSelected = selectedPlan?.name === plan;
      const value = !isSelected ? { name: plan as AIPlanAccount, info } : undefined;

      return (
        <div key={index} className={styles.cell}>
          <span
            className={classNames(styles.plan, { [styles.selected]: isSelected })}
            onClick={() => setSelectedPlan(value)}
          >
            {!!discount && <span className={styles.discount}>${regular}</span>}
            <span>${total}</span>
          </span>
        </div>
      );
    });

  return (
    <>
      <h3 className={styles.title}>Buy AI Credits</h3>
      <div className={styles.table}>
        {loading ? (
          <Skeleton width="100%" height={100} />
        ) : (
          <>
            <div className={styles.aiTokens}>{renderPlans()}</div>
            <div className={styles.prices}>{renderPrices()}</div>
          </>
        )}
        <div className={styles.footer}>
          <div className={styles.totals}>
            <p>
              Total Amount to Pay: <span>${totalWithDiscount}</span>
            </p>
            <p>Money Saved: ${savedTotal}</p>
          </div>
          <Button
            className={styles.makePaymentBtn}
            theme={ThemeButton.PRIMARY}
            onClick={onClickMakePayment}
            loading={paymentLoading}
            size={SizeButton.M}
            disabled={!selectedPlan}
          >
            Make payment
          </Button>
        </div>
      </div>
    </>
  );
};
