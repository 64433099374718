import { useCallback } from 'react';

import { classNames } from 'utils/classNames';

import styles from './ProgressBar.module.scss';

const INTERVAL_DOTS_COUNT = 7;

interface ProgressBarProps {
  className?: string;
  progress: number;
  max: number;
}

const ProgressBar = ({ progress, max }: ProgressBarProps) => {
  const width = (100 * progress) / max;

  const getRangeDots = useCallback(() => {
    let position = 0;
    const rangeValue = 100 / INTERVAL_DOTS_COUNT;
    const dots: JSX.Element[] = [];
    for (let i = 0; i < INTERVAL_DOTS_COUNT - 1; i++) {
      position += rangeValue;
      dots.push(
        <div
          className={classNames(styles.intervalDot, { [styles.passed]: position < width })}
          style={{ left: `${position}%` }}
        />,
      );
    }

    return dots;
  }, [width]);

  return (
    <div className={styles.container}>
      <div className={styles.bar} style={{ width: `${width}%` }} />
      <div className={styles.labels}>
        <p>0 CREDITS</p>
        <p>{max} CREDITS</p>
      </div>
      <div className={styles.dot} style={{ left: `${width}%` }} />
      {getRangeDots()}
    </div>
  );
};

export default ProgressBar;
