import { Box } from '@mui/material';

import { MediaStatusEnum } from 'models/media';

import { MediaStatusTitleEnum } from '../constants';

import useStyles from './styles';

const Tag = ({ name }: { name: MediaStatusEnum }) => {
  const styles = useStyles();

  return (
    // @ts-ignore
    <Box sx={{ ...styles.tag, ...(styles[name.toLowerCase()] ?? {}) }}>{MediaStatusTitleEnum[name]}</Box>
  );
};

export default Tag;
