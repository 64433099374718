import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    title: {
      color: '#00AFF0',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },

    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      margin: '23px 0 30px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '7px',
        margin: '17px 0 23px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '5px',
        margin: '10px 0 25px',
      },
    },

    button: (isActive: boolean) => ({
      borderRadius: '10px',
      background: isActive ? '#D7F1FB' : '#F4F4F4',
      padding: '9px 20px',
      color: '#242628',
      fontSize: '19px',
      textTransform: 'none',
      minWidth: 'unset',
      fontWeight: 400,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        borderRadius: '8px',
        fontSize: '14px',
        padding: '5px 15px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        borderRadius: '5px',
        fontSize: '10px',
        padding: '5px 8px',
      },
      '&:hover': {
        background: isActive ? '#D7F1FB' : '#F4F4F4',
      },
    }),

    gallery: {
      overflow: 'hidden',
      flexGrow: 1,
      margin: '0 -15px',
    },

    helperText: {
      marginBottom: '20px',
      textAlign: 'center',
      display: 'block',
      color: '#A3ADB1',
      fontSize: '14px',
      height: '18px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '14px',
        height: '14px',
        marginBottom: '10px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
        height: '14px',
        marginBottom: '10px',
      },
    },
  };
};

export default useStyles;
