import { useTheme } from '@mui/material';

import { ScreenSizes, ElectronSidebarSize } from '../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      height: '100vh',
      position: 'relative',
    },
    content: {
      position: 'relative',
      flexGrow: 1,
      marginLeft: ElectronSidebarSize.Big,
      [breakpoints.down(ScreenSizes.screenXl)]: {
        marginLeft: ElectronSidebarSize.Small,
      },
    },
  };
};

export default useStyles;
