import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { useElectronContext } from 'modules/electron/providers';
import { fanNotesService } from 'services/FanNotesService';

import { useWebviewContext } from '../../../../../../providers';
import Loader from '../../../../../../../Loader';

import Tag from './Tag';
import { TAGS } from './constants';
import useStyles from './styles';
import TextareaBlock from './TextareaBlock';
import useFanNotes from './hooks/useFanNotes';

const FanNotes = () => {
  const styles = useStyles();

  const noteStringRef = useRef('');

  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const [isLoading, setIsLoading] = useState(false);

  const [activeTag, setActiveTag] = useState('');

  const { updateNotes, getFilteredNotes, prepareSaveNotes } = useFanNotes();

  const loadNotes = useCallback(async (creatorId: string, chatterId: string) => {
    setIsLoading(true);
    try {
      const { notes } = await fanNotesService.getFanNotes(creatorId, chatterId);

      setActiveTag('');
      noteStringRef.current = notes;
    } catch (e) {}
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!activeCreator?.id || !activeChatterId) {
      return;
    }

    loadNotes(activeCreator.id, activeChatterId);
  }, [activeCreator?.id, activeChatterId, loadNotes]);

  const handleSave = useCallback(
    (value: string) => {
      const noteString = noteStringRef.current;

      if (!activeCreator?.id || !activeChatterId || noteString === value) {
        return;
      }

      const preparedValueStr = prepareSaveNotes(noteString, activeTag, value);

      noteStringRef.current = preparedValueStr;
      updateNotes(activeCreator?.id, activeChatterId, preparedValueStr);
    },
    [activeCreator?.id, activeChatterId, activeTag, updateNotes],
  );

  return (
    <Box>
      <Box sx={styles.title}>Search by:</Box>

      <Box sx={styles.tags}>
        {TAGS.map((tag) => (
          <Tag
            label={tag.label}
            color={tag.color}
            active={tag.label === activeTag}
            key={tag.label}
            onClick={() => {
              setActiveTag((prevState) => (tag.label === prevState ? '' : tag.label));
            }}
          />
        ))}
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography sx={styles.helperText}>
            {activeTag ? "While 'Search by' is active, you can't type" : null}
          </Typography>
          <Box sx={styles.textarea}>
            <Box sx={styles.content}>
              <TextareaBlock
                disabled={!!activeTag}
                notes={getFilteredNotes(noteStringRef.current, activeTag)}
                onUpdate={handleSave}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FanNotes;
