import React, { useState } from 'react';

import { IContentContext, IContentProviderProps } from './interfaces';
import useSession from './hooks/useSession';

const DefaultContext = {} as IContentContext;

export const ContentContext = React.createContext<IContentContext>(DefaultContext);

export const useDashboardContext = () => React.useContext(ContentContext);

export const DashboardProvider = ({ children }: IContentProviderProps) => {
  const { creatorSession, isProxy, isSessionActive, handleLoginError } = useSession();

  const [isWebviewActive, setIsWebviewActive] = useState(false);

  return (
    <ContentContext.Provider
      value={{ isProxy, isSessionActive, creatorSession, handleLoginError, isWebviewActive, setIsWebviewActive }}
    >
      {children}
    </ContentContext.Provider>
  );
};
