import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAIBalance, IAISubscriptions, IPaymentCustomer, ISubscriptions } from 'models/billing';
import { StateSchema, ThunkConfig } from 'store';
import { billingService } from 'services/BillingService';
import { ErrorData } from 'models/fields';

import { authActions } from './auth';

export interface ExtraThunkArgs {
  isSilently?: boolean;
}

export const createOrUpdatePaymentCustomer = createAsyncThunk<void, IPaymentCustomer, ThunkConfig<string>>(
  'billing/createOrUpdatePaymentCustomer',
  async (data: IPaymentCustomer, { getState, rejectWithValue, dispatch }) => {
    const authData = getState().auth.authData;
    try {
      const stripeCustomerId = authData?.stripeCustomerId;
      const modifiedAuthData = stripeCustomerId
        ? await billingService.updatePaymentCustomer(data)
        : await billingService.createPaymentCustomer(data);
      dispatch(authActions.updateAuthData(modifiedAuthData.member));
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export const loadCurrentBalance = createAsyncThunk<
  { value: number } & Partial<ExtraThunkArgs>,
  Partial<ExtraThunkArgs> | undefined,
  ThunkConfig<string>
>('billing/loadCurrentBalance', async (params = {}, { rejectWithValue }) => {
  try {
    const { isSilently } = params;
    const response = await billingService.getBalance();
    return { value: +response.balance, isSilently };
  } catch (ex) {
    return rejectWithValue('Error');
  }
});

export const loadSubscriptions = createAsyncThunk<ISubscriptions[], void, ThunkConfig<string>>(
  'billing/loadSubscriptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingService.getSubscriptions();

      return response.subscriptions;
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export const loadAISubscriptions = createAsyncThunk<IAISubscriptions, void, ThunkConfig<string>>(
  'billing/loadAISubscriptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingService.getAISubscriptions();

      return response.subscriptions;
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export const loadAIBalance = createAsyncThunk<
  IAIBalance & Partial<ExtraThunkArgs>,
  Partial<ExtraThunkArgs> | undefined,
  ThunkConfig<string>
>('billing/loadAIBalance', async (params = {}, { rejectWithValue }) => {
  try {
    const { isSilently } = params;
    const { balanceAI, balanceAITotal } = await billingService.getAIBalance();

    return { balanceAI: +balanceAI, balanceAITotal: +balanceAITotal, isSilently };
  } catch (ex) {
    return rejectWithValue('Error');
  }
});

export interface BillingSchema {
  amount?: string;
  subscriptions: {
    items: ISubscriptions[];
    loading: boolean;
  };
  balance: number;
  loading: boolean;
  ai: {
    balance: IAIBalance;
    loading: boolean;
    subscriptions: {
      loading: boolean;
      items: IAISubscriptions;
    };
  };
  error?: string | ErrorData[];
}

const initialState: BillingSchema = {
  amount: undefined,
  subscriptions: {
    items: [],
    loading: false,
  },
  balance: 0,
  loading: false,
  ai: {
    loading: false,
    balance: {
      balanceAI: 0,
      balanceAITotal: 0,
    },
    subscriptions: {
      items: {},
      loading: false,
    },
  },
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setAmount: (state: BillingSchema, action: PayloadAction<string | undefined>) => {
      state.amount = action.payload;
    },
    updateAuthErrors: (state: BillingSchema, action: PayloadAction<string | ErrorData[] | undefined>) => {
      state.error = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadCurrentBalance.pending, (state, action) => {
      !action.meta.arg?.isSilently && (state.loading = true);
    });
    builder.addCase(loadCurrentBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.balance = action.payload.value;
    });

    builder.addCase(loadSubscriptions.pending, (state) => {
      state.subscriptions.loading = true;
    });
    builder.addCase(loadSubscriptions.fulfilled, (state, action) => {
      state.subscriptions.items = action.payload;
      state.subscriptions.loading = false;
    });
    builder.addCase(loadSubscriptions.rejected, (state) => {
      state.subscriptions.loading = false;
    });

    builder.addCase(loadAIBalance.pending, (state, action) => {
      !action.meta.arg?.isSilently && (state.ai.loading = true);
    });
    builder.addCase(loadAIBalance.fulfilled, (state, action) => {
      state.ai.loading = false;
      state.ai.balance = action.payload;
    });

    builder.addCase(loadAISubscriptions.pending, (state) => {
      state.ai.subscriptions.loading = true;
    });
    builder.addCase(loadAISubscriptions.fulfilled, (state, action) => {
      state.ai.subscriptions.items = action.payload;
      state.ai.subscriptions.loading = false;
    });
    builder.addCase(loadAISubscriptions.rejected, (state) => {
      state.ai.subscriptions.loading = false;
    });
  },
});

export const getBillingAmount = (state: StateSchema) => state.billing.amount;
export const getBillingBalance = (state: StateSchema) => state.billing.balance;
export const getBillingLoading = (state: StateSchema) => state.billing.loading;
export const getBillingSubscriptions = (state: StateSchema) => state.billing.subscriptions;

export const getBillingAILoading = (state: StateSchema) => state.billing.ai.loading;
export const getBillingAIBalance = (state: StateSchema) => state.billing.ai.balance;
export const getBillingAISubscriptions = (state: StateSchema) => state.billing.ai.subscriptions;

export const { actions: billingActions } = billingSlice;
export const { reducer: billingReducer } = billingSlice;
