import React, { useCallback, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { ModalType } from 'constants/modal';
import { useAppDispatch, useAppSelector } from 'store';
import { modalActions } from 'store/reducers/modal';
import withModalParam from 'hoc/withModalParam';
import { IPaymentInvoice } from 'models/billing';

import styles from './Modal.module.scss';
import { ConnectCreator } from './ConnectCreator/ConnectCreator';
import { AddMembers } from './AddMembers/AddMembers';
import { AssignCreator } from './AssignCreator/AssignCreator';
import { MembersRole } from './MembersRole/MembersRole';
import { AddOrUpdateRole } from './AddOrUpdateRole/AddOrUpdateRole';
import { BillingHistory } from './BillingHistory/BillingHistory';
import { BillingPayment } from './BillingPayment/BillingPayment';
import { PaymentSuccess } from './PaymentSuccess/PaymentSuccess';
import { SuccessfullyUpdated } from './SuccessfullyUpdated/SuccessfullyUpdated';
import { AiCopilot } from './AiCopilot/AiCopilot';
import { SuccessfulPayment } from './SuccessfulPayment/SuccessfulPayment';
import { SuccessfulInfo } from './SuccessfulInfo/SuccessfulInfo';
import { EditProxy } from './EditProxy/EditProxy';
import { AvailableUpdate } from './AvailableUpdate/AvailableUpdate';
import { SessionExpired } from './SessionExpired/SessionExpired';

const ModalWindow: React.FC = () => {
  const { modalType, config } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const isModalOpened = useMemo(() => modalType !== ModalType.NONE, [modalType]);

  const onRequestClose = useCallback(
    (event?: object, reason?: string) => {
      if (reason === 'backdropClick') return;

      dispatch(modalActions.setModalType({ type: ModalType.NONE, config: undefined }));
    },
    [dispatch],
  );

  //useDisableBodyScroll(isModalOpened);

  const renderBody = () => {
    switch (modalType) {
      case ModalType.СONNECT_CREATOR: {
        return <ConnectCreator onClose={onRequestClose} />;
      }
      case ModalType.ADD_MEMBERS: {
        return <AddMembers onClose={onRequestClose} />;
      }
      case ModalType.ASSIGN_CREATOR: {
        return <AssignCreator onClose={onRequestClose} />;
      }
      case ModalType.MEMBERS_ROLE: {
        return <MembersRole onClose={onRequestClose} />;
      }
      case ModalType.ADD_ROLE:
      case ModalType.UPDATE_ROLE: {
        return <AddOrUpdateRole isEdit={modalType === ModalType.UPDATE_ROLE} onClose={onRequestClose} />;
      }
      case ModalType.BILLING_HISTORY: {
        return <BillingHistory onClose={onRequestClose} />;
      }
      case ModalType.BILLING_PAYMENT: {
        return <BillingPayment onClose={onRequestClose} />;
      }
      case ModalType.PAYMENT_SUCCESSFUL: {
        return <PaymentSuccess data={config?.data as IPaymentInvoice} onClose={onRequestClose} />;
      }
      case ModalType.SUCCESSFULLY_UPDATED: {
        return <SuccessfullyUpdated data={config?.data as { text: string }} onClose={onRequestClose} />;
      }
      case ModalType.AI_COPILOT: {
        return <AiCopilot onClose={onRequestClose} />;
      }
      case ModalType.BILLING_PAYMENT_SUCCESSFUL: {
        return <SuccessfulPayment data={config?.data as { title: string; text: string }} onClose={onRequestClose} />;
      }
      case ModalType.SUCCESSFUL_INFO: {
        return <SuccessfulInfo onClose={onRequestClose} />;
      }
      case ModalType.EDIT_PROXY: {
        return <EditProxy onClose={onRequestClose} />;
      }
      case ModalType.AVAILABLE_UPDATE: {
        return <AvailableUpdate onCancel={onRequestClose} />;
      }
      case ModalType.SESSION_EXPIRED: {
        return <SessionExpired config={config?.[ModalType.SESSION_EXPIRED]} onClose={onRequestClose} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Dialog
      open={isModalOpened}
      className={styles.modal}
      onClose={onRequestClose}
      PaperProps={{ className: styles.papper }}
      disableEscapeKeyDown
    >
      <DialogContent sx={{ p: 0 }} className={styles.body}>
        {renderBody()}
      </DialogContent>
    </Dialog>
  );
};

export default withModalParam(ModalWindow);
