import { useTheme } from '@mui/material';

import { ScreenSizes } from 'modules/electron/constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    main: {
      display: 'flex',
      gap: '16px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '13px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '9px',
      },
    },
    image: {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      background: '#F3F4F6',
      overflow: 'hidden',

      '& img': {
        width: '100%',
        objectFit: 'cover',
        minHeight: '100%',
      },

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: '48px',
        height: '48px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: '32px',
        height: '32px',
      },
    },

    title: {
      color: '#242628',
      fontSize: '22px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '16px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },

    email: {
      color: '#A6A6A6',
      fontSize: '19px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '14px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '10px',
      },
    },

    button: {
      width: '40px',
      height: '40px',
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',

        '& path': {
          fill: 'var(--gray-dark-color)',
          stroke: 'var(--gray-dark-color)',
        },
      },
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: '25px',
        height: '25px',
        padding: '4px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: '20px',
        height: '20px',
        padding: '4px',
      },
    },

    controls: {
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '12px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '8px',
      },
    },
  };
};

export default useStyles;
