import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { useElectronContext } from '../../../../../providers';
import Loader from '../../../../Loader';
import OnlineFans from '../OnlineFans';

import OnlineChatterList from './OnlineChatterList';
import Filter from './Filter';
import { IProps } from './interfaces';
import useStyles from './useStyles';

const Dashboard = ({ disableOnlyFansIndicator }: IProps) => {
  const styles = useStyles();
  const { isOnlineSubscribersLoading, onlineSubscribers } = useElectronContext();

  const [filterTotal, setFilterTotal] = useState<number | string>('');

  const filteredSubscribers = useMemo(
    () => onlineSubscribers.filter((subscriber) => +subscriber.totalSpent >= +filterTotal),
    [onlineSubscribers, filterTotal],
  );

  return (
    <Box sx={styles.root}>
      {disableOnlyFansIndicator ? null : <OnlineFans total={onlineSubscribers.length} />}
      <Filter onChange={setFilterTotal} />
      {isOnlineSubscribersLoading ? <Loader /> : <OnlineChatterList subscribers={filteredSubscribers} />}
    </Box>
  );
};

export default Dashboard;
