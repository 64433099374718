import { v4 } from 'uuid';
import { Box, IconButton, Typography } from '@mui/material';

import { Bell, BellOff, Refresh } from '../../../../../../icons';
import { useOFNotificationContext, NotificationStatusEnum } from '../../../../../../providers';
import Loader from '../../../../../Loader';

import MainAccountForm from './MainAccountForm/MainAccountForm';
import { IMainAccountProps } from './interfaces';
import useStyles from './useStyles';

const MainAccount = ({ details, isLoading, updateChatterInfo, isRefreshDisabled }: IMainAccountProps) => {
  const styles = useStyles();
  const { status, setTriggerFlag, isProcessing } = useOFNotificationContext();

  const handleBell = () => {
    setTriggerFlag(v4());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.main}>
          <Box sx={styles.image}>{details.avatar ? <img src={details.avatar} alt={details.name} /> : null}</Box>
          <div>
            <Typography sx={styles.title}>{details.name}</Typography>
            <Typography sx={styles.email}>@{details.username}</Typography>
          </div>
        </Box>
        <Box sx={styles.controls}>
          {status === NotificationStatusEnum.NONE ? null : (
            <IconButton onClick={handleBell} sx={styles.button} disabled={isProcessing}>
              {status === NotificationStatusEnum.ON ? <Bell /> : <BellOff />}
            </IconButton>
          )}

          <IconButton onClick={updateChatterInfo} sx={styles.button} disabled={isRefreshDisabled}>
            <Refresh />
          </IconButton>
        </Box>
      </Box>
      <MainAccountForm birthday={details.birthday} location={details.location} />
    </>
  );
};

export default MainAccount;
