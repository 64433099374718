import { memo, useState } from 'react';

import { ModalType } from 'constants/modal';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { useAppDispatch, useAppSelector } from 'store';
import { Button, ThemeButton } from 'components/Button/Button';
import { ReactComponent as MembersRoleIcon } from 'assets/images/members-role.svg';
import Select from 'components/fields/Select/Select';
import { IDropdownOption } from 'models/fields';

import { getRolesList } from '../../../store/reducers/roles';
import { getModalLoading, modalActions } from '../../../store/reducers/modal';
import { getMembersState, membersActions, updateMemberRole } from '../../../store/reducers/members';

import styles from './MembersRole.module.scss';

interface MembersRoleProps {
  onClose?: () => void;
}

export const MembersRole = memo(({ onClose }: MembersRoleProps) => {
  const dispatch = useAppDispatch();

  const { items: roles } = useAppSelector(getRolesList);
  const { memberActiveModal } = useAppSelector(getMembersState);
  const loading = useAppSelector(getModalLoading);

  const [selectedRole, setSelectedRole] = useState<IDropdownOption | null>(null);

  const handleClose = () => {
    dispatch(membersActions.setMemberActiveModal(null));
    onClose?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CloseIcon className={styles.close} onClick={handleClose} />
      </div>

      <div className={styles.body}>
        <div className={styles.icon}>
          <MembersRoleIcon />
        </div>
        <h2 className={styles.title}>Members role</h2>
        <p className={styles.description}>
          Make sure you link to a Creator's account. Once done, we'll establish a new, distinct session for it.
        </p>
        <div className={styles.actions}>
          <Select
            placeholder="Select role..."
            className={styles.select}
            value={selectedRole}
            options={roles
              .filter((role) => role.id !== memberActiveModal?.roleId)
              .map((role) => ({ value: role.id, label: role.name }))}
            onChange={(value) => {
              setSelectedRole(value as IDropdownOption);
            }}
          />
          <Button
            theme={ThemeButton.PRIMARY}
            className={styles.updateBtn}
            onClick={async () => {
              if (!selectedRole?.value || !memberActiveModal) {
                return;
              }

              await dispatch(
                updateMemberRole({
                  roleId: selectedRole?.value,
                  memberId: memberActiveModal.id,
                }),
              );

              dispatch(modalActions.setModalType({ type: ModalType.NONE }));
            }}
            disabled={!selectedRole || loading}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
});
