import { memo, useCallback, useEffect, useState } from 'react';
import { yupToFormErrors } from 'formik';

import { RolePermissionsType, RoleSelectorType } from 'constants/roles';
import { ReactComponent as RolesIcon } from 'assets/images/role-permissions.svg';
import { Button, ThemeButton } from 'components/Button/Button';
import { IValidateFlags } from 'models/validation';
import { IRoles, RolePermissionType } from 'models/roles';
import { rolesSchema } from 'utils/validators';
import { useAppSelector } from 'store';
import { getRolePermissions, getRolesErrors } from 'store/reducers/roles';
import { getModalLoading } from 'store/reducers/modal';
import { classNames } from 'utils/classNames';
import { getApiErrorMessageByField } from 'constants/messages';

import styles from './RolePermissionsStep.module.scss';

type RolePermissionsDataType = Pick<IRoles, 'permissions'>;

interface RolePermissionsStepProps {
  data: RolePermissionsDataType;
  isEdit?: boolean;
  onChangeData: (value: string[], targetName?: string) => void;
  onSubmit: () => void;
}

export const RolePermissionsStep = memo(({ data, isEdit, onChangeData, onSubmit }: RolePermissionsStepProps) => {
  const [selectorType, setSelectorType] = useState<RoleSelectorType>();
  const loading = useAppSelector(getModalLoading);
  const [validateErrors, setValidateErrors] = useState<IValidateFlags<RolePermissionsDataType>>({});
  const { ofPermissions, permissionsMap, platformPermissions } = useAppSelector(getRolePermissions);
  const apiErrors = useAppSelector(getRolesErrors);
  console.log('apiErrorsw', apiErrors);

  useEffect(() => {
    setValidateErrors({});
  }, [data]);

  const onClickCreateRole = useCallback(async () => {
    try {
      await rolesSchema.rolePermissionsStep.validate({ ...data }, { abortEarly: false });
      onSubmit();
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
  }, [data, onSubmit]);

  const onClickPermissionItem = useCallback(
    (item: string) => {
      if (loading) return;
      console.log('item', item);
      const permissions = [...data.permissions];
      const selectedIndex = permissions.findIndex((p) => p === item);
      // Toggle implementation
      selectedIndex !== -1 ? permissions.splice(selectedIndex, 1) : permissions.push(item);
      onChangeData?.([...permissions], 'permissions');
    },
    [data, loading, onChangeData],
  );

  const onClickSelectorBtn = useCallback(
    (type: RoleSelectorType, name: string) => {
      if (selectorType === type) {
        onChangeData?.([], 'permissions');
        setSelectorType(undefined);
      } else {
        setSelectorType(type);
        const ofPermissionsValues = [...Object.values(ofPermissions!)];
        const platformPermissionsValues = [...Object.values(platformPermissions!)];
        switch (name) {
          case RoleSelectorType.SELECT_ALL: {
            onChangeData?.([...ofPermissionsValues, ...platformPermissionsValues], 'permissions');
            break;
          }
          case RoleSelectorType.PLATFORM_PERMISSION: {
            onChangeData?.([...platformPermissionsValues], 'permissions');
            break;
          }
          case RoleSelectorType.OF_FULL_ACCESS: {
            onChangeData?.([...ofPermissionsValues], 'permissions');
            break;
          }
        }
      }
    },
    [ofPermissions, platformPermissions, selectorType, onChangeData],
  );

  const renderSelectorControls = useCallback(() => {
    return Object.entries(RoleSelectorType).map(([type, name], index) => (
      <div
        key={index}
        className={classNames(styles.selectorBtn, {
          [styles.selected]: type === selectorType,
        })}
        onClick={() => onClickSelectorBtn(type as RoleSelectorType, name)}
      >
        {name}
      </div>
    ));
  }, [selectorType, onClickSelectorBtn]);

  const getPermissionList = useCallback(
    (permissions: RolePermissionType = {}, columnName?: string) => (
      <div className={styles.column}>
        <p className={styles.columnName}>{columnName}:</p>
        {Object.values(permissions).map((key, index) => (
          <div key={index} className={styles.permissionItem} onClick={() => onClickPermissionItem(key)}>
            <input
              id="permission"
              type="checkbox"
              onClick={(e) => e.stopPropagation()}
              checked={data?.permissions?.includes(key)}
            />
            <label htmlFor="permission">{permissionsMap?.[key]}</label>
          </div>
        ))}
      </div>
    ),
    [data, permissionsMap, onClickPermissionItem],
  );

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <RolesIcon />
      </div>
      <h2 className={styles.title}>Role Permissions</h2>
      <div className={styles.selectorControls}>{renderSelectorControls()}</div>
      <div className={styles.permissions}>
        {getPermissionList(ofPermissions, RolePermissionsType.OF_PERMISSION)}
        {getPermissionList(platformPermissions, RolePermissionsType.PLATFORM_PERMISSION)}
      </div>

      {validateErrors.permissions && (
        <div className={styles.errorText}>
          {validateErrors.permissions || getApiErrorMessageByField('permissions', apiErrors)}
        </div>
      )}
      <div className={styles.submitBtn}>
        <Button theme={ThemeButton.PRIMARY} onClick={onClickCreateRole} loading={loading}>
          {isEdit ? 'Update Role' : 'Create Role'}
        </Button>
      </div>
    </div>
  );
});
