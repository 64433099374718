import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICreators, ICreatorsDictionary, ICreatorsList } from 'models/creators';
import { creatorsService } from 'services/CreatorsService';
import { StateSchema, ThunkConfig } from 'store';

import { IMemberCreatorBody } from '../../models/members';

import { modalActions } from './modal';

export const loadCreatorsDictionary = createAsyncThunk<ICreatorsDictionary, void, ThunkConfig<string>>(
  'creators/loadCreatorsInfo',
  async (_, { rejectWithValue }) => {
    try {
      const [info, proxyCountry] = await Promise.all([
        creatorsService.getCreatorsDictionaries(),
        creatorsService.getCreatorsProxy(),
      ]);

      return {
        ...info.dictionaries?.value,
        proxyCountry: [...proxyCountry.countries],
      };
    } catch (e) {
      return rejectWithValue('Cannot load auth data');
    }
  },
);

export const loadCreatorsList = createAsyncThunk<ICreators[], void, ThunkConfig<string>>(
  'creators/loadCreatorsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await creatorsService.getCreators();

      return response.creators;
    } catch {
      return rejectWithValue('Error');
    }
  },
);

export const createOrUpdateCreators = createAsyncThunk<void, ICreators, ThunkConfig<string>>(
  'creators/createOrUpdateCreators',
  async (data: ICreators, { rejectWithValue, dispatch }) => {
    try {
      const creatorsId = data?.id;
      if (creatorsId) {
        //const modifiedItem = (await updateBenchAPI(benchId, data)).bench;
        //api.dispatch(updateItemInBenchList(modifiedItem));
      } else {
        const createdItem = await creatorsService.createCreators(data);
        dispatch(creatorsActions.addItemToCreatorsList(createdItem.creator));
      }
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export const loadCreatorById = createAsyncThunk<void, { id: string }, ThunkConfig<string>>(
  'creators/loadCreatorById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const creatorsId = data?.id;

      if (creatorsId) {
        const creator = await creatorsService.getCreatorById(creatorsId);
        dispatch(creatorsActions.setSelectedCreator(creator.creator));
      }
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export const updateCreatorData = createAsyncThunk<void, { id: string; data: IMemberCreatorBody }, ThunkConfig<string>>(
  'creators/updateCreatorData',
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(modalActions.setModalLoading(true));
      await creatorsService.updateCreatorData(id, data);
      dispatch(loadCreatorById({ id }));
    } catch {
      return rejectWithValue('Error');
    }
    dispatch(modalActions.setModalLoading(false));
  },
);

export interface CreatorsSchema {
  dictionary: ICreatorsDictionary;
  list: ICreatorsList;
  data: ICreators | undefined;
  selectedCreator: ICreators | undefined;
  loading: boolean;
  inited: boolean;
}

const initialState: CreatorsSchema = {
  dictionary: {},
  list: {
    items: [],
    cursor: undefined,
  },
  data: undefined,
  selectedCreator: undefined,
  loading: false,
  inited: false,
};

export const creatorsSlice = createSlice({
  name: 'creators',
  initialState,
  reducers: {
    addItemToCreatorsList: (state: CreatorsSchema, action: PayloadAction<ICreators>) => {
      state.list.items.push({ ...action.payload });
    },
    updateItemInBenchList: (state: CreatorsSchema, action: PayloadAction<ICreators>) => {
      const itemIndex = state.list.items.findIndex((x) => x.id === action.payload.id);

      ~itemIndex && (state.list.items[itemIndex] = action.payload);
    },
    setSelectedCreator: (state: CreatorsSchema, action: PayloadAction<ICreators | undefined>) => {
      state.selectedCreator = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadCreatorsDictionary.fulfilled, (state, action) => {
      state.dictionary = action.payload;
    });
    builder.addCase(loadCreatorsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadCreatorsList.fulfilled, (state, action) => {
      state.loading = false;
      state.list.items = action.payload;

      !state.inited && (state.inited = true);
    });
    builder.addCase(loadCreatorsList.rejected, (state) => {
      state.loading = false;
      !state.inited && (state.inited = true);
    });
  },
});

export const getCreatorsDictionary = (state: StateSchema) => state.creators.dictionary;
export const getCreatorData = (state: StateSchema) => state.creators.data;
export const getCreatorsList = (state: StateSchema) => state.creators.list;
export const getCreatorsLoading = (state: StateSchema) => state.creators.loading;
export const getCreatorsInited = (state: StateSchema) => state.creators.inited;
export const getCreator = (state: StateSchema) => state.creators.selectedCreator;

export const { actions: creatorsActions } = creatorsSlice;
export const { reducer: creatorsReducer } = creatorsSlice;
