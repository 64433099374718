import React, { useCallback, useEffect } from 'react';

import useIpcMessages from './useIpcMessages';

interface IWebView {
  ref: React.MutableRefObject<any | null>;
}

const useWebView = ({ ref }: IWebView) => {
  useIpcMessages({ ref });

  const handleLocalStorage = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    const localStorageData = await ref.current.executeJavaScript(`JSON.stringify(window.localStorage);`);

    return JSON.parse(localStorageData);
  }, []);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'get-local-storage';

    const cb = (_: IpcRendererEvent, data: string[]) => {
      setTimeout(async () => {
        const storage = await handleLocalStorage();
        const payload = storage
          ? data.reduce(
              (acc, key: string) => ({
                ...acc,
                // @ts-ignore
                [key]: storage[key] ?? '',
              }),
              {},
            )
          : null;

        window.electronAPI.send(`${eventName}-response`, {
          payload,
        });
      }, 3000);
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, []);
};

export default useWebView;
