import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ITab } from '../interfaces';

import useStyles from './useStyles';

interface IDashboardTab {
  tab: ITab;
  isActive: boolean;
  onDeleteTab: (tabId: string) => void;
  onChange: (tab: ITab) => void;
}

const DashboardTab = ({ isActive, tab, onDeleteTab, onChange }: IDashboardTab) => {
  const styles = useStyles();

  return (
    <Button
      sx={styles.tab(isActive)}
      onClick={() => {
        onChange(tab);
      }}
    >
      <Typography sx={styles.label}>{tab.title}</Typography>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          onDeleteTab(tab.id);
        }}
        sx={styles.action}
      >
        <CloseIcon />
      </Box>
    </Button>
  );
};

export default DashboardTab;
