const Bell = () => {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8843 1.08353C7.06789 1.08353 3.97409 4.17733 3.97409 7.99372V9.97518C3.97409 11.467 2.97157 12.7775 2.34567 14.1316C2.12363 14.612 1.99976 15.1471 1.99976 15.711C1.99976 17.5307 3.29984 19.0919 5.10228 19.3419C6.8922 19.5901 9.12879 19.8397 10.8843 19.8397C12.6398 19.8397 14.8764 19.5901 16.6663 19.3419C18.4687 19.0919 19.7688 17.5307 19.7688 15.711C19.7688 15.1471 19.6449 14.612 19.4229 14.1316C18.797 12.7775 17.7945 11.467 17.7945 9.97518V7.99371C17.7945 4.17732 14.7007 1.08353 10.8843 1.08353Z"
        fill="#00AFF0"
      />
      <path
        d="M5.10228 19.3419L4.96261 20.3491H4.96261L5.10228 19.3419ZM16.6663 19.3419L16.8059 20.3491L16.6663 19.3419ZM4.99093 7.99372C4.99093 4.73891 7.62947 2.10036 10.8843 2.10036V0.0666913C6.50631 0.0666913 2.95726 3.61574 2.95726 7.99372H4.99093ZM4.99093 9.97518V7.99372H2.95726V9.97518H4.99093ZM3.01659 15.711C3.01659 15.2974 3.1072 14.9076 3.26868 14.5583L1.42265 13.705C1.14007 14.3164 0.982921 14.9968 0.982921 15.711H3.01659ZM5.24194 18.3347C3.96564 18.1577 3.01659 17.047 3.01659 15.711H0.982921C0.982921 18.0144 2.63403 20.0262 4.96261 20.3491L5.24194 18.3347ZM10.8843 18.8229C9.2025 18.8229 7.02361 18.5817 5.24194 18.3347L4.96261 20.3491C6.7608 20.5984 9.05509 20.8565 10.8843 20.8565V18.8229ZM16.5266 18.3347C14.745 18.5817 12.5661 18.8229 10.8843 18.8229V20.8565C12.7135 20.8565 15.0078 20.5984 16.8059 20.3491L16.5266 18.3347ZM18.752 15.711C18.752 17.047 17.8029 18.1577 16.5266 18.3347L16.8059 20.3491C19.1345 20.0262 20.7856 18.0144 20.7856 15.711H18.752ZM18.4999 14.5583C18.6614 14.9076 18.752 15.2974 18.752 15.711H20.7856C20.7856 14.9968 20.6285 14.3164 20.3459 13.705L18.4999 14.5583ZM16.7776 7.99371V9.97518H18.8113V7.99371H16.7776ZM10.8843 2.10036C14.1391 2.10036 16.7776 4.7389 16.7776 7.99371H18.8113C18.8113 3.61574 15.2623 0.0666913 10.8843 0.0666913V2.10036ZM20.3459 13.705C20.1743 13.3337 19.9798 12.9717 19.7988 12.637C19.613 12.2934 19.442 11.9795 19.2902 11.6641C18.9859 11.0316 18.8113 10.495 18.8113 9.97518H16.7776C16.7776 10.9472 17.1043 11.8117 17.4577 12.546C17.6348 12.9139 17.8317 13.2748 18.0099 13.6043C18.193 13.9429 18.3586 14.2525 18.4999 14.5583L20.3459 13.705ZM2.95726 9.97518C2.95726 10.495 2.7827 11.0316 2.47834 11.6641C2.32655 11.9795 2.15551 12.2934 1.96971 12.637C1.78877 12.9717 1.59429 13.3337 1.42265 13.705L3.26868 14.5583C3.41 14.2525 3.57554 13.9429 3.75861 13.6043C3.93682 13.2748 4.13377 12.9139 4.31085 12.546C4.66423 11.8117 4.99093 10.9472 4.99093 9.97518H2.95726Z"
        fill="#00AFF0"
      />
      <path
        d="M13.353 22.4649C12.8225 23.2628 11.9152 23.7888 10.8851 23.7888C9.85501 23.7888 8.94775 23.2628 8.41719 22.4649"
        stroke="#00AFF0"
        strokeWidth="2.03367"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Bell;
