import { AxiosError } from 'axios';

import { ErrorData } from 'models/fields';

export enum ApiErrorStatusCode {
  EXPIRED_ACCESS_TOKEN = 401,
  ERROR_PERMISSIONS_ACCESS = 403,
}

export enum ApiErrorCode {
  // AGENCY
  AGENCY_LINK_CREATION_ERROR = 'AGENCY_LINK_CREATION_ERROR',
  MEMBER_DOES_NOT_HAVE_AGENCY_ERROR = 'MEMBER_DOES_NOT_HAVE_AGENCY_ERROR',
  MEMBER_HAS_AGENCY_ERROR = 'MEMBER_HAS_AGENCY_ERROR',
  NO_ACCESS_ERROR = 'NO_ACCESS_ERROR',

  // AUTH
  INVALID_REFRESH_TOKEN_ERROR = 'INVALID_REFRESH_TOKEN_ERROR',
  INVALID_ACCESS_TOKEN_ERROR = 'INVALID_ACCESS_TOKEN_ERROR',
  ROLE_PERMISSION_ERROR = 'ROLE_PERMISSION_ERROR',
  EMAIL_ALREADY_USED_ERROR = 'EMAIL_ALREADY_USED_ERROR',

  // CREATORS
  GITHUB_OF_INFO_UNAVAILABLE_ERROR = 'GITHUB_OF_INFO_UNAVAILABLE_ERROR',
  CREATOR_OF_LINK_HAS_ALREADY_TAKEN_ERROR = 'CREATOR_OF_LINK_HAS_ALREADY_TAKEN_ERROR',
  CREATOR_NOT_FOUND_ERROR = 'CREATOR_NOT_FOUND_ERROR',
  CREATOR_SESSION_NOT_ACTIVE_ERROR = 'CREATOR_SESSION_NOT_ACTIVE_ERROR',
  CREATOR_UPDATE_ERROR = 'CREATOR_UPDATE_ERROR',
  CREATOR_SESSION_ID_MISMATCH_ERROR = 'CREATOR_SESSION_ID_MISMATCH_ERROR',
  OF_AUTH_ERROR = 'OF_AUTH_ERROR',
  UNVERIFIED_CREATORS_LIMIT_ERROR = 'UNVERIFIED_CREATORS_LIMIT_ERROR',

  // DYNAMO
  UNSUPPORTED_TRANSFORMED_RECORD_TYPE_ERROR = 'UNSUPPORTED_TRANSFORMED_RECORD_TYPE_ERROR',
  UNSUPPORTED_GET_PK_RECORD_TYPE_ERROR = 'UNSUPPORTED_GET_PK_RECORD_TYPE_ERROR',

  // EMAIL
  TOO_LONG_STRING = 'TOO_LONG_STRING',
  INVALID_EMAIL = 'INVALID_EMAIL',

  // MEMBERS
  MEMBER_EMAIL_ALREADY_ADDED_ERROR = 'MEMBER_EMAIL_ALREADY_ADDED_ERROR',
  MEMBER_ALREADY_HAS_STRIPE_ACCOUNT_ERROR = 'MEMBER_ALREADY_HAS_STRIPE_ACCOUNT_ERROR',
  MEMBER_NOT_FOUND_ERROR = 'MEMBER_NOT_FOUND_ERROR',
  MEMBER_TOKEN_NOT_VALID_ERROR = 'MEMBER_TOKEN_NOT_VALID_ERROR',
  MEMBER_LINK_CONFLICT_ERROR = 'MEMBER_LINK_CONFLICT_ERROR',
  MEMBER_CREATOR_CONFLICT_ERROR = 'MEMBER_CREATOR_CONFLICT_ERROR',
  MEMBER_USER_POOL_DELETION_ERROR = 'MEMBER_USER_POOL_DELETION_ERROR',
  MEMBER_CREATOR_DELETE_ERROR = 'MEMBER_CREATOR_DELETE_ERROR',
  MEMBER_UPDATE_CREDENTIALS_ERROR = 'MEMBER_UPDATE_CREDENTIALS_ERROR',

  // PAYMENT
  STRIPE_CUSTOMER_NOT_FOUND_ERROR = 'STRIPE_CUSTOMER_NOT_FOUND_ERROR',
  PAYMENT_NOT_FOUND_ERROR = 'PAYMENT_NOT_FOUND_ERROR',
  INSUFFICIENT_BALANCE_ERROR = 'INSUFFICIENT_BALANCE_ERROR',
  INSUFFICIENT_BALANCE_HISTORY_ERROR = 'INSUFFICIENT_BALANCE_HISTORY_ERROR',
  INACTIVE_CREATOR_SESSION_ERROR = 'INACTIVE_CREATOR_SESSION_ERROR',
  INVALID_POSTAL_CODE_ERROR = 'INVALID_POSTAL_CODE_ERROR',

  // PROXY
  PROXY_COUNTRY_NOT_AVAILABLE_ERROR = 'PROXY_COUNTRY_NOT_AVAILABLE_ERROR',
  PROXY_NOT_AVAILABLE = 'PROXY_NOT_AVAILABLE',
  PROXY_TYPE_NOT_PROVIDED_AVAILABLE = 'PROXY_TYPE_NOT_PROVIDED_AVAILABLE',
  PROXY_HAS_NOT_ATTACHED = 'PROXY_HAS_NOT_ATTACHED',

  // ROLES
  ROLE_NAME_IS_ALREADY_TAKEN_ERROR = 'ROLE_NAME_IS_ALREADY_TAKEN_ERROR',
  ROLE_DOES_NOT_EXIST_ERROR = 'ROLE_DOES_NOT_EXIST_ERROR',
  ROLE_IS_NOT_UPDATED_ERROR = 'ROLE_IS_NOT_UPDATED_ERROR',
  ROLE_HAS_NOT_MEET_PERMISSIONS_ERROR = 'ROLE_HAS_NOT_MEET_PERMISSIONS_ERROR',

  // SUBSCRIPTION
  SUBSCRIPTION_IS_STILL_ACTIVE_ERROR = 'SUBSCRIPTION_IS_STILL_ACTIVE_ERROR',
  INVALID_TOKEN_ERROR = 'INVALID_TOKEN_ERROR',
  INVALID_IDENTIFICATION_PLAN = 'INVALID_IDENTIFICATION_PLAN',

  // UPLOAD
  PRE_SIGNED_BUCKET_NOT_DEFINED_ERROR = 'PRE_SIGNED_BUCKET_NOT_DEFINED_ERROR',

  // BASE
  NOT_AN_INTEGER = 'NOT_AN_INTEGER',
  NOT_A_POSITIVE = 'NOT_A_POSITIVE',
  NOT_A_BOOLEAN = 'NOT_A_BOOLEAN',
  NOT_A_URL = 'NOT_A_URL',
  NOT_A_DATE = 'NOT_A_DATE',
  DATE_MUST_BE_LATER = 'DATE_MUST_BE_LATER',
  DATE_MUST_BE_EARLIER = 'DATE_MUST_BE_EARLIER',
  NOT_INT_32 = 'NOT_INT_32',
  STRING_LENGTH_IS_TOO_SHORT = 'STRING_LENGTH_IS_TOO_SHORT',
  STRING_LENGTH_IS_TOO_LONG = 'STRING_LENGTH_IS_TOO_LONG',
  MIN_VALUE_ERROR = 'MIN_VALUE_ERROR',
  INVALID_SCALE = 'INVALID_SCALE',
  MAX_VALUE_ERROR = 'MAX_VALUE_ERROR',
  INVALID_ENUM_VALUE = 'INVALID_ENUM_VALUE',
  ARRAY_EMPTY = 'ARRAY_EMPTY',
  ARRAY_INVALID_SIZE = 'ARRAY_INVALID_SIZE',
  ARRAY_MUST_BE_UNIQUE = 'ARRAY_MUST_BE_UNIQUE',
  FIELD_MUST_BE_AN_ARRAY = 'FIELD_MUST_BE_AN_ARRAY',
  INVALID_LIMIT_VALUE = 'INVALID_LIMIT_VALUE',

  //STATISTICS
  FORCE_MEDIA_UPDATE_ALREADY_REQUESTED_ERROR = 'FORCE_MEDIA_UPDATE_ALREADY_REQUESTED_ERROR',

  //AI
  AI_SERVER_ERROR = 'AI_SERVER_ERROR',
  AI_INVALID_OF_CHATTER_ID_ERROR = 'AI_INVALID_OF_CHATTER_ID_ERROR',
  AI_NOTE_EMPTY_ERROR = 'AI_NOTE_EMPTY_ERROR',
  AI_AMOUNT_EXCEED_ERROR = 'AI_AMOUNT_EXCEED_ERROR',
  AI_CHAT_HISTORY_IS_EMPTY_ERROR = 'AI_CHAT_HISTORY_IS_EMPTY_ERROR',

  // AXIOS
  ERR_NETWORK = 'ERR_NETWORK',
}

const apiErrorMessages: Record<ApiErrorCode, string> = {
  // AGENCY
  [ApiErrorCode.AGENCY_LINK_CREATION_ERROR]:
    "We couldn't link your account to the agency. Please try again later or contact support for assistance.",
  [ApiErrorCode.MEMBER_DOES_NOT_HAVE_AGENCY_ERROR]: 'Member didn’t link to any agency.',
  [ApiErrorCode.MEMBER_HAS_AGENCY_ERROR]: 'Member is already linked to an agency.',
  [ApiErrorCode.NO_ACCESS_ERROR]:
    "You don't have permission to access this feature. Please check your access rights or contact your administrator.",

  // AUTH
  [ApiErrorCode.INVALID_REFRESH_TOKEN_ERROR]:
    'Your session has expired or is invalid. Please sign in again to continue.',
  [ApiErrorCode.INVALID_ACCESS_TOKEN_ERROR]: 'Your access is no longer valid. Please re-login to renew your session.',
  [ApiErrorCode.ROLE_PERMISSION_ERROR]: 'Permissions have been changed, please re-login',
  [ApiErrorCode.EMAIL_ALREADY_USED_ERROR]:
    'An account with this email address already exists. Please use a different email address or log in to your existing account.',

  // CREATORS
  [ApiErrorCode.GITHUB_OF_INFO_UNAVAILABLE_ERROR]: 'Request Failed. Retry or contact support for help.',
  [ApiErrorCode.CREATOR_OF_LINK_HAS_ALREADY_TAKEN_ERROR]:
    'Sorry this model is connected to another agency. Please contact support email or telegram chat.',
  [ApiErrorCode.CREATOR_NOT_FOUND_ERROR]:
    'Creator not found. Please ensure you have the correct information and try again.',
  [ApiErrorCode.CREATOR_SESSION_NOT_ACTIVE_ERROR]: 'Your session is no longer active. Please log in again to proceed.',
  [ApiErrorCode.CREATOR_UPDATE_ERROR]:
    'We encountered an error updating your information. Please try again later or contact support if the issue persists.',
  [ApiErrorCode.CREATOR_SESSION_ID_MISMATCH_ERROR]:
    'You do not own this link. Please verify your information and try again',
  [ApiErrorCode.OF_AUTH_ERROR]:
    'Authentication failed. Please check your credentials and try again, or contact support for assistance.',
  [ApiErrorCode.UNVERIFIED_CREATORS_LIMIT_ERROR]: 'The number of unverified models has reached the limit.',

  // DYNAMO
  [ApiErrorCode.UNSUPPORTED_TRANSFORMED_RECORD_TYPE_ERROR]: 'Request Failed. Retry or contact support for help.',
  [ApiErrorCode.UNSUPPORTED_GET_PK_RECORD_TYPE_ERROR]:
    "We're having trouble processing your request right now. Please check back later or reach out to support for help.",

  // EMAIL
  [ApiErrorCode.TOO_LONG_STRING]: 'The text is too long.',
  [ApiErrorCode.INVALID_EMAIL]: 'The email address you entered is not valid.',

  // MEMBERS
  [ApiErrorCode.MEMBER_EMAIL_ALREADY_ADDED_ERROR]:
    'This email address has already been added. Please use a different email address.',
  [ApiErrorCode.MEMBER_ALREADY_HAS_STRIPE_ACCOUNT_ERROR]:
    'You already have a Stripe account linked. If you need to change it, please contact support.',
  [ApiErrorCode.MEMBER_NOT_FOUND_ERROR]: "We couldn't find your account. Please check your information and try again.",
  [ApiErrorCode.MEMBER_TOKEN_NOT_VALID_ERROR]: 'Request Failed. Retry or contact support for help.',
  [ApiErrorCode.MEMBER_LINK_CONFLICT_ERROR]:
    'The link you are trying to use is already in use. Please choose a different one.',
  [ApiErrorCode.MEMBER_CREATOR_CONFLICT_ERROR]:
    "There's a conflict with your creator account. Please contact support for assistance.",
  [ApiErrorCode.MEMBER_USER_POOL_DELETION_ERROR]:
    'There was an error removing your account. Please contact support for further assistance.',
  [ApiErrorCode.MEMBER_CREATOR_DELETE_ERROR]:
    'We encountered an issue deleting the creator profile. Please reach out to support for help.',
  [ApiErrorCode.MEMBER_UPDATE_CREDENTIALS_ERROR]:
    'There was a problem updating your credentials. Please check your information and try again, or contact support if the issue persists.',

  // PAYMENT
  [ApiErrorCode.STRIPE_CUSTOMER_NOT_FOUND_ERROR]:
    "We couldn't locate your payment information. Please ensure your account details are up to date.",
  [ApiErrorCode.PAYMENT_NOT_FOUND_ERROR]:
    "We couldn't find your payment. Please check your payment details and try again.",
  [ApiErrorCode.INSUFFICIENT_BALANCE_ERROR]:
    'Your balance is insufficient to complete this transaction. Please add funds and try again.',
  [ApiErrorCode.INSUFFICIENT_BALANCE_HISTORY_ERROR]:
    "There's an issue with your balance history, preventing the transaction. Please contact support for help.",
  [ApiErrorCode.INACTIVE_CREATOR_SESSION_ERROR]:
    'Your session has expired or is inactive. Please log in again to continue.',
  [ApiErrorCode.INVALID_POSTAL_CODE_ERROR]: 'Incorrect ZIP code. Please enter a valid code to continue',
  // PROXY
  [ApiErrorCode.PROXY_COUNTRY_NOT_AVAILABLE_ERROR]:
    'The proxy service for the selected country is not available. Please choose another country or try again later.',
  [ApiErrorCode.PROXY_NOT_AVAILABLE]:
    'The proxy service is currently unavailable. Please check your connection or try again later.',
  [ApiErrorCode.PROXY_TYPE_NOT_PROVIDED_AVAILABLE]:
    'A proxy type was not specified. Please select a proxy type and try again.',
  [ApiErrorCode.PROXY_HAS_NOT_ATTACHED]: 'Proxy has not been attached.',

  // ROLES
  [ApiErrorCode.ROLE_NAME_IS_ALREADY_TAKEN_ERROR]:
    'This role name is already in use. Please choose a different name and try again.',
  [ApiErrorCode.ROLE_DOES_NOT_EXIST_ERROR]:
    'The selected role does not exist. Please check your selection and try again.',
  [ApiErrorCode.ROLE_IS_NOT_UPDATED_ERROR]:
    'We were unable to update the role due to an error. Please check your entries and try again.',
  [ApiErrorCode.ROLE_HAS_NOT_MEET_PERMISSIONS_ERROR]: 'The role does not have the required permissions.',

  // SUBSCRIPTION
  [ApiErrorCode.SUBSCRIPTION_IS_STILL_ACTIVE_ERROR]:
    'Your subscription is still active. If you need to make changes or have questions, please contact support.',
  [ApiErrorCode.INVALID_TOKEN_ERROR]: 'Request Failed. Retry or contact support for help.',
  [ApiErrorCode.INVALID_IDENTIFICATION_PLAN]:
    'The selected plan is not valid. Please check your choice and try again, or contact support for more information.',

  // UPLOAD
  [ApiErrorCode.PRE_SIGNED_BUCKET_NOT_DEFINED_ERROR]: 'Request Failed. Retry or contact support for help.',

  // BASE
  [ApiErrorCode.NOT_AN_INTEGER]: 'Please enter a whole number without decimals.',
  [ApiErrorCode.NOT_A_POSITIVE]: 'Please enter a positive number.',
  [ApiErrorCode.NOT_A_BOOLEAN]: "Please enter either 'true' or 'false'.",
  [ApiErrorCode.NOT_A_URL]: 'Please enter a valid URL.',
  [ApiErrorCode.NOT_A_DATE]: 'Please enter a valid date in the format YYYY-MM-DD.',
  [ApiErrorCode.DATE_MUST_BE_LATER]: 'The date must be in the future.',
  [ApiErrorCode.DATE_MUST_BE_EARLIER]: 'The date must be in the past.',
  [ApiErrorCode.NOT_INT_32]: 'Please enter a non-decimal value.',
  [ApiErrorCode.STRING_LENGTH_IS_TOO_SHORT]: 'Your text is too short. Please add more characters.',
  [ApiErrorCode.STRING_LENGTH_IS_TOO_LONG]: 'Your text is too long. Please shorten it.',
  [ApiErrorCode.MIN_VALUE_ERROR]: 'The value is too low. Please increase it.',
  [ApiErrorCode.INVALID_SCALE]: 'The scale value is incorrect. Please adjust it.',
  [ApiErrorCode.MAX_VALUE_ERROR]: 'The value is too high. Please decrease it.',
  [ApiErrorCode.INVALID_ENUM_VALUE]: 'The selected option is not valid. Please choose from the available options.',
  [ApiErrorCode.ARRAY_EMPTY]: 'The list cannot be empty. Please add at least one item.',
  [ApiErrorCode.ARRAY_INVALID_SIZE]: 'The list size is not correct. Please adjust the number of items.',
  [ApiErrorCode.ARRAY_MUST_BE_UNIQUE]: 'All items in the list must be unique. Please remove duplicates.',
  [ApiErrorCode.FIELD_MUST_BE_AN_ARRAY]: 'This field must be a list of values.',
  [ApiErrorCode.INVALID_LIMIT_VALUE]: 'The limit value is invalid. Please enter a valid number.',

  [ApiErrorCode.FORCE_MEDIA_UPDATE_ALREADY_REQUESTED_ERROR]: 'Something went wrong with media update.',

  [ApiErrorCode.AI_SERVER_ERROR]: 'Something went wrong, please try again, if error persists contact support 💔',
  [ApiErrorCode.AI_INVALID_OF_CHATTER_ID_ERROR]:
    'Something went wrong, please try again, if error persists contact support 💔',
  [ApiErrorCode.AI_NOTE_EMPTY_ERROR]: 'Creator Information missing, contact support so we can help you!',
  [ApiErrorCode.AI_AMOUNT_EXCEED_ERROR]: 'You run out of AI Credits, buy them on your billing tab!',
  [ApiErrorCode.AI_CHAT_HISTORY_IS_EMPTY_ERROR]: 'AI_CHAT_HISTORY_IS_EMPTY_ERROR',

  [ApiErrorCode.ERR_NETWORK]:
    'Something goes wrong with proxy. Please try again or contact support if the issue continues',
};

export const SILENT_ERROR_CODES = [ApiErrorCode.FORCE_MEDIA_UPDATE_ALREADY_REQUESTED_ERROR];

export function getErrorMessage(errorCode: ApiErrorCode): string {
  return apiErrorMessages[errorCode] || 'Request Failed. Retry or contact support for help.';
}

export const getAxiosErrorMessage = (error: AxiosError<any, any>) => {
  const { data } = error.response ?? {};
  const errorCode: ApiErrorCode = data?.error[0]?.message;
  const errorMessage = getErrorMessage(errorCode);

  return errorMessage;
};

export const getApiErrorMessageByField = (fieldName: string, errors?: string | ErrorData[]) => {
  if (!errors) return '';

  if (typeof errors === 'string') {
    return errors;
  } else {
    const errorData = errors?.find(({ field }) => fieldName === field);

    return apiErrorMessages[errorData?.message as ApiErrorCode] || errorData?.message;
  }
};
