import React, { InputHTMLAttributes } from 'react';

import { classNames } from 'utils/classNames';
import { ThemeField } from 'models/fields';

import { INamedFieldProps, NamedField } from '../NamedField/NamedField';

import styles from './TextArea.module.scss';

type HTMLTextAreaProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'children'>;

interface ITextAreaProps extends HTMLTextAreaProps, INamedFieldProps {
  theme?: ThemeField;
  placeholder?: string;
  className?: string;
  value?: string;
  onChangeValue?: (value: string, targetName?: string) => void;
}

export const TextArea: React.FC<ITextAreaProps> = ({
  theme = ThemeField.DARK,
  fieldName,
  placeholder,
  value,
  error,
  onChangeValue,
  className = '',
  ...props
}) => (
  <NamedField
    fieldName={fieldName}
    error={error}
    className={classNames(styles.textarea, { [styles.error]: error }, [className, theme])}
  >
    <textarea
      {...props}
      placeholder={placeholder}
      className={styles.control}
      value={value}
      onChange={(e): void => onChangeValue?.(e.target.value, e.target.name)}
    />
  </NamedField>
);
