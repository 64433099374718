import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      color: '#00AFF0',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      display: 'flex',
      gap: '18px',
      alignItems: 'center',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
        gap: '14px',
        '& svg': {
          transform: 'scale(0.75)',
        },
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '13px',
        gap: '8px',
        '& svg': {
          transform: 'scale(0.6)',
        },
      },
      '& p': {
        margin: 0,
      },
    },
  };
};

export default useStyles;
