import { useCallback, useEffect, useRef, useState } from 'react';

import { ModalType } from 'constants/modal';
import { modalActions } from 'store/reducers/modal';

import { useAppSelector, useAppDispatch } from '../store';
import { getRolesList } from '../store/reducers/roles';
import { getUserAgency, getUserAuthData } from '../store/reducers/auth';
import { getMembersState } from '../store/reducers/members';

export enum PermissionStatus {
  Idle,
  Completed,
  Failed,
}

const usePermissions = () => {
  const dispatch = useAppDispatch();
  const { items: roles } = useAppSelector(getRolesList);
  const authUser = useAppSelector(getUserAuthData);
  const agency = useAppSelector(getUserAgency);
  const [userPermissions, setUserPermissions] = useState<string[] | null>(null);
  const [permissionStatus, setPermissionStatus] = useState(PermissionStatus.Idle);
  const [isSuperAdminPermission, setIsSuperAdminPermission] = useState<boolean>(false);
  const [superAdminId, setSuperAdminId] = useState<string>('');
  // Variable necessary when initializing the application, for correct redirection according to permissions (see AuthProvider logic)
  // [TODO]: rewrite the logic instead of useEffect to useMemo in order to process permissions immediately, and not after mounting (useEffect) the application
  const prevRoleUpdatedAt = useRef('');

  const { list: members } = useAppSelector(getMembersState);

  const openLogoutPopup = useCallback(() => {
    const config = {
      [ModalType.SESSION_EXPIRED]: {
        title: 'Permissions has been changed',
        description: 'The permissions of your account have been changed. Please log in to the system again.',
      },
    };
    prevRoleUpdatedAt.current = '';
    dispatch(modalActions.setModalType({ type: ModalType.SESSION_EXPIRED, config }));
  }, []);

  useEffect(() => {
    if (!authUser?.roleId || !agency?.ownerId) {
      setPermissionStatus(PermissionStatus.Failed);
      return;
    }

    const role = roles.find((role) => role.id === authUser.roleId);
    const isSuperAdmin = agency.ownerId === authUser.id;

    setSuperAdminId(agency.ownerId); // agency ownerID = adminId
    setIsSuperAdminPermission(isSuperAdmin);

    if (!role) {
      setPermissionStatus(PermissionStatus.Failed);
      setUserPermissions(null);
      return;
    }

    setPermissionStatus(PermissionStatus.Completed);
    setUserPermissions(role.permissions);
  }, [authUser, agency, roles]);

  useEffect(() => {
    if (!authUser?.id) {
      return;
    }

    const currentMember = members.items?.find((member) => member.id === authUser?.id);

    if (!currentMember) {
      return;
    }
    const newRole = roles.find((role) => role.id === authUser.roleId); // super admin not contains in roles list

    if (currentMember?.roleId === authUser.roleId) {
      if (newRole && prevRoleUpdatedAt.current && prevRoleUpdatedAt.current !== newRole?.updatedAt) {
        //Permissions for role have been changed
        openLogoutPopup();
        return;
      }
    } else {
      //Role has been changed
      openLogoutPopup();
      return;
    }

    prevRoleUpdatedAt.current = newRole?.updatedAt ?? '';
  }, [authUser, members, roles]);

  return {
    userPermissions,
    isSuperAdminPermission,
    superAdminId,
    permissionStatus,
  };
};

export default usePermissions;
