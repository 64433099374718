import { useEffect } from 'react';

import Statistics from 'components/Statistics/Statistics';
import { useAppDispatch } from 'store';
import { loadCreatorsList } from 'store/reducers/creators';

import { useChatterCharms } from '../../providers/ChatterCharmsProvider';

const StatisticsPage = () => {
  const { creators: creatorsList } = useChatterCharms();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadCreatorsList());
  }, [dispatch]);

  if (creatorsList.length > 0) {
    return <Statistics />;
  }

  return null;
};

export default StatisticsPage;
