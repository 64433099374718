import React, { useCallback, useMemo, useState } from 'react';
import { yupToFormErrors } from 'formik';
import { useSnackbar } from 'notistack';

import { Button, ThemeButton } from '../../Button/Button';
import Input from '../../fields/Input/Input';
import { IMember } from '../../../models/members';
import { ThemeField } from '../../../models/fields';
import { IValidateFlags } from '../../../models/validation';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getUserAuthData } from '../../../store/reducers/auth';
import { updateProfile } from '../../../store/reducers/members';
import { compareStrings } from '../helpers/common';
import { settingsFormSchema } from '../../../utils/validators';

import styles from './SettingsForm.module.scss';

type ValidateDataType = Pick<IMember, 'email' | 'name'>;

export const SettingsForm = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(getUserAuthData);

  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState(user?.name || '');

  const [loading, setLoading] = useState(false);

  const [validateErrors, setValidateErrors] = useState<IValidateFlags<ValidateDataType>>({});

  const isEqual = useMemo(
    () => !!(user?.email && compareStrings(email, user?.email)) && !!(user?.name && compareStrings(name, user?.name)),
    [email, name, user],
  );

  const onSubmit = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    setValidateErrors({});

    try {
      await settingsFormSchema.validate({ email, name }, { abortEarly: false });
      const response = await dispatch(
        updateProfile({
          memberId: user?.id,
          data: {
            email,
            name,
          },
        }),
      );

      if (response.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Successfully updated!', { variant: 'success' });
      }
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    } finally {
      setLoading(false);
    }
  }, [user, name, email, loading]);

  return (
    <form className={styles.form}>
      <Input
        name="email"
        fieldName="E-mail"
        placeholder="Ex: abc@example.com"
        theme={ThemeField.DARK}
        readonly={loading}
        value={email}
        onChange={setEmail}
        className={styles.input}
        error={validateErrors.email}
        disabled={loading}
      />
      <div>
        <Input
          name="name"
          placeholder="Ex: John Wick"
          fieldName="Name"
          theme={ThemeField.DARK}
          readonly={loading}
          value={name}
          onChange={setName}
          className={styles.input}
          error={validateErrors.name}
          disabled={loading}
        />
        <p className={styles.text}>
          This contact will only be used by our support team for communication and service improvement purposes.
        </p>
      </div>
      <Button
        loading={loading}
        disabled={isEqual}
        theme={ThemeButton.PRIMARY}
        className={styles.button}
        onClick={onSubmit}
      >
        Update
      </Button>
    </form>
  );
};
