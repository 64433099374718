import { type RouteProps } from 'react-router-dom';

import SignInPage from 'pages/sign-in';
import SignUpPage from 'pages/sign-up';
import MembersPage from 'pages/members';
import CreatorsPage from 'pages/creators';
import RolesPage from 'pages/roles';
import StatisticsPage from 'pages/statistics';
import BillingPage from 'pages/billing';
import SettingsPage from 'pages/settings';
import EditAccountPage from 'pages/edit';
import { NotFoundScreen } from 'components/screens';

import { PlatformPermissions, PermissionsType } from '../constants/roles';

export type AppRouteProps = RouteProps & {
  authOnly?: boolean;
  permissions?: (keyof PermissionsType)[];
};

export enum AppRoutes {
  ACCOUNTS = 'accounts',
  MEMBERS = 'members',
  ROLES = 'roles',
  STATISTICS = 'statistics',
  BILLING = 'billing',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SETTINGS = 'settings',
  EDIT_ACCOUNT = 'editAccount',
  // last item
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.SETTINGS]: '/settings',
  [AppRoutes.ACCOUNTS]: '/',
  [AppRoutes.MEMBERS]: '/members',
  [AppRoutes.ROLES]: '/roles',
  [AppRoutes.STATISTICS]: '/statistics',
  [AppRoutes.BILLING]: '/billing',
  [AppRoutes.SIGN_IN]: '/sign-in',
  [AppRoutes.SIGN_UP]: '/sign-up',
  [AppRoutes.EDIT_ACCOUNT]: '/edit-account',
  // other routes
  [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, AppRouteProps> = {
  [AppRoutes.ACCOUNTS]: {
    path: RoutePath.accounts,
    element: <CreatorsPage />,
    authOnly: true,
    permissions: [PlatformPermissions.ACCOUNTS],
  },
  [AppRoutes.MEMBERS]: {
    path: RoutePath.members,
    element: <MembersPage />,
    authOnly: true,
    permissions: [PlatformPermissions.MEMBERS],
  },
  [AppRoutes.ROLES]: {
    path: RoutePath.roles,
    element: <RolesPage />,
    authOnly: true,
    permissions: [PlatformPermissions.ROLES],
  },
  [AppRoutes.STATISTICS]: {
    path: RoutePath.statistics,
    element: <StatisticsPage />,
    authOnly: true,
    permissions: [PlatformPermissions.STATISTICS],
  },
  [AppRoutes.BILLING]: {
    path: RoutePath.billing,
    element: <BillingPage />,
    authOnly: true,
    permissions: [PlatformPermissions.BILLINGS],
  },
  [AppRoutes.SIGN_IN]: {
    path: RoutePath.signIn,
    element: <SignInPage />,
  },
  [AppRoutes.SIGN_UP]: {
    path: RoutePath.signUp,
    element: <SignUpPage />,
  },
  [AppRoutes.SETTINGS]: {
    path: RoutePath.settings,
    element: <SettingsPage />,
    authOnly: true,
  },
  [AppRoutes.EDIT_ACCOUNT]: {
    path: RoutePath.editAccount,
    element: <EditAccountPage />,
    authOnly: true,
    permissions: [PlatformPermissions.ACCOUNTS],
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundScreen />,
  },
};
