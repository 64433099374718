export enum RolesColumns {
  ROLE,
  DESCRIPTION,
  TEAM_MEMBERS,
  PLATFORM_PERMISSION,
  OF_PERMISSION,
  ACTION,
}

export enum AddOrUpdateStep {
  REQUEST = 'REQUEST',
  ROLE_PERMISSIONS = 'ROLE_PERMISSIONS',
}

export const DEFAULT_ROLE_LABEL_COLOR = '#9c27b0';

export enum RoleSelectorType {
  OF_FULL_ACCESS = 'OF Full Access',
  PLATFORM_PERMISSION = 'Platform Permission',
  SELECT_ALL = 'Select All',
}

export enum RolePermissionsType {
  OF_PERMISSION = 'OF Permission',
  PLATFORM_PERMISSION = 'Platform Permission',
}

/*

export const permissionsConfig: Record<RolePermissionsType, string[]> = {
  [RolePermissionsType.OF_PERMISSION]: Object.values(OFPermissions),
  [RolePermissionsType.PLATFORM_PERMISSION]: Object.values(PlatformPermissions),
};

*/

export enum OFPermissions {
  CARDS_BANK = 'CARDS_BANK',
  CHAT_HIDING_FAN_BLOCKING = 'CHAT_HIDING_FAN_BLOCKING',
  LOGOUT_BUTTON = 'LOGOUT_BUTTON',
  SETTINGS = 'SETTINGS',
  STATEMENTS = 'STATEMENTS',
  VAULT = 'VAULT',
  OF_STATISTICS = 'OF_STATISTICS',
}

export enum PlatformPermissions {
  ACCOUNTS = 'ACCOUNTS',
  BILLINGS = 'BILLINGS',
  MEMBERS = 'MEMBERS',
  ROLES = 'ROLES',
  STATISTICS = 'STATISTICS',
}

export type PermissionsType = typeof OFPermissions & typeof PlatformPermissions;
