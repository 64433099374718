import { memo, useState } from 'react';
import { isAxiosError } from 'axios';

import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { useAppDispatch, useAppSelector } from 'store';
import { creatorsActions, getCreator, getCreatorsDictionary } from 'store/reducers/creators';
import { creatorsService } from 'services/CreatorsService';
import { ICreators } from 'models/creators';
import { ErrorData } from 'models/fields';

import { ProxyStep } from '../ConnectCreator/ProxyStep/ProxyStep';

import styles from './EditProxy.module.scss';

interface EditProxyProps {
  onClose?: () => void;
}

export const EditProxy = memo(({ onClose }: EditProxyProps) => {
  const dispatch = useAppDispatch();
  const dictionary = useAppSelector(getCreatorsDictionary);
  const currentCreators = useAppSelector(getCreator);
  const [selectedProxy, setSelectedProxy] = useState(currentCreators?.proxyCountry);
  const [loading, setLoading] = useState(false);
  const isSameProxyCountry = currentCreators?.proxyCountry === selectedProxy;
  const [apiErrors, setApiErrors] = useState<ErrorData[]>([]);

  const onSubmitData = async () => {
    if (!currentCreators?.id || !selectedProxy) return;
    setLoading(true);
    try {
      const { creator } = await creatorsService.updateCreatorProxy(currentCreators?.id, selectedProxy);
      const { changeProxy, proxyCountry } = creator || {};
      dispatch(creatorsActions.setSelectedCreator({ ...currentCreators, changeProxy, proxyCountry } as ICreators));
    } catch (error) {
      if (isAxiosError(error)) {
        const { data } = error.response ?? {};
        data?.errors && setApiErrors(data?.errors);
      }
    }
    setLoading(false);
    onClose?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>

      <div className={styles.body}>
        <ProxyStep
          data={{ proxyCountry: selectedProxy }}
          dictionary={dictionary}
          onChangeData={setSelectedProxy}
          onNextStep={onSubmitData}
          isEdit={true}
          disabled={isSameProxyCountry}
          loading={loading}
          apiErrors={apiErrors}
        />
      </div>
    </div>
  );
});
