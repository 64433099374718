import React from 'react';

import { classNames } from '../../../../utils/classNames';

import styles from './Status.module.scss';

enum ProxyStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

interface Props {
  isActive: boolean;
}

export const Status = ({ isActive }: Props) => (
  <span className={classNames(styles.box, { [styles.activeBox]: isActive })}>
    {isActive ? ProxyStatus.ACTIVE : ProxyStatus.INACTIVE}
  </span>
);
