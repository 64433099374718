import { CreatorsSessionStatus } from 'constants/creators';

import { ICreators, SubscriptionStatus } from './creators';

export interface IPaymentAccountInfo {
  email?: string;
  phone?: string;
  name?: string;
  country?: string;
  province?: string;
  isBusiness?: boolean;
  postalCode?: string;
}

export interface IPaymentBusinessPurchase {
  vatType?: string;
  city?: string;
  businessName?: string;
  businessId?: string;
  fullAddress?: string;
  postalCode?: string;
}

export interface ISubscriptionsHistoryItem {
  createdAt: string;
  total: string;
  description: string;
  id: string;
}

export interface ISubscriptionsHistory {
  nextToken: string;
  items: ISubscriptionsHistoryItem[];
}

export interface ISubscriptionPlanPices {
  regular?: number;
  discount?: number;
}

export interface IAISubscriptionPaymentPlan {
  credits: number;
  price?: ISubscriptionPlanPices;
}

export interface ISelectedPlanData {
  creatorId: string;
  plan: PlanAccount;
  regular?: number;
  discount?: number;
  disabled?: boolean;
}

export interface ISelectedAIPlanData {
  name: AIPlanAccount;
  info: IAISubscriptionPaymentPlan;
}

export enum PlanAccount {
  M1 = 'M1',
  M3 = 'M3',
  M6 = 'M6',
}

export enum AIPlanAccount {
  AI1 = 'AI1',
  AI2 = 'AI2',
  AI3 = 'AI3',
}

export interface ISubscriptions {
  agencyId: string;
  creator: ICreators;
  creatorId: string;
  creatorName: string;
  creatorUsername: string;
  endDate: string;
  plan: PlanAccount;
  sessionStatus: CreatorsSessionStatus;
  startDate: string;
  stats30D: string;
  status: SubscriptionStatus;
  subscriptionId: string;

  [PlanAccount.M1]: ISubscriptionPlanPices;
  [PlanAccount.M3]: ISubscriptionPlanPices;
  [PlanAccount.M6]: ISubscriptionPlanPices;
}

export interface IAISubscriptions {
  [AIPlanAccount.AI1]?: IAISubscriptionPaymentPlan;
  [AIPlanAccount.AI2]?: IAISubscriptionPaymentPlan;
  [AIPlanAccount.AI3]?: IAISubscriptionPaymentPlan;
}

export enum PaymentStatus {
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

export interface IPaymentInvoice {
  hosted_invoice_url?: string;
  id?: string;
  status?: PaymentStatus;
  tax?: number;
  number?: string;
  total?: number;
  total_excluding_tax?: number;
}

export type IPaymentCustomer = IPaymentAccountInfo & IPaymentBusinessPurchase;

export interface IAIBalance {
  balanceAI: string | number;
  balanceAITotal: string | number;
}

export const subscriptionStatusMap: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.PAID]: 'Paid',
  [SubscriptionStatus.UNPAID]: 'Unpaid',
  [SubscriptionStatus.TRIAL]: 'Free Trial',
};
