import { Link } from 'react-router-dom';

import { classNames } from 'utils/classNames';
import { useAppSelector } from 'store';
import { getUserAuthData } from 'store/reducers/auth';

import { ReactComponent as SettingsIcon } from '../../assets/images/settings.svg';
import { RoutePath } from '../../config/routeConfig';

import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps) => {
  const user = useAppSelector(getUserAuthData);

  return (
    <header className={classNames(styles.header, {}, [className])}>
      {/*<div>Free trial: <span>14 days</span></div>*/}

      <Link to={RoutePath.settings} className={styles.settingsBox}>
        <div className={styles.settings}>
          <SettingsIcon />
        </div>
        <div className={styles.currentSession}>{user?.name}</div>
      </Link>
      {/*<div className={styles.avatar}></div>*/}
    </header>
  );
};

export default Header;
