import { Box, Typography } from '@mui/material';

import { formatNumberWithPrecision } from 'utils/convert';
import { MediaStatusEnum } from 'models/media';

import useStyles from './useStyles';

interface IPriceTag {
  status: string;
  price: number;
}

const PriceTag = ({ status, price }: IPriceTag) => {
  const styles = useStyles();

  const isPaid = status === MediaStatusEnum.Paid;

  return (
    <Box sx={styles.overlay(isPaid)}>
      <Typography component={'span'}>${formatNumberWithPrecision(price)}</Typography>
    </Box>
  );
};

export default PriceTag;
