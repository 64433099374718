import styles from './AISubscription.module.scss';
import { AIBalance } from './AIBalance/AIBalance';
import { BuyAICredits } from './BuyAICredits/BuyAICredits';

export const AISubscription = () => {
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <AIBalance />
      </div>
      <div className={styles.payment}>
        <BuyAICredits />
      </div>
    </div>
  );
};
