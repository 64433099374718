import { classNames } from 'utils/classNames';
import { ReactComponent as Logo } from 'assets/images/temp/smoke-logo.svg';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: JSX.Element;
  className?: string;
}

const Layout = ({ className, children }: LayoutProps) => {
  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.content}>{children}</div>
      <Logo className={styles.brandLogo} />
    </div>
  );
};

export default Layout;
