import { useCallback, useEffect, useState } from 'react';
import { IconButton, Box } from '@mui/material';

import { useElectronContext } from 'modules/electron/providers';

import { Reload } from '../../../../../../icons';
import Input from '../Form/Input';

import useStyles from './useStyles';

interface IForm {
  onChange: (value: number | string) => void;
}

const Filter = ({ onChange }: IForm) => {
  const styles = useStyles();

  const { loadOnlineChatters, activeCreator, isOnlineSubscribersLoading } = useElectronContext();
  const [total, setTotal] = useState<number | string>('');

  const handleChange = useCallback((value: string) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setTotal(+value);
    }
  }, []);

  const handleReload = useCallback(() => {
    if (!activeCreator?.id) {
      return;
    }

    loadOnlineChatters({ creatorId: activeCreator.id });
  }, [activeCreator, loadOnlineChatters]);

  useEffect(() => {
    onChange(total);
  }, [total, onChange]);

  return (
    <Box sx={styles.root}>
      <Box>
        Who Spent ≥ <Input value={total} onChange={handleChange} />$
      </Box>
      <IconButton onClick={handleReload} disabled={isOnlineSubscribersLoading}>
        <Reload />
      </IconButton>
    </Box>
  );
};

export default Filter;
