import { DashboardProvider } from './providers';
import DashboardContainer from './DashboardContainer';

const OFDashboardView = () => (
  <DashboardProvider>
    <DashboardContainer />
  </DashboardProvider>
);

export default OFDashboardView;
