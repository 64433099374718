import { useCallback, useEffect, useRef } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';

import { scrappingService } from '../../../../services/ScrapingService';
import { ISubscriberInfo } from '../../../../models/subscribers';
import useFetch from '../../../../hooks/useFetch';

import useForceUpdateChatters from './useForceUpdateChatters';
import { IForceUpdatedData, IGetChatterInfo, LoadChatterInfoType } from './interfaces';

type UseActiveChatterInfoType = (data: { activeCreatorId?: string | null; activeChatterId: string | null }) => {
  chatter: ISubscriberInfo | null;
  loading: boolean;
  getChatterInfo: LoadChatterInfoType;
  forceUpdatedData: Record<string, IForceUpdatedData>;
};

const useActiveChatterInfo: UseActiveChatterInfoType = ({ activeCreatorId, activeChatterId }) => {
  const unmountedRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadChatterInfo = useCallback(
    async (
      { creatorId, chatterId, forceUpdate = false }: IGetChatterInfo,
      config: AxiosRequestConfig = {},
    ): Promise<ISubscriberInfo | null | -1> => {
      try {
        const chatterInfoMethod = forceUpdate ? 'forceUpdateChatterInfo' : 'getChatterInfo';

        const data = await scrappingService[chatterInfoMethod](
          {
            creatorId,
            chatterId,
          },
          config,
        );

        return data?.info ?? null;
      } catch (e) {
        console.log('e', e);
      }

      return forceUpdate ? -1 : null;
    },
    [],
  );

  const { forceUpdatedData, callForceUpdate } = useForceUpdateChatters({ activeChatterId });

  const { data, loading, callLoadData, setData } = useFetch(loadChatterInfo);

  const getChatterInfo = useCallback(
    (props: IGetChatterInfo) => {
      const { forceUpdate = false } = props;

      if (forceUpdate) {
        callForceUpdate(props, async () => {
          const data = await loadChatterInfo({ ...props, forceUpdate: false });

          if (data && !unmountedRef.current && data !== -1) {
            setData(data);
          }
        });
      }

      callLoadData(props);
    },
    [callForceUpdate, setData],
  );

  useEffect(() => {
    if (!activeChatterId) {
      setData(null);
    }
  }, [activeChatterId]);

  useEffect(() => {
    if (!activeChatterId || !activeCreatorId) {
      return;
    }

    callLoadData({ creatorId: activeCreatorId, chatterId: activeChatterId });
  }, [activeCreatorId, activeChatterId, callLoadData]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return { chatter: data === -1 ? null : data, loading, getChatterInfo, forceUpdatedData };
};

export default useActiveChatterInfo;
