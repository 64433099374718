import { AppRoutes, routeConfig, RoutePath } from 'config/routeConfig';

import { ReactComponent as MembersIcon } from '../assets/images/members.svg';
import { ReactComponent as AcountsIcon } from '../assets/images/creators.svg';
import { ReactComponent as RolesIcon } from '../assets/images/roles.svg';
import { ReactComponent as StatisticsIcon } from '../assets/images/statistics.svg';
import { ReactComponent as BillingIcon } from '../assets/images/billing.svg';

import { PermissionsType } from './roles';

export interface SidebarItemType {
  path: string;
  text: string;
  Icon: React.VFC<React.SVGProps<SVGSVGElement>>;
  permissions?: (keyof PermissionsType)[];
}

const getRoutePermissions = (route: AppRoutes) => routeConfig[route].permissions;

export const sidebarItemsList: SidebarItemType[] = [
  {
    path: RoutePath.accounts,
    text: 'Accounts',
    Icon: AcountsIcon,
    permissions: getRoutePermissions(AppRoutes.ACCOUNTS),
  },
  {
    path: RoutePath.statistics,
    text: 'Statistics',
    Icon: StatisticsIcon,
    permissions: getRoutePermissions(AppRoutes.STATISTICS),
  },
  {
    path: RoutePath.members,
    text: 'Members',
    Icon: MembersIcon,
    permissions: getRoutePermissions(AppRoutes.MEMBERS),
  },
  {
    path: RoutePath.roles,
    text: 'Roles',
    Icon: RolesIcon,
    permissions: getRoutePermissions(AppRoutes.ROLES),
  },
  {
    path: RoutePath.billing,
    text: 'Billing',
    Icon: BillingIcon,
    permissions: getRoutePermissions(AppRoutes.BILLING),
  },
];

export const getClosestSidebarPathIntersection = (userPermissions: string[] | null) => {
  for (const item of sidebarItemsList) {
    const closestPossibleIntersection = item?.permissions?.find((value) => userPermissions?.includes(value));
    if (closestPossibleIntersection) return item.path;
  }

  return RoutePath.settings;
};
