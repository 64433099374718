// Based on format dateString = '2024-04-15 21:06:00.000000000';
export const getUTCDateBasedISOFormat = (dateString: string) => {
  // Разбиваем строку на компоненты даты и времени
  const [datePart, timePart] = dateString.split(' ');

  // Разбираем компоненты даты и времени
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Создаем объект Date
  const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

  // Получаем смещение временной зоны в минутах
  const timezoneOffsetInMinutes = dateObject.getTimezoneOffset();

  // Применяем смещение временной зоны
  const timestampWithTimezone = dateObject.getTime() + timezoneOffsetInMinutes * -60 * 1000;

  // Создаем новый объект Date с примененным смещением временной зоны
  const dateWithTimezone = new Date(timestampWithTimezone);

  return dateWithTimezone;
};

export const getCurrentTimezoneOffsetInSec = () => {
  const currentDate = new Date();
  const timezoneOffsetInSeconds = currentDate.getTimezoneOffset() * -60;

  return timezoneOffsetInSeconds;
};
