import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';

import { ICreators, SubscriptionStatus } from '../../../models/creators';
import { Button, SizeButton, ThemeButton } from '../../Button/Button';
import { RoutePath } from '../../../config/routeConfig';

import styles from './CurrentPlan.module.scss';

const STATUS_TITLE = {
  [SubscriptionStatus.TRIAL]: 'Trial Period',
  [SubscriptionStatus.UNPAID]: 'Unpaid',
  [SubscriptionStatus.PAID]: 'Paid',
};

const BG_COLOR = {
  [SubscriptionStatus.TRIAL]: 'linear-gradient(0deg, rgba(0,125,240,1) 0%, rgba(0,212,255,1) 100%)',
  [SubscriptionStatus.UNPAID]: 'linear-gradient(0deg, #F3FFAB 0%, #F05600 100%)',
  [SubscriptionStatus.PAID]: 'linear-gradient(0deg, #ABF0FF 0%, #5BC0EB 100%)',
};

const COLOR_END_DATE = {
  [SubscriptionStatus.TRIAL]: '#ABDCF1',
  [SubscriptionStatus.UNPAID]: '#C46D03',
  [SubscriptionStatus.PAID]: '#306477',
};

const COLOR_BTN = {
  [SubscriptionStatus.TRIAL]: '#00AFF0',
  [SubscriptionStatus.UNPAID]: '#C46D03',
  [SubscriptionStatus.PAID]: '',
};

interface Props {
  data: ICreators;
}

export const CurrentPlan = ({ data }: Props) => {
  const navigate = useNavigate();

  const statusType: SubscriptionStatus = useMemo(() => data && data.subscription?.status, [data]);

  const renderText = useMemo(() => {
    switch (statusType) {
      case SubscriptionStatus.UNPAID: {
        return 'Your AI training will begin after payment';
      }

      default: {
        const endDate = new Date(data?.subscription?.endDate);
        return `Expiry ${format(endDate, 'MMMM dd, yyyy')}`;
      }
    }
  }, [statusType]);

  const handleSelectPlan = useCallback(() => {
    navigate(RoutePath.billing);
  }, []);

  return (
    <div className={styles.box} style={{ background: BG_COLOR[statusType] }}>
      <p className={styles.plan}>Current Plan</p>

      <div className={styles.inner}>
        <div>
          <h3 className={styles.titleStatus}>{STATUS_TITLE[statusType]}</h3>
          <p className={styles.endDate} style={{ color: COLOR_END_DATE[statusType] }}>
            {renderText}
          </p>
        </div>
        {statusType === SubscriptionStatus.PAID ? (
          <BrandLogo className={styles.logo} />
        ) : (
          <Button
            className={styles.btn}
            style={{ color: COLOR_BTN[statusType] }}
            size={SizeButton.M}
            theme={ThemeButton.CLEAR}
            onClick={handleSelectPlan}
          >
            Select plan
          </Button>
        )}
      </div>
    </div>
  );
};
