import { useTheme } from '@mui/material';

import { ScreenSizes } from 'modules/electron/constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      gap: '16px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        gap: '12px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        gap: '9px',
      },
    },
    media: (isBg = true) => ({
      width: '123px',
      height: '123px',
      minWidth: '123px',
      borderRadius: '10px',
      overflow: 'hidden',
      position: 'relative',
      background: isBg ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)' : 'transparent',

      '& img': {
        width: '100%',
        objectFit: 'cover',
      },

      '& svg': {
        width: '100%',
        height: '100%',
      },

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: '92px',
        height: '92px',
        minWidth: '92px',
        borderRadius: '8px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: '74px',
        height: '74px',
        minWidth: '74px',
        borderRadius: '5px',
      },
    }),
    overlay: (isPaid: boolean) => ({
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: isPaid ? 'rgba(0, 0, 0, 0.50)' : 'rgba(0, 0, 0, 0)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:before': isPaid
        ? {
            content: '""',
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4585 19.4792C13.4585 20.8533 14.521 21.9583 15.8243 21.9583H18.4876C19.621 21.9583 20.5418 20.995 20.5418 19.7908C20.5418 18.5017 19.9752 18.0342 19.1393 17.7367L14.8752 16.2492C14.0393 15.9517 13.4727 15.4983 13.4727 14.195C13.4727 13.005 14.3935 12.0275 15.5268 12.0275H18.1902C19.4935 12.0275 20.556 13.1325 20.556 14.5067' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 10.625V23.375' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M24.0835 4.25V9.91667H29.7502' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M31.1668 2.83334L24.0835 9.91668' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.0002 2.83334C9.18016 2.83334 2.8335 9.18001 2.8335 17C2.8335 22.5817 6.0635 27.4125 10.7668 29.7217' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M31.1667 17C31.1667 24.82 24.82 31.1667 17 31.1667' stroke='white' stroke-width='2.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
            width: '34px',
            height: '34px',
          }
        : {},

      '& span': {
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        color: 'var(--white-color)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        [breakpoints.down(ScreenSizes.screenXXl)]: {
          fontSize: '12px',
        },
        [breakpoints.down(ScreenSizes.screenXl)]: {
          fontSize: '8px',
        },
      },
    }),
    text: {
      color: 'var(--black-color)',
      fontSize: '19px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      marginBottom: '4px',
      maxWidth: '306px',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '15px',
        maxWidth: '230px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
        maxWidth: '180px',
      },
    },
    date: {
      color: '#848484',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '12px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '8px',
      },
    },
    bundle: {
      position: 'absolute',
      top: '3px',
      right: '3px',
      width: '13px',
      height: '13px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  } as Record<string, any>;
};

export default useStyles;
