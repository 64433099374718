import { Props } from 'react-apexcharts';

import variables from 'assets/styles/variables.module.scss';

export default {
  options: {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#474B56',
      },
    },
    yaxis: {
      //@ts-ignore
      //  stepSize: 50,
      forceNiceScale: true,
      min: 0,
      labels: {
        style: {
          colors: '#A3ADB1',
        },
      },
    },
    stroke: {
      width: 3,
      colors: [variables.accentColor],
      curve: 'smooth',
    },
    markers: {
      colors: [variables.accentColor],
    },
    tooltip: {
      marker: {
        show: false,
      },
    },
    grid: {
      borderColor: '#474B56',
    },
    fill: {
      type: 'solid',
      opacity: [0.1, 1],
    },
  },
  series: [
    {
      name: 'Income',
      type: 'area',
      data: [0],
    },
  ],
} as Props;
