import {
  PAYMENT,
  PAYMENT_CUSTOMERS,
  PAYMENT_BALANCE,
  SUBSCRIPTIONS_HISTORY,
  SUBSCRIPTIONS,
  PAYMENT_AI_BALANCE,
  SUBSCRIPTIONS_AI,
} from 'config/api';
import {
  AIPlanAccount,
  IAIBalance,
  IAISubscriptions,
  IPaymentCustomer,
  IPaymentInvoice,
  ISelectedPlanData,
  ISubscriptions,
  ISubscriptionsHistory,
} from 'models/billing';
import { IMember } from 'models/members';

import { BaseService } from './BaseService';

class BillingService extends BaseService {
  public async getBalance(): Promise<{ balance: string }> {
    return this.get(PAYMENT_BALANCE);
  }

  public async getAIBalance(): Promise<IAIBalance> {
    return this.get(PAYMENT_AI_BALANCE);
  }

  public async getSubscriptionsHistory(): Promise<ISubscriptionsHistory> {
    return this.get(SUBSCRIPTIONS_HISTORY, { query: { limit: 500 } });
  }

  public async getSubscriptions(): Promise<{ subscriptions: ISubscriptions[] }> {
    return this.get(SUBSCRIPTIONS);
  }

  public async getAISubscriptions(): Promise<{ subscriptions: IAISubscriptions }> {
    return this.get(SUBSCRIPTIONS_AI);
  }

  public async createPaymentSubscriptions(items: ISelectedPlanData[]): Promise<{ status: string }> {
    return this.post(SUBSCRIPTIONS, { items });
  }
  public async createPaymentAISubscriptions(plan: AIPlanAccount): Promise<{ status: string }> {
    return this.patch(PAYMENT_AI_BALANCE, { plan });
  }

  public async createPaymentCustomer(data: IPaymentCustomer): Promise<{ member: IMember }> {
    return this.post(PAYMENT_CUSTOMERS, data);
  }

  public async updatePaymentCustomer(data: IPaymentCustomer): Promise<{ member: IMember }> {
    return this.patch(PAYMENT_CUSTOMERS, data);
  }

  public async createPaymentInvoice(amount: number): Promise<{ invoice: IPaymentInvoice }> {
    return this.post(PAYMENT, { amount });
  }

  public async getPaymentInvoice(id: string): Promise<{ invoice: IPaymentInvoice }> {
    return this.get(`${PAYMENT}/${id}`);
  }
}

export const billingService = new BillingService();
