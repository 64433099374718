import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { ISubscriber } from 'models/subscribers';

import { ScreenSizes } from '../../../../../constants';

import Chatter from './Chatter';
import useStyles from './useStyles';

const OnlineChatterList = ({ subscribers }: { subscribers: ISubscriber[] }) => {
  const styles = useStyles();

  const { width } = useWindowDimensions();

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const subscriber = subscribers[index];

    return (
      <Box sx={{ ...style }}>
        <Box sx={styles.item}>
          <Chatter key={subscriber.id} subscriber={subscriber} />
        </Box>
      </Box>
    );
  };

  const itemSize = useMemo(() => {
    if (width < ScreenSizes.screenXl) {
      return 39 + 5;
    }

    if (width < ScreenSizes.screenXXl) {
      return 46 + 5;
    }

    return 61 + 10;
  }, [width]);

  if (!subscribers.length) {
    return null;
  }

  return (
    <Box sx={styles.list}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List height={height} itemCount={subscribers.length} itemSize={itemSize} width={width}>
            {renderRow}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
};

export default OnlineChatterList;
