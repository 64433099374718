import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { yupToFormErrors } from 'formik';

import { Button, ThemeButton } from 'components/Button/Button';
import Select from 'components/fields/Select/Select';
import { ICreators, ICreatorsDictionary } from 'models/creators';
import { convertToDropdownArray, convertToDropdownItem } from 'utils/convert';
import { IValidateFlags } from 'models/validation';
import { connectCreatorSchema } from 'utils/validators';
import { ErrorData, IDropdownOption } from 'models/fields';
import { getApiErrorMessageByField } from 'constants/messages';

import styles from './ProxyStep.module.scss';

type ProxyDataType = Pick<ICreators, 'proxyCountry'>;

interface ProxyStepProps {
  data: ProxyDataType;
  dictionary: ICreatorsDictionary;
  isEdit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  apiErrors?: ErrorData[];
  onNextStep: () => void;
  onChangeData: (value: string, targetName?: string) => void;
}

export const ProxyStep = memo(
  ({ data, dictionary, isEdit, apiErrors, disabled, loading, onNextStep, onChangeData }: ProxyStepProps) => {
    const [validateErrors, setValidateErrors] = useState<IValidateFlags<ProxyDataType>>({});

    const proxyOptions = useMemo(() => convertToDropdownArray(dictionary.proxyCountry), [dictionary]);

    useEffect(() => {
      setValidateErrors({});
    }, [data]);

    const onClickConnectCreator = useCallback(async () => {
      try {
        await connectCreatorSchema.proxyStep.validate({ ...data }, { abortEarly: false });
        onNextStep();
      } catch (errors) {
        setValidateErrors(yupToFormErrors(errors));
      }
    }, [data, onNextStep]);

    const list = useMemo(() => {
      if (isEdit) {
        return ['You can change the proxy once a month.', 'If you have issues contact support.'];
      }

      return [
        'Select the country where the creator is located.',
        'If that specific country is not present, choose one close to it.',
        'Proxies guarantee a fast, secure, and reliable connection.',
        'We have chosen trusted and stable providers.',
      ];
    }, [isEdit]);

    return (
      <div className={styles.container}>
        <h2 className={styles.title}>{isEdit ? 'Edit Proxy' : `Let's set it up`}</h2>
        <p className={styles.subtitle}>{isEdit ? 'Remember' : `Just a heads-up:`}</p>
        <ul className={styles.list}>
          {list.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
        <div className={styles.actions}>
          <Select
            name="proxyCountry"
            options={proxyOptions}
            className={styles.select}
            placeholder="Select a Proxy Country"
            value={convertToDropdownItem(data.proxyCountry)}
            onChange={(value, actionMeta) => {
              const targetName = actionMeta.name;
              const updatedValue = value as IDropdownOption;
              onChangeData(updatedValue.value, targetName!);
            }}
            error={validateErrors.proxyCountry || getApiErrorMessageByField('proxyCountry', apiErrors)}
          />
          <Button theme={ThemeButton.PRIMARY} onClick={onClickConnectCreator} disabled={disabled} loading={loading}>
            {isEdit ? 'Edit' : 'Continue'}
          </Button>
        </div>
      </div>
    );
  },
);
