import { Skeleton } from '@mui/material';

import { ReactComponent as AIStarsIcon } from 'assets/images/billing/ai-stars.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { classNames } from 'utils/classNames';
import { ReactComponent as AISubscriptionIcon } from 'assets/images/billing/ai-subscription.svg';
import { useAppSelector } from 'store';
import { getBillingAIBalance, getBillingAILoading } from 'store/reducers/billing';

import styles from './AIBalance.module.scss';

export const AIBalance = () => {
  const loading = useAppSelector(getBillingAILoading);
  const balance = useAppSelector(getBillingAIBalance);

  return (
    <>
      <div className={styles.icon}>
        <AISubscriptionIcon />
      </div>
      <h3 className={styles.title}>Current AI Balance</h3>
      <div className={classNames(styles.value, { [styles.loading]: loading })}>
        {loading ? (
          <Skeleton variant="rounded" />
        ) : (
          <>
            <AIStarsIcon /> {balance.balanceAI} AI Credits
          </>
        )}
      </div>
      <ProgressBar progress={+balance.balanceAI} max={+balance.balanceAITotal} className={styles.progressBar} />
    </>
  );
};
