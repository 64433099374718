import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Button, ThemeButton } from 'components/Button/Button';

import styles from './ConfirmationDialog.module.scss';

interface IConfirmationDialog {
  message: string;
  title: string;
  onConfirm: () => Promise<void>;
  cancelLabel?: string;
  confirmLabel?: string;
  onClose: () => void;
}

const ConfirmationDialog = (props: IConfirmationDialog) => {
  const { message, title, onConfirm, onClose, cancelLabel = 'No', confirmLabel = 'Yes' } = props;

  const [confirmationLoading, setConfirmationLoading] = React.useState(false);

  const handleConfirm = async (e: any) => {
    e.stopPropagation();
    setConfirmationLoading(true);
    await onConfirm();
    setConfirmationLoading(false);
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} className={styles.dialog}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <DialogContent sx={{ mt: 1, mb: 1 }} className={styles.content}>
        <DialogContentText align="left" className={styles.text}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }} className={styles.actions}>
        <Button theme={ThemeButton.DARK_OUTLINE} onClick={handleClose} disabled={confirmationLoading}>
          {cancelLabel}
        </Button>
        <Button theme={ThemeButton.PRIMARY} onClick={handleConfirm} disabled={confirmationLoading}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
