import React from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames } from 'utils/classNames';
import { RoutePath } from 'config/routeConfig';
import { Button, ThemeButton } from 'components/Button/Button';

import styles from './RedirectNotification.module.scss';

interface RedirectNotificationProps {
  className?: string;
}

export const RedirectNotification = ({ className }: RedirectNotificationProps) => {
  const navigate = useNavigate();

  const onClickRedirectBtn = () => {
    navigate(RoutePath.billing);
  };

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.info}>
        <h2 className={styles.title}>Select Plan</h2>
        <p className={styles.description}>
          You have a creator without a subscription plan or not verified. To access analytics, please upgrade this
          creator to a Premium plan.
        </p>
      </div>

      <Button theme={ThemeButton.PRIMARY} className={styles.redirect} onClick={onClickRedirectBtn}>
        Go to Billings
      </Button>
    </div>
  );
};
