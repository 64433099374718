import { useEffect, useState } from 'react';
import { yupToFormErrors } from 'formik';
import { Link } from 'react-router-dom';
import { DeepPartial } from '@reduxjs/toolkit';

import { getApiErrorMessageByField } from 'constants/messages';
import Input from 'components/fields/Input/Input';
import { RoutePath } from 'config/routeConfig';
import { Button, ThemeButton } from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { authActions, getUserAuthErrors, signIn } from 'store/reducers/auth';
import { signInFormSchema } from 'utils/validators';
import { ISignInRequest } from 'models/auth';
import { ThemeField } from 'models/fields';

import styles from './RequestForm.module.scss';

interface RequestFormProps {
  onClickForgotPassword: () => void;
}

const RequestForm = ({ onClickForgotPassword }: RequestFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validateErrors, setValidateErrors] = useState<DeepPartial<Omit<ISignInRequest, 'isStepForSignUp'>>>({});
  const [loading, setLoading] = useState(false);
  const apiErrors = useAppSelector(getUserAuthErrors);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValidateErrors({});
    dispatch(authActions.updateAuthErrors(undefined));
  }, [email, password]);

  const onClickSignInBtn = async () => {
    setLoading(true);
    try {
      await signInFormSchema.validate({ email, password }, { abortEarly: false });
      await dispatch(signIn({ email, password }));
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
    setLoading(false);
  };

  const onPressKeyButton = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickSignInBtn();
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Log In</h1>
      <Input
        fieldName="E-mail"
        placeholder="Ex: abc@example.com"
        theme={ThemeField.LIGHT}
        value={email}
        onChange={(value) => setEmail(value.trim())}
        className={styles.input}
        error={validateErrors.email}
        readonly={loading}
        onKeyUp={onPressKeyButton}
        mandatory
      />
      <Input
        placeholder="Enter your password"
        theme={ThemeField.LIGHT}
        readonly={loading}
        value={password}
        onChange={(value) => setPassword(value)}
        fieldName="Password"
        type="password"
        error={validateErrors.password || getApiErrorMessageByField('password', apiErrors)}
        onKeyUp={onPressKeyButton}
        mandatory
      />
      <div className={styles.forgotPassword} onClick={onClickForgotPassword}>
        Forgot Password?
      </div>
      <Button onClick={onClickSignInBtn} theme={ThemeButton.PRIMARY} className={styles.button} loading={loading}>
        Log in
      </Button>
      <p className={styles.signUpLink}>
        Don’t have an account? <Link to={RoutePath.signUp}>Sign Up here!</Link>
      </p>
    </div>
  );
};

export default RequestForm;
