import { useCallback, useEffect, useMemo, useState } from 'react';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { useFormik, yupToFormErrors } from 'formik';
import { useSnackbar } from 'notistack';

import { ReactComponent as BasicInfoIcon } from 'assets/images/editAccount/basicInfo.svg';
import { ReactComponent as AboutIcon } from 'assets/images/editAccount/about.svg';

import { useAppDispatch, useAppSelector } from '../../../store';
import { editAccountFormSchema } from '../../../utils/validators';
import { convertToDropdownArray, convertToDropdownItem } from '../../../utils/convert';
import { getModalLoading } from '../../../store/reducers/modal';
import { getCreatorsDictionary, updateCreatorData } from '../../../store/reducers/creators';
import Card from '../Card/Card';
import Input from '../../fields/Input/Input';
import Select from '../../fields/Select/Select';
import { ChipArea } from '../../fields/ChipArea/ChipArea';
import { TextArea } from '../../fields/TextArea/TextArea';
import { Button, SizeButton, ThemeButton } from '../../Button/Button';
import { IDropdownOption } from '../../../models/fields';
import { ICreators } from '../../../models/creators';
import { IValidateFlags } from '../../../models/validation';

import styles from './AccountInfoForm.module.scss';

type BasicInfoDataType = Pick<
  ICreators,
  'name' | 'birthDate' | 'country' | 'city' | 'notes' | 'sex' | 'conversationalStyle' | 'fetish'
>;

interface Props {
  data: ICreators;
}

export const AccountInfoForm = ({ data }: Props) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loading = useAppSelector(getModalLoading);
  const dictionary = useAppSelector(getCreatorsDictionary);

  const [validateErrors, setValidateErrors] = useState<IValidateFlags<BasicInfoDataType>>({});

  const initialValues = useMemo(
    () => ({
      name: data.name,
      birthDate: data.birthDate,
      country: data.country,
      city: data.city,
      notes: data.notes,
      sex: data.sex,
      conversationalStyle: data.conversationalStyle,
      fetish: data.fetish,
    }),
    [data],
  );

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        ...convertToDropdownItem(country.name),
        ...country,
      })),
    [],
  );

  const sexOptions = useMemo(() => convertToDropdownArray(dictionary.sex), [dictionary]);

  const getCityOptionsByCountry = useCallback(
    (country?: ICountry | string) => {
      if (!country) {
        return [];
      }

      let countryCode: string | undefined = '';
      if (typeof country === 'string') {
        countryCode = countryOptions.find((item) => item.name === country)?.isoCode;
      } else {
        countryCode = country.isoCode;
      }

      if (!countryCode) {
        return [];
      }

      const cities = City.getCitiesOfCountry(countryCode);
      const uniqueCities = [...new Map(cities?.map((item) => [item['name'], item])).values()] as ICity[];
      return uniqueCities.map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));
    },
    [countryOptions],
  );

  const [conversationalStyleOptions, fetishOptions] = useMemo(() => {
    const { conversationalStyle, fetish } = dictionary;

    return [convertToDropdownArray(conversationalStyle), convertToDropdownArray(fetish)];
  }, [dictionary]);

  const { values, handleSubmit, setFieldValue, dirty } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (!data.id) return;

      const { name, birthDate, country, city, notes, sex, conversationalStyle, fetish } = values;

      const response = await dispatch(
        updateCreatorData({
          id: data.id,
          data: {
            city,
            notes,
            sex,
            conversationalStyle,
            fetish,
            name,
            birthDate,
            country,
          },
        }),
      );

      if (response.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Successfully updated!', { variant: 'success' });
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setValidateErrors({});
  }, [values]);

  const { name, birthDate, country, city, notes, sex, conversationalStyle, fetish } = values;

  const onChangeData = useCallback(
    (value: any, targetName?: string) => {
      targetName && setFieldValue(targetName, value);
    },
    [setFieldValue],
  );

  const handleUpdate = useCallback(async () => {
    try {
      await editAccountFormSchema.validate({ ...values }, { abortEarly: false });
      handleSubmit();
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
  }, [values]);

  return (
    <div className={styles.box}>
      <Card title="Basic Info" icon={<BasicInfoIcon />} isResetStyles>
        <section className={styles.form}>
          <div className={styles.formInner}>
            <Input
              name="name"
              fieldName="Creator's Name"
              placeholder="Name"
              value={name}
              onChange={onChangeData}
              className={styles.field}
              error={validateErrors.name}
            />
            <Select
              name="sex"
              fieldName="Sex"
              className={styles.select}
              placeholder="Sex"
              value={convertToDropdownItem(sex)}
              options={sexOptions}
              onChange={(value, actionMeta) => {
                const targetName = actionMeta.name;
                const updatedValue = value as IDropdownOption;
                onChangeData(updatedValue.value, targetName!);
              }}
              error={validateErrors.sex}
            />
            <Input
              type="number"
              name="birthDate"
              fieldName="Age"
              placeholder="Age"
              value={birthDate}
              onChange={onChangeData}
              className={styles.field}
              error={validateErrors.birthDate}
            />
            <Select
              name="country"
              fieldName="Country"
              placeholder="Select or enter country"
              value={convertToDropdownItem(country)}
              options={countryOptions}
              onChange={(value, actionMeta) => {
                const targetName = actionMeta.name;
                const updatedValue = value as ICountry;
                onChangeData(updatedValue.name, targetName!);
              }}
              onInputChange={(newValue) => {
                onChangeData(newValue, 'country');
              }}
              className={styles.select}
              error={validateErrors.country}
            />
            <Select
              name="city"
              fieldName="City"
              placeholder="Select or enter city"
              value={convertToDropdownItem(city)}
              options={getCityOptionsByCountry(country)}
              onChange={(value, actionMeta) => {
                const targetName = actionMeta.name;
                const updatedValue = value as ICity;
                onChangeData(updatedValue.name, targetName!);
              }}
              onInputChange={(newValue, actionMeta) => {
                onChangeData(newValue, 'city');
              }}
              className={styles.select}
              error={validateErrors.city}
            />
          </div>
          <TextArea
            name="notes"
            placeholder="AI Note"
            fieldName="AI Notes: Fill it with your creator Information"
            value={notes}
            className={styles.notes}
            onChangeValue={onChangeData}
            /* defaultValue={AI_TEMPLATE_TEXT}*/
          />
        </section>
      </Card>

      <div className={styles.line} />

      <Card title="About" icon={<AboutIcon />} isResetStyles>
        <section className={styles.form}>
          <Select
            name="conversationalStyle"
            fieldName="Conversational Style"
            placeholder="Choose your style"
            value={convertToDropdownItem(conversationalStyle)}
            onChange={(value, actionMeta) => {
              const targetName = actionMeta.name;
              const updatedValue = value as IDropdownOption;
              onChangeData(updatedValue.value, targetName!);
            }}
            options={conversationalStyleOptions}
            className={styles.style}
            error={validateErrors.conversationalStyle}
          />
          <ChipArea
            name="fetish"
            fieldName="Select Fetish"
            value={convertToDropdownArray(fetish as string[]) || []}
            onChange={(value, targetName) =>
              onChangeData(
                (value as IDropdownOption[]).map((item) => item.value),
                targetName,
              )
            }
            options={fetishOptions}
            multiple={true}
          />
        </section>
      </Card>

      <div className={styles.btnBox}>
        <Button
          theme={ThemeButton.PRIMARY}
          onClick={handleUpdate}
          size={SizeButton.M}
          className={styles.btn}
          disabled={!dirty}
          loading={loading}
        >
          Update
        </Button>
      </div>
    </div>
  );
};
