import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/billing/successful.svg';

import styles from './SuccessfulPayment.module.scss';

export interface SuccessfulPaymentProps {
  data?: { title?: string; text?: string };
  onClose?: () => void;
}

export const SuccessfulPayment = ({ data = {}, onClose }: SuccessfulPaymentProps) => {
  const { title, text } = data;

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <BrandLogo className={styles.logo} />
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>
      <div className={styles.body}>
        <SuccessIcon className={styles.icon} />
        <p className={styles.title}>{title || 'Payment Successful'}</p>
      </div>
      <p className={styles.text}>{text || 'Refresh page if subscription not updated'}</p>
    </div>
  );
};
