import React, { useCallback, useEffect, useState } from 'react';

import Select from '../../../fields/Select/Select';
import { IDropdownOption } from '../../../../models/fields';
import { Button, ThemeButton } from '../../../Button/Button';
import { assignMemberCreator, getMembersState } from '../../../../store/reducers/members';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getModalLoading } from '../../../../store/reducers/modal';

import styles from './AssignCreatorForm.module.scss';

export interface IAssignCreatorForm {
  nonAssignedCreators: { label: string; value: string }[];
}

const AssignCreatorForm = ({ nonAssignedCreators }: IAssignCreatorForm) => {
  const dispatch = useAppDispatch();

  const { memberActiveModal } = useAppSelector(getMembersState);
  const loading = useAppSelector(getModalLoading);

  const [selectedValue, setSelectedValue] = useState<IDropdownOption | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAssignCreator = useCallback(async () => {
    if (!selectedValue?.value || !memberActiveModal?.id) {
      return;
    }

    setIsProcessing(true);
    dispatch(
      assignMemberCreator({
        memberId: memberActiveModal?.id,
        creatorId: selectedValue?.value,
      }),
    );

    setSelectedValue(null);
  }, [selectedValue?.value, memberActiveModal?.id]);

  useEffect(() => {
    if (!loading) {
      setIsProcessing(false);
    }
  }, [loading]);

  return (
    <div className={styles.assignCreator}>
      <Select
        className={styles.input}
        options={nonAssignedCreators}
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value as IDropdownOption);
        }}
      />
      <Button
        theme={ThemeButton.PRIMARY}
        onClick={handleAssignCreator}
        disabled={!selectedValue || !memberActiveModal || loading}
        loading={isProcessing}
      >
        Assign Account
      </Button>
    </div>
  );
};

export default AssignCreatorForm;
