import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { sidebarItemsList } from 'constants/sidebar';
import { WebviewURL } from 'constants/externalLinks';
import { classNames } from 'utils/classNames';
import { useAppDispatch } from 'store';
import { RoutePath } from 'config/routeConfig';
import { logoutApp } from 'store/reducers/auth';
import usePermissions from 'hooks/usePermissions';
import { useElectronContext } from 'modules/electron/providers';

import { ReactComponent as LogOutIcon } from '../../assets/images/return.svg';
import { ReactComponent as BrandLogo } from '../../assets/images/defaultBrandLogo.svg';
import { ReactComponent as NewsLogo } from '../../assets/images/news.svg';

import { SidebarItem } from './SidebarItem/SidebarItem';
import styles from './Sidebar.module.scss';

interface SidebarProps {
  className?: string;
}

const Sidebar = ({ className }: SidebarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userPermissions, isSuperAdminPermission } = usePermissions();
  const { setOfUrl } = useElectronContext();

  const onLogout = useCallback(() => {
    dispatch(logoutApp());
    navigate(RoutePath.signIn);
  }, []);

  const itemsList = useMemo(() => {
    return sidebarItemsList
      .filter((item) => {
        if (!item.permissions?.length || !item.permissions || isSuperAdminPermission) {
          return true;
        }

        const intersection = item.permissions.filter((permission) => userPermissions?.includes(permission));

        return !!intersection.length;
      })
      .map((item) => <SidebarItem key={item.path} item={item} collapsed={false} />);
  }, [userPermissions, isSuperAdminPermission]);

  return (
    <nav className={classNames(styles.sidebar, {}, [className])}>
      <div className={styles.header}>
        <BrandLogo />
        <h1 className={styles.title}>
          <span>Chatter</span>Charms<span className={styles.postfix}>Beta</span>
        </h1>
      </div>

      <div className={styles.menu}>{itemsList}</div>

      <div className={styles.footer}>
        <div
          className={styles.button}
          onClick={() => {
            setOfUrl(WebviewURL.NOTION);
          }}
        >
          <NewsLogo />
          <span>News</span>
        </div>
        <div className={styles.button} onClick={onLogout}>
          <LogOutIcon />
          <span>Log Out</span>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
