import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { useWebviewContext } from '../../../../providers';
import { useOfSidebarContext } from '../../../providers';
import { OF_SIDEBAR_ROUTE } from '../../../constants/enums';

import { IChatter } from './interfaces';
import useStyles from './useStyles';

const Chatter = ({ subscriber }: IChatter) => {
  const styles = useStyles();

  const { setActiveChatterId, activeChatterId } = useWebviewContext();

  const { setSidebarRoute } = useOfSidebarContext();

  const { name, totalSpent, avatar } = subscriber;

  const handleClick = useCallback(() => {
    setActiveChatterId(subscriber.id);
    setSidebarRoute(OF_SIDEBAR_ROUTE.ACCOUNT);
  }, [subscriber]);

  const isActive = activeChatterId && activeChatterId === subscriber.id;

  return (
    <Button type="button" onClick={handleClick} sx={styles.button}>
      <Box sx={{ ...styles.root, ...(isActive ? styles.active : {}) }}>
        <Box sx={styles.group}>
          <Box sx={styles.image}>{avatar ? <img src={avatar} alt={name} /> : null}</Box>
          <Typography component={'span'} sx={styles.name}>
            {name}
          </Typography>
        </Box>

        <Typography component={'span'} sx={styles.price}>
          {totalSpent}$
        </Typography>
      </Box>
    </Button>
  );
};

export default Chatter;
