import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Country, IState, State, ICountry } from 'country-state-city';

import { IValidateFlags } from 'models/validation';
import Input from 'components/fields/Input/Input';
import Select from 'components/fields/Select/Select';
import { IPaymentAccountInfo } from 'models/billing';
import { convertToDropdownItem } from 'utils/convert';

import styles from './AccountStep.module.scss';

interface AccountStepProps {
  data: IPaymentAccountInfo;
  errors: IValidateFlags<IPaymentAccountInfo>;
  onChange: (value: string | boolean, targetName?: string) => void;
}

export const AccountStep = memo(({ data, errors, onChange }: AccountStepProps) => {
  const [firstName, setFirstName] = useState<string>(data.name?.split(' ')[0] || ''); // TODO: fix
  const [lastName, setLastName] = useState<string>(data.name?.split(' ')[1] || ''); // TODO: fix

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        ...convertToDropdownItem(country.name),
        ...country,
      })),
    [],
  );

  const countryValue = convertToDropdownItem(countryOptions.find((item) => item.isoCode === data.country)?.name);

  const getStatesOfCountry = useCallback(
    (country?: ICountry | string) => {
      if (!country) {
        return [];
      }

      let countryCode: string | undefined = '';
      if (typeof country === 'string') {
        countryCode = countryOptions.find((item) => item.name === country)?.isoCode;
      } else {
        countryCode = country.isoCode;
      }

      if (!countryCode) {
        return [];
      }

      const states = State.getStatesOfCountry(countryCode);
      const uniqueStates = [...new Map(states?.map((item) => [item['name'], item])).values()] as IState[];
      return uniqueStates.map((state) => ({ label: state.name, value: state.name, ...state }));
    },
    [countryOptions],
  );

  const provinceOptions = getStatesOfCountry(countryValue?.value);

  useEffect(() => {
    firstName && lastName ? onChange(`${firstName} ${lastName}`, 'name') : onChange('', 'name');
  }, [firstName, lastName, onChange]);

  return (
    <div className={styles.container}>
      <Input
        name="email"
        value={data.email}
        onChange={onChange}
        placeholder="E-mail *"
        error={errors.email}
        className={styles.input}
      />
      <div className={styles.name}>
        <Input
          placeholder="First Name *"
          value={firstName}
          onChange={setFirstName}
          error={errors.name}
          className={styles.input}
        />
        <Input
          placeholder="Last Name *"
          value={lastName}
          onChange={setLastName}
          error={errors.name}
          className={styles.input}
        />
      </div>
      <Input
        name="phone"
        value={data.phone}
        onChange={onChange}
        placeholder="Phone Number"
        error={errors.phone}
        className={styles.input}
      />
      <div className={styles.location}>
        <Select
          name="country"
          placeholder="Country"
          value={countryValue}
          options={countryOptions}
          onChange={(value, actionMeta) => {
            const targetName = actionMeta.name;
            const updatedValue = value as ICountry;
            onChange(updatedValue.isoCode, targetName!);
          }}
          error={errors.country}
          className={styles.input}
        />
        {provinceOptions.length > 0 && (
          <Select
            name="province"
            placeholder="State/Province"
            value={convertToDropdownItem(data.province)}
            options={provinceOptions}
            onChange={(value, actionMeta) => {
              const targetName = actionMeta.name;
              const updatedValue = value as ICountry;
              onChange(updatedValue.name, targetName!);
            }}
            onInputChange={(newValue) => onChange(newValue, 'province')}
            error={errors.province}
            className={styles.input}
          />
        )}
      </div>

      <Input
        name="postalCode"
        value={data.postalCode}
        onChange={onChange}
        placeholder="ZIP *"
        error={errors.postalCode}
        className={styles.input}
      />

      <div className={styles.businessPurchase} onClick={() => onChange(!data.isBusiness, 'isBusiness')}>
        <input id="businessPurchase" type="checkbox" onClick={(e) => e.stopPropagation()} checked={data.isBusiness} />
        <label htmlFor="businessPurchase">Business Purchase</label>
      </div>
    </div>
  );
});
