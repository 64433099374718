import { yupToFormErrors } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, ThemeButton } from 'components/Button/Button';
import { ThemeField } from 'models/fields';
import { confirmPasswordSchema } from 'utils/validators';
import { authService } from 'services/AuthService';
import Input from 'components/fields/Input/Input';
import { IConfirmPassword } from 'models/auth';
import { IValidateFlags } from 'models/validation';

import styles from './ConfirmPassword.module.scss';

interface ConfirmPasswordProps {
  data?: IConfirmPassword;
  onNext: () => void;
  onCancel: () => void;
  className?: string;
}

export const ConfirmPassword = ({ data, onNext, onCancel }: ConfirmPasswordProps) => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [validateErrors, setValidateErrors] = useState<
    IValidateFlags<{ newPassword: string; repeatedPassword: string }>
  >({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValidateErrors({});
  }, [newPassword, repeatedPassword]);

  const onClickRequestBtn = useCallback(async () => {
    setLoading(true);
    try {
      await confirmPasswordSchema.validate({ newPassword, repeatedPassword }, { abortEarly: false });
      if (data) {
        const { status } = await authService.confirmPassword({ ...data, password: newPassword });

        if (status === 'OK') {
          onNext();
        }
      }
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }

    setLoading(false);
  }, [data, newPassword, repeatedPassword]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Reset Your Password</h1>
      <p className={styles.description}>Your new password must be different from previous used password</p>
      <Input
        fieldName="New Password"
        placeholder="Enter a new password"
        theme={ThemeField.LIGHT}
        readonly={loading}
        value={newPassword}
        onChange={setNewPassword}
        className={styles.input}
        error={validateErrors.newPassword}
        disabled={loading}
        mandatory
      />
      <Input
        fieldName="Confirm New Password"
        placeholder="Confirm your new password"
        theme={ThemeField.LIGHT}
        readonly={loading}
        value={repeatedPassword}
        onChange={setRepeatedPassword}
        className={styles.input}
        error={validateErrors.repeatedPassword}
        disabled={loading}
        mandatory
      />
      <Button disabled={loading} theme={ThemeButton.PRIMARY} className={styles.button} onClick={onClickRequestBtn}>
        Reset Password
      </Button>
    </div>
  );
};
