import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps } from '@mui/x-date-pickers';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { classNames } from 'utils/classNames';
import { ThemeField } from 'models/fields';
import { validateDate } from 'utils/convert';

import { INamedFieldProps, NamedField } from '../NamedField/NamedField';

import styles from './DatePicker.module.scss';

interface IDatePickerProps extends DatePickerProps<Date>, INamedFieldProps {
  theme?: ThemeField;
  name?: string;
  placeholder?: string;
  className?: string;
  inputReadOnly?: boolean;
  onChangeValue?: (value?: string, targetName?: string) => void;
}

export const DateInputPicker: React.FC<IDatePickerProps> = ({
  theme = ThemeField.DARK,
  name,
  fieldName,
  placeholder,
  onChangeValue,
  error,
  className = '',
  inputReadOnly,
  ...props
}) => {
  return (
    <NamedField
      fieldName={fieldName}
      error={error}
      className={classNames(styles.dateInputPicker, { [styles.error]: error }, [className, theme])}
    >
      <div className={styles.inputWrapper}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...props}
            slotProps={{
              popper: { className: styles.calendar },
              field: { readOnly: inputReadOnly },
            }}
            slots={{ openPickerIcon: CalendarIcon }}
            format="dd/MM/yyyy"
            onChange={(value) => {
              onChangeValue?.(validateDate(value)?.toISOString(), name);
            }}
          />
        </LocalizationProvider>
      </div>
    </NamedField>
  );
};
