import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authReducer, AuthSchema } from './reducers/auth';
import { creatorsReducer, CreatorsSchema } from './reducers/creators';
import { RolesSchema, rolesReducer } from './reducers/roles';
import { MembersSchema, membersReducer } from './reducers/members';
import { modalReducer, ModalSchema } from './reducers/modal';
import fileUploader, { IFileUploaderState } from './reducers/fileUploader';
import { billingReducer, BillingSchema } from './reducers/billing';

// [TODO]: Replace all interfaces
export interface ThunkExtraArg {}

export interface StateSchema {
  auth: AuthSchema;
  creators: CreatorsSchema;
  roles: RolesSchema;
  members: MembersSchema;
  modal: ModalSchema;
  billing: BillingSchema;
  fileUploader: IFileUploaderState;
}

export interface ThunkConfig<T> {
  rejectValue: T;
  extra: ThunkExtraArg;
  state: StateSchema;
}

export function createReduxStore(initialState?: StateSchema) {
  const rootReducers: ReducersMapObject<StateSchema> = {
    auth: authReducer,
    creators: creatorsReducer,
    modal: modalReducer,
    roles: rolesReducer,
    billing: billingReducer,
    members: membersReducer,
    fileUploader,
  };

  // [TODO]: Reducer Manager
  const extraArg: ThunkExtraArg = {};

  const store = configureStore({
    reducer: rootReducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: extraArg,
        },
      }),
  });

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<StateSchema> = useSelector;
