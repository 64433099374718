import React, { useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { Box } from '@mui/material';

import { IMedia } from 'models/media';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { Media, SkeletonMedia } from '../Media';
import { ScreenSizes } from '../../../../../../../../../constants';

interface IMediaList {
  media: IMedia[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
}

const MediaList = ({ media, hasNextPage, isNextPageLoading, loadNextPage }: IMediaList) => {
  const { width } = useWindowDimensions();

  const itemCount = hasNextPage ? media.length + 1 : media.length;
  const isItemLoaded = (index: number) => !hasNextPage || index < media.length;

  const loadMoreItems = () => {
    if (isNextPageLoading) {
      return;
    }

    loadNextPage();
  };

  const itemSize = useMemo(() => {
    if (width < ScreenSizes.screenXl) {
      return 74 + 10;
    }

    if (width < ScreenSizes.screenXXl) {
      return 92 + 12;
    }

    return 123 + 15;
  }, [width]);

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              height={height}
              itemCount={itemCount}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              width={width}
              ref={ref}
            >
              {({ index, style }: { index: number; style: React.CSSProperties }) => (
                <Box sx={{ ...style }}>
                  <Box sx={{ padding: '0 15px' }}>
                    {isItemLoaded(index) ? <Media media={media[index]} /> : <SkeletonMedia />}
                  </Box>
                </Box>
              )}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default MediaList;
