import { Dispatch, ReactNode, SetStateAction } from 'react';

export enum NotificationStatusEnum {
  ON = 'ON',
  OFF = 'OFF',
  NONE = 'NONE',
}

export interface IOFNotificationContext {
  status: NotificationStatusEnum;
  setStatus: Dispatch<SetStateAction<NotificationStatusEnum>>;
  triggerFlag: string;
  setTriggerFlag: Dispatch<SetStateAction<string>>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
}

export interface IOFNotificationProps {
  children: ReactNode;
}
