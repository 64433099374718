import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Box, Button } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';

import { IModalProps } from './interfases';
import useStyles from './useStyles';

const Modal = ({ children, isOpen, handleClose, sx = {} }: IModalProps) => {
  const styles = useStyles();

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);

    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  const ModalComponent = (
    <Box sx={styles.modal}>
      <Box sx={{ ...styles.content, ...sx } as SxProps}>
        <Button onClick={handleClose} sx={styles.close}>
          &#10005;
        </Button>

        {children}
      </Box>
    </Box>
  );

  return createPortal(ModalComponent, document.body);
};

export default Modal;
