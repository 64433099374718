import { Props } from 'react-apexcharts';

import variables from 'assets/styles/variables.module.scss';

export default {
  options: {
    labels: [],
    legend: {
      show: false,
    },
    colors: ['#00AFF0', '#4BEEBD', '#FC975F', '#7A64B8', '#EC4899', '#FACC15'],
    stroke: {
      width: 3,
      colors: [variables.secondaryColor],
    },
  },
  series: [
    {
      data: [0],
    },
  ],
} as Props;
