import { default as SelectControl, createFilter, InputActionMeta } from 'react-select';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

import { classNames } from 'utils/classNames';
import { ThemeField } from 'models/fields';

import { INamedFieldProps, NamedField } from '../NamedField/NamedField';

import styles from './Select.module.scss';

type BaseSelectProps = Omit<StateManagerProps, 'theme'>;

interface ISelectProps extends BaseSelectProps, INamedFieldProps {
  theme?: ThemeField;
  className?: string;
  Icon?: React.VFC<React.SVGProps<SVGSVGElement>>;
}

const Select: React.FC<ISelectProps> = ({
  theme = ThemeField.DARK,
  fieldName,
  mandatory,
  className,
  error,
  withoutErrorMessage,
  Icon,
  isSearchable = true,
  ...props
}) => {
  const onInputChange = (newValue: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close' && props.onInputChange) {
      props.onInputChange(newValue, action);
    }
  };

  const mods = {
    [styles.error]: error,
    [styles.withIcon]: Boolean(Icon),
    [styles.notSearchable]: !isSearchable,
  };

  return (
    <NamedField
      fieldName={fieldName}
      mandatory={mandatory}
      error={error}
      withoutErrorMessage={withoutErrorMessage}
      className={classNames(styles.select, mods, [className, theme])}
    >
      <>
        <div className={styles.iconWrapper}>
          {Icon && <Icon className={styles.icon} />}
          <SelectControl
            {...props}
            filterOption={createFilter({ ignoreAccents: false })}
            onInputChange={onInputChange}
            className={classNames(styles.control, mods)}
            classNamePrefix="select"
            isSearchable={isSearchable}
          />
        </div>
      </>
    </NamedField>
  );
};

export default Select;
