import { AxiosInstance, AxiosRequestConfig } from 'axios';

import $api from 'utils/axios';

/* eslint-disable  @typescript-eslint/no-explicit-any */
type TBody = any;

export class BaseService {
  private _axios: AxiosInstance;

  public constructor() {
    this._axios = $api;
  }

  private executeSendRequest(method: 'post' | 'patch' | 'put' | 'delete') {
    return <T>(url: string, body?: TBody, headers?: any) => {
      const updatedBody = method === 'delete' ? { data: body } : body;
      return this._axios[method](url, updatedBody, {
        headers: { ...headers },
        mode: 'cors',
      } as AxiosRequestConfig<T>).then((item: any) => item.data as T);
    };
  }

  private executeGetRequest(method: 'get') {
    return <T>(url: string, config: { query?: Record<string, unknown> | null; headers?: any } = {}) => {
      const { query = null, headers = null, ...rest } = config;

      return this._axios[method]<Record<string, unknown>, { data: T }>(url, {
        ...(headers ? { headers: { ...headers } } : {}),
        ...(query ? { params: { ...query } } : {}),
        ...rest,
        mode: 'cors',
      } as AxiosRequestConfig<T>).then((item: any) => item.data);
    };
  }

  public get post() {
    return this.executeSendRequest('post');
  }

  public get put() {
    return this.executeSendRequest('put');
  }

  public get patch() {
    return this.executeSendRequest('patch');
  }

  public get get() {
    return this.executeGetRequest('get');
  }

  public get delete() {
    return this.executeSendRequest('delete');
  }
}

export const baseService = new BaseService();
