const useStyles = () => ({
  modal: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    zIndex: 999,
    padding: '20px',
  },
  close: {
    fontSize: '16px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '30px',
    height: '30px',
    background: 'rgba(138,150,163,.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'rgba(138, 150, 163, 0.3)',
    },
  },
  content: {
    borderRadius: '10px',
    width: '50%',
    maxHeight: '70%',
    maxWidth: '400px',
    height: 'fit-content',
    backgroundColor: ' #ffffff',
    color: '#242628',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    position: 'relative',
    padding: '20px',
  },
});

export default useStyles;
