import { useTheme } from '@mui/material';

import { ScreenSizes } from 'modules/electron/constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: (isActive: boolean) => ({
      color: isActive ? '#00AFF0' : '#A3ADB1',

      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      paddingBottom: '4px',
      borderBottom: '3px solid transparent',
      position: 'relative',
      textTransform: 'none',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
        borderBottomWidth: '2px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
        borderBottomWidth: '1px',
      },

      '&:after': {
        content: '""',
        backgroundColor: isActive ? '#00AFF0' : 'transparent',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '3px',
        width: isActive ? '100%' : 0,
        transition: 'width 0.3s ease-out',
        [breakpoints.down(ScreenSizes.screenXXl)]: {
          height: '2px',
        },
        [breakpoints.down(ScreenSizes.screenXl)]: {
          height: '1px',
        },
      },
    }),
  } as Record<string, any>;
};

export default useStyles;
