import React, { useState } from 'react';
import { Box } from '@mui/material';

import Tab from './Tab';
import { TABS } from './constanrs';
import useStyles from './useStyles';

const Tabs = () => {
  const styles = useStyles();

  const [activeTab, setActiveTab] = useState(TABS[0].label);

  return (
    <>
      <Box sx={styles.root}>
        {TABS.map(({ label }) => (
          <Tab key={label} label={label} onClick={() => setActiveTab(label)} active={activeTab === label} />
        ))}
      </Box>
      <Box sx={styles.content}>
        {TABS.filter((tab) => tab.label === activeTab).map(({ label, content }) => (
          <Box key={label} sx={styles.contentWrapper}>
            {content}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Tabs;
