import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';

import { DEFAULT_ROLE_LABEL_COLOR } from 'constants/roles';
import { StateSchema, ThunkConfig } from 'store';
import { IRolePermissions, IRoles, IRolesList } from 'models/roles';
import { roleService } from 'services/RoleService';
import { ErrorData } from 'models/fields';

import { modalActions } from './modal';

export const loadRolePermissions = createAsyncThunk<IRolePermissions, void, ThunkConfig<string>>(
  'roles/loadRolePermissions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await roleService.getRolePermissions();

      return response;
    } catch (e) {
      return rejectWithValue('Cannot load auth data');
    }
  },
);

export const loadRoles = createAsyncThunk<
  { roles: IRoles[]; permissions: IRolePermissions },
  void,
  ThunkConfig<string>
>('roles/loadRoles', async (_, { rejectWithValue }) => {
  try {
    const promises = [roleService.getRoles(), roleService.getRolePermissions()];
    const [data, permissions] = await Promise.all([...promises]);
    const roles = (data as { roles: IRoles[] }).roles;

    return { roles, permissions: permissions as IRolePermissions };
  } catch {
    return rejectWithValue('Error');
  }
});

export const checkSingleRoles = createAsyncThunk<IRoles[], void, ThunkConfig<string>>(
  'roles/checkSingleRoles',
  async (_, { rejectWithValue }) => {
    try {
      const data = await roleService.getRoles();

      return data.roles;
    } catch {
      return rejectWithValue('Error');
    }
  },
);

export const createOrUpdateRoles = createAsyncThunk<void, IRoles, ThunkConfig<string>>(
  'roles/createOrUpdateRoles',
  async (data: IRoles, { rejectWithValue, dispatch }) => {
    dispatch(modalActions.setModalLoading(true));
    try {
      const roleId = data?.id;
      if (roleId) {
        const modifiedItem = await roleService.updateRoles(data);
        dispatch(rolesActions.updateItemInRolesList(modifiedItem.role));
      } else {
        const createdItem = await roleService.createRoles(data);
        dispatch(rolesActions.addItemToRolesList(createdItem.role));
      }
      dispatch(modalActions.setModalLoading(false));
    } catch (ex) {
      dispatch(modalActions.setModalLoading(false));
      if (isAxiosError(ex)) {
        const { data } = ex.response ?? {};
        if (data?.errors) {
          return rejectWithValue(data?.errors);
        }
      }

      return rejectWithValue('Error');
    }
  },
);

export const removeRoles = createAsyncThunk<void, IRoles, ThunkConfig<string>>(
  'roles/removeRoles',
  async (data: IRoles, { rejectWithValue, dispatch }) => {
    try {
      const roleId = data?.id;

      if (!roleId) return;
      dispatch(rolesActions.removeItemInRolesList(data));
      await roleService.removeRoles(roleId, data.agencyId!);
      //const modifiedItem = (await updateBenchAPI(benchId, data)).bench;
    } catch (ex) {
      return rejectWithValue('Error');
    }
  },
);

export interface RolesSchema {
  permissions: IRolePermissions;
  list: IRolesList;
  data?: Partial<IRoles>;
  loading: boolean;
  inited: boolean;
  error?: string | ErrorData[];
}

const initialState: RolesSchema = {
  permissions: {},
  list: {
    items: [],
    cursor: undefined,
  },
  data: {
    color: DEFAULT_ROLE_LABEL_COLOR,
    permissions: [],
  },
  loading: false,
  inited: false,
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoleData: (state: RolesSchema, action: PayloadAction<IRoles>) => {
      state.data = action.payload;
    },
    addItemToRolesList: (state: RolesSchema, action: PayloadAction<IRoles>) => {
      state.list.items.push({ ...action.payload });
    },
    updateItemInRolesList: (state: RolesSchema, action: PayloadAction<IRoles>) => {
      const itemIndex = state.list.items.findIndex((x) => x.id === action.payload.id);

      ~itemIndex && (state.list.items[itemIndex] = action.payload);
    },
    removeItemInRolesList: (state: RolesSchema, action: PayloadAction<IRoles>) => {
      const list = [...state.list.items];
      const itemIndex = list.findIndex((x) => x.id === action.payload.id);

      ~itemIndex && list.splice(itemIndex, 1);
      state.list.items = list;
    },
    resetRoleData: (state: RolesSchema) => {
      state.data = initialState.data;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadRolePermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
    builder.addCase(loadRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.list.items = action.payload.roles;
      state.permissions = action.payload.permissions;

      !state.inited && (state.inited = true);
    });
    builder.addCase(loadRoles.rejected, (state) => {
      state.loading = false;
      !state.inited && (state.inited = true);
    });
    builder.addCase(createOrUpdateRoles.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(checkSingleRoles.fulfilled, (state, action) => {
      state.list.items = action.payload;
    });
  },
});

export const getRolePermissions = (state: StateSchema) => state.roles.permissions;
export const getRolesData = (state: StateSchema) => state.roles.data;
export const getRolesList = (state: StateSchema) => state.roles.list;
export const getRolesLoading = (state: StateSchema) => state.roles.loading;
export const getRolesInited = (state: StateSchema) => state.roles.inited;
export const getRolesErrors = (state: StateSchema) => state.roles.error;

export const { actions: rolesActions } = rolesSlice;
export const { reducer: rolesReducer } = rolesSlice;
