import { Box, Skeleton } from '@mui/material';

import useStyles from './useStyles';

const SkeletonMedia = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.media(false)}>
        <Skeleton animation="wave" height="100%" width="100%" variant="rectangular" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Skeleton animation="wave" height={22} width={50} sx={{ marginBottom: '10px', fontSize: '1.4rem' }} />
        <Skeleton animation="wave" height={19} width="90%" sx={{ marginBottom: '4px', fontSize: '1rem' }} />
        <Skeleton animation="wave" height={14} width="45%" sx={{ fontSize: '0.8rem' }} />
      </Box>
    </Box>
  );
};

export default SkeletonMedia;
