export enum GACategory {
  Platform = 'Platform',
  OF = 'OF',
}

export enum GAAction {
  SuccessPayment = 'Success_Payment',
  AddRole = 'Add_Role',
  ConnectCreator = 'Connect_Creator',
  AddFunds = 'Add_Funds',
  MoveToOF = 'Move_To_OF',
  MoveToPlatform = 'Move_To_Platform',
  LogOut = 'Log_Out',
  AddToAgency = 'Add_To_Agency',
  SignUp = 'Sign_Up',
  SignIn = 'Sign_In',
}

export type GAEventValue = Record<string, string>;
