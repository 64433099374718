import { getScrapingMetadataApi } from '../config/api';
import { IScrapinMetadata } from '../models/scraping';

import { BaseService } from './BaseService';

class ScrapingMetadataService extends BaseService {
  public async updateMetadata(creatorId: string, chatterId: string, data: IScrapinMetadata): Promise<IScrapinMetadata> {
    return this.patch(getScrapingMetadataApi(creatorId, chatterId), data);
  }
}

export const scrapingMetadataService = new ScrapingMetadataService();
