import { memo, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { Skeleton } from '@mui/material';

import { StatisticFilters } from 'models/statistics';
import { useIsVisible } from 'hooks/useIsVisible';
import useHeatmapStatistics from 'components/Statistics/hooks/useHeatmapStatistics';

import initialSettings, { colors } from './settings';

interface HeatmapChartProps {
  name: string;
  filters: StatisticFilters;
}

const HeatmapChart = ({ name, filters }: HeatmapChartProps) => {
  const [settings, setSettings] = useState(initialSettings);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersectedOnce, resetIntersectedOnce } = useIsVisible(containerRef);
  const { getConvertedHeatmapSettings } = useHeatmapStatistics();

  useEffect(() => {
    (async () => {
      if (!isIntersectedOnce) return;
      const convertedSettings = await getConvertedHeatmapSettings(filters, initialSettings, colors);
      setSettings(convertedSettings);
      setLoading(false);
    })();
  }, [name, isIntersectedOnce]);

  useEffect(() => {
    setLoading(true);
    resetIntersectedOnce();
  }, [filters]);

  return (
    <div ref={containerRef}>
      {loading ? (
        <Skeleton height={400} />
      ) : (
        <Chart options={settings.options} series={settings.series} height={400} type="heatmap" />
      )}
    </div>
  );
};

export default memo(HeatmapChart);
