const PersonalCard = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8333 25.375H8.16658C3.02159 25.375 1.45825 23.8117 1.45825 18.6667V9.33333C1.45825 4.18833 3.02159 2.625 8.16658 2.625H19.8333C24.9783 2.625 26.5416 4.18833 26.5416 9.33333V18.6667C26.5416 23.8117 24.9783 25.375 19.8333 25.375ZM8.16658 4.375C3.98992 4.375 3.20825 5.16833 3.20825 9.33333V18.6667C3.20825 22.8317 3.98992 23.625 8.16658 23.625H19.8333C24.0099 23.625 24.7916 22.8317 24.7916 18.6667V9.33333C24.7916 5.16833 24.0099 4.375 19.8333 4.375H8.16658Z"
        fill="currentColor"
      />
      <path
        d="M22.1666 10.2083H16.3333C15.8549 10.2083 15.4583 9.81166 15.4583 9.33333C15.4583 8.85499 15.8549 8.45833 16.3333 8.45833H22.1666C22.6449 8.45833 23.0416 8.85499 23.0416 9.33333C23.0416 9.81166 22.6449 10.2083 22.1666 10.2083Z"
        fill="currentColor"
      />
      <path
        d="M22.1667 14.875H17.5C17.0217 14.875 16.625 14.4783 16.625 14C16.625 13.5217 17.0217 13.125 17.5 13.125H22.1667C22.645 13.125 23.0417 13.5217 23.0417 14C23.0417 14.4783 22.645 14.875 22.1667 14.875Z"
        fill="currentColor"
      />
      <path
        d="M22.1666 19.5417H19.8333C19.3549 19.5417 18.9583 19.145 18.9583 18.6667C18.9583 18.1883 19.3549 17.7917 19.8333 17.7917H22.1666C22.6449 17.7917 23.0416 18.1883 23.0416 18.6667C23.0416 19.145 22.6449 19.5417 22.1666 19.5417Z"
        fill="currentColor"
      />
      <path
        d="M9.9166 14.0467C8.2716 14.0467 6.92993 12.705 6.92993 11.06C6.92993 9.415 8.2716 8.07333 9.9166 8.07333C11.5616 8.07333 12.9033 9.415 12.9033 11.06C12.9033 12.705 11.5616 14.0467 9.9166 14.0467ZM9.9166 9.82333C9.23993 9.82333 8.67993 10.3833 8.67993 11.06C8.67993 11.7367 9.23993 12.2967 9.9166 12.2967C10.5933 12.2967 11.1533 11.7367 11.1533 11.06C11.1533 10.3833 10.5933 9.82333 9.9166 9.82333Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 19.9267C13.5566 19.9267 13.1716 19.5883 13.1249 19.1333C12.9966 17.8733 11.9816 16.8583 10.7099 16.7417C10.1732 16.695 9.63656 16.695 9.09989 16.7417C7.82822 16.8583 6.81322 17.8617 6.68489 19.1333C6.63822 19.6117 6.20656 19.9733 5.72822 19.915C5.24989 19.8683 4.89989 19.4367 4.94656 18.9583C5.15656 16.8583 6.82489 15.19 8.93656 15.0033C9.57822 14.945 10.2316 14.945 10.8732 15.0033C12.9732 15.2017 14.6532 16.87 14.8632 18.9583C14.9099 19.4367 14.5599 19.8683 14.0816 19.915C14.0582 19.9267 14.0232 19.9267 13.9999 19.9267Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PersonalCard;
