import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { startOfDay, endOfDay, startOfWeek, subDays, startOfMonth, isSameDay } from 'date-fns';

import { CreatorsSessionStatus } from 'constants/creators';
import { TimeRangeLabel } from 'constants/statistics';
import { classNames } from 'utils/classNames';
import Dropdown from 'components/fields/Dropdown/Dropdown';
import { ChipArea, ChipAreaType } from 'components/fields/ChipArea/ChipArea';
import { convertToDropdownItem } from 'utils/convert';
import { useAppDispatch } from 'store';
import DateRangePicker from 'components/fields/DateRangePicker/DateRangePicker';
import { StatisticFilters } from 'models/statistics';
import { IDropdownOption } from 'models/fields';
import useSubscriptions from 'hooks/useSubscriptions';

import { SubscriptionStatus } from '../../models/creators';
import { loadSubscriptions } from '../../store/reducers/billing';
import { useChatterCharms } from '../../providers/ChatterCharmsProvider';

import { MonthlyEstimate } from './MonthlyEstimate/MonthlyEstimate';
import { TopFansTable } from './TopFansTable/TopFansTable';
import { EarningsStats } from './EarningsStats/EarningsStats';
import { RedirectNotification } from './RedirectNotification/RedirectNotification';
import Chart, { ChartType } from './Chart/Chart';
import MainStatistics from './MainStatistics/MainStatistics';
import styles from './Statistics.module.scss';

interface StatisticsProps {
  className?: string;
}

const ALL_ACCOUNTS = 'All Accounts';

const timeRangeOptions = [
  {
    label: TimeRangeLabel.TODAY,
    value: startOfDay(new Date()).toISOString(),
  },
  {
    label: TimeRangeLabel.YESTERDAY,
    value: startOfDay(subDays(new Date(), 1)).toISOString(),
  },
  {
    label: TimeRangeLabel.THIS_WEEK,
    value: startOfWeek(new Date()).toISOString(),
  },
  {
    label: TimeRangeLabel.THIS_MONTH,
    value: startOfMonth(new Date()).toISOString(),
  },
];

export interface IDateUTCRange {
  from: string;
  to: string;
}

const enum EarningType {
  NET = 'Net',
  GROSS = 'Gross',
}

const Statistics = ({ className }: StatisticsProps) => {
  const dispatch = useAppDispatch();

  const { creators: creatorsList } = useChatterCharms();
  const { subscriptions } = useSubscriptions();

  const todayUTC = new Date().toISOString();

  const creatorsDropdownOptions = useMemo(
    () => [
      ALL_ACCOUNTS,
      ...creatorsList
        .filter(({ sessionStatus }) => sessionStatus !== CreatorsSessionStatus.NOT_VERIFIED)
        .map((item) => item.id as string),
    ],
    [creatorsList],
  );
  const earningTypeDropdownOptions = useMemo(() => [EarningType.NET, EarningType.GROSS], []);
  const [selectedCreators, setSelectedCreators] = useState<string[]>(creatorsDropdownOptions);
  const [earningType, setEarningType] = useState<string>(EarningType.NET);
  const [range, setRange] = useState<IDateUTCRange | undefined>({
    from: timeRangeOptions[0].value,
    to: todayUTC,
  });
  const [activeTimeRangeLabel, setActiveTimeRangeLabel] = useState<TimeRangeLabel | undefined>(TimeRangeLabel.TODAY);

  const [customRange, setCustomRange] = useState<DateRange>();

  useEffect(() => {
    dispatch(loadSubscriptions());
  }, []);

  const hasUnpaidSubscriptions = useMemo(() => {
    const unpaidList = subscriptions.filter((item) => [SubscriptionStatus.UNPAID].includes(item.status));

    return unpaidList.length > 0;
  }, [subscriptions]);

  const filters: StatisticFilters = useMemo(() => {
    return {
      creatorIds: selectedCreators.filter((item) => item !== ALL_ACCOUNTS),
      from: range?.from || startOfDay(customRange?.from as Date).toISOString(),
      to: range?.to || endOfDay(customRange?.to as Date).toISOString(),
      revenue: earningType.toLowerCase(),
    };
  }, [selectedCreators, earningType, range, customRange]);

  const onChangeAccounts = useCallback(
    (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      const value = event.target.value as string[];
      const childValue = (child as ReactElement<{ value: string }, string>).props.value;

      const isAllListItemClicked =
        childValue === ALL_ACCOUNTS || !value.length || (value.length === 1 && value.includes(ALL_ACCOUNTS));

      if (isAllListItemClicked) {
        setSelectedCreators([...creatorsDropdownOptions]);
      } else {
        const updatedValue = [...value].filter((item) => item !== ALL_ACCOUNTS);
        const isAllSelected = updatedValue.length === creatorsDropdownOptions.length - 1;
        isAllSelected && updatedValue.push(ALL_ACCOUNTS);
        setSelectedCreators(updatedValue);
      }
    },
    [creatorsDropdownOptions],
  );

  const onChangeEarningType = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setEarningType(value);
  }, []);

  const onChangeRange = useCallback((item: IDropdownOption[] | IDropdownOption) => {
    setRange({ from: (item as IDropdownOption).value, to: todayUTC });
    setActiveTimeRangeLabel((item as IDropdownOption).label as TimeRangeLabel);
    setCustomRange(undefined);
  }, []);

  const onChangeCustomRange: SelectRangeEventHandler = useCallback(
    (range, day, modifires) => {
      const isSameRangeDay =
        customRange?.from && customRange?.to ? isSameDay(customRange?.from, customRange?.to) : true;

      if (!isSameRangeDay) {
        setCustomRange({ from: day, to: day });
        return;
      }

      setCustomRange({
        from: range?.from,
        to: range?.to || range?.from,
      });

      if (range) {
        setRange(undefined);
        setActiveTimeRangeLabel(undefined);
      } else {
        setRange({ from: timeRangeOptions[0].value, to: todayUTC });
        setActiveTimeRangeLabel(TimeRangeLabel.TODAY);
      }
    },
    [customRange],
  );

  const onRenderAccountItem = useCallback(
    (selectedId: string) => {
      if (selectedId === ALL_ACCOUNTS) {
        return (
          <div className={styles.accountItem}>
            <Checkbox checked={selectedCreators.indexOf(selectedId) > -1} />
            <div>
              <div className={styles.name}>{ALL_ACCOUNTS}</div>
              <div className={styles.ofNick}>{`Select all creators`}</div>
            </div>
          </div>
        );
      }

      const item = creatorsList.find(({ id }) => id === selectedId);
      if (item) {
        return (
          <div className={styles.accountItem}>
            <Checkbox checked={selectedCreators.indexOf(selectedId) > -1} />
            <div className={styles.avatar}>{item.imageLink && <img src={item.imageLink} alt="Avatar Account" />}</div>
            <div>
              <div className={styles.name}>{item.name}</div>
              <div className={styles.ofNick}>{`@${item.ofNick}`}</div>
            </div>
          </div>
        );
      }

      return <></>;
    },
    [creatorsList, selectedCreators],
  );

  const onRenderAccountPlaceholder = useCallback(
    (selected: unknown) => {
      if ((selected as string[]).find((item) => item === ALL_ACCOUNTS)) return <div>{ALL_ACCOUNTS}</div>;
      const placeholder = creatorsList
        .filter((item) => (selected as string[]).includes(item.id || ''))
        .map((item) => item.name);

      return <div>{placeholder.slice(0, 3).join(', ')}</div>;
    },
    [creatorsList],
  );

  return (
    <div className={classNames(styles.statistics, {}, [className])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Account Statistics</h1>
      </div>
      <div className={styles.filters}>
        <Dropdown
          fieldName="Account"
          value={selectedCreators}
          options={creatorsDropdownOptions}
          onChange={onChangeAccounts}
          multiple
          className={styles.filterInput}
          renderValue={onRenderAccountPlaceholder}
          onRenderItem={onRenderAccountItem}
        />
        <Dropdown
          fieldName="Earning Type"
          value={earningType}
          options={earningTypeDropdownOptions}
          onChange={onChangeEarningType}
          className={styles.filterInput}
        />
        <ChipArea
          fieldName="Time Range"
          value={convertToDropdownItem(range?.from, activeTimeRangeLabel)}
          onChange={onChangeRange}
          type={ChipAreaType.CLEAR}
          options={timeRangeOptions}
          containerClassName={styles.filterInput}
          className={styles.chipField}
        />

        <DateRangePicker
          selected={customRange}
          onSelect={onChangeCustomRange}
          fieldName="Custom"
          className={styles.filterInput}
          toDate={new Date()}
          defaultMonth={customRange?.from && new Date(customRange?.from)}
        />
      </div>
      {hasUnpaidSubscriptions ? <RedirectNotification /> : null}
      <MainStatistics filters={filters} />
      <Chart commonFilters={filters} type={ChartType.EARNINGS} title="Earnings" needWaterMark />
      <MonthlyEstimate filters={filters} />
      <div className={styles.row}>
        <Chart
          className={styles.earningBreakdown}
          commonFilters={filters}
          type={ChartType.EARNINGS_BREAKDOWN}
          title="Earnings Breakdown"
          withoutFilterContentType
        />
        <EarningsStats filters={filters} className={styles.earningStats} />
      </div>
      <div className={styles.row}>
        <Chart className={styles.bestDay} commonFilters={filters} type={ChartType.BEST_DAY} title="Best Day" halfView />
        <Chart
          className={styles.bestHour}
          commonFilters={filters}
          type={ChartType.BEST_HOUR}
          title="Best Hour"
          halfView
        />
      </div>

      <Chart commonFilters={filters} type={ChartType.DAY_HOUR_EARNINGS_HEATMAP} title="Day/Hour Earnings Heatmap" />
      <TopFansTable filters={filters} />
    </div>
  );
};

export default Statistics;
