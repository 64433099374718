import { useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';

import { TopFansColumns } from 'constants/statistics';
import { classNames } from 'utils/classNames';
import { ITopFanUser, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { IColumn } from 'models/table';
import { useIsVisible } from 'hooks/useIsVisible';
import { formatNumberWithPrecision } from 'utils/convert';

import styles from './TopFansTable.module.scss';

interface TopFansTableProps {
  filters: StatisticFilters;
  className?: string;
}

const columns: IColumn[] = [
  {
    id: TopFansColumns.No,
    fieldName: 'No',
    label: 'No.',
    width: 150,
  },
  {
    id: TopFansColumns.NAME,
    fieldName: 'name',
    label: 'Name',
    width: 150,
  },
  {
    id: TopFansColumns.USERNAME,
    fieldName: 'username',
    label: 'Username',
    width: 150,
  },
  {
    id: TopFansColumns.LAST_PURCHASE,
    fieldName: 'lastPurchase',
    label: 'Last Purchase',
    width: 150,
  },
  {
    id: TopFansColumns.AVG_SPENT,
    fieldName: 'avgSpent',
    label: 'Avg. Spent',
    width: 150,
  },
  {
    id: TopFansColumns.TOTAL_SPENT,
    fieldName: 'totalSpent',
    label: 'Total Spent',
    width: 150,
  },
];

export const TopFansTable = ({ filters, className }: TopFansTableProps) => {
  const [data, setData] = useState<ITopFanUser[]>([]);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersectedOnce, resetIntersectedOnce } = useIsVisible(containerRef);

  const getAsyncAndConvertChartData = async () => {
    try {
      const response = await statisticsService.getTopSpendingFans(filters);
      setData(response?.users);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      if (!isIntersectedOnce) return;
      await getAsyncAndConvertChartData();
      setLoading(false);
    })();
  }, [isIntersectedOnce]);

  useEffect(() => {
    setLoading(true);
    resetIntersectedOnce();
  }, [filters]);

  const onRenderColumnItem = useCallback((item: ITopFanUser, index: number, column: IColumn) => {
    const fieldName = column?.fieldName;

    switch (column?.id) {
      case TopFansColumns.No: {
        return <p>{index + 1}</p>;
      }
      case TopFansColumns.USERNAME: {
        return <span className={styles.username}>{item.username}</span>;
      }
      case TopFansColumns.AVG_SPENT: {
        return <p className={styles.avgSpent}>$ {item.avgSpent}</p>;
      }
      case TopFansColumns.TOTAL_SPENT: {
        return <span className={styles.totalSpent}>$ {formatNumberWithPrecision(item.totalSpent || 0)}</span>;
      }
      case TopFansColumns.LAST_PURCHASE: {
        return <p>{format(new Date(item.lastPurchase), 'yyyy-MM-dd')}</p>;
      }
      default: {
        if (fieldName && item) {
          return <p>{(item as any)[fieldName]}</p>;
        }
        return <></>;
      }
    }
  }, []);

  return (
    <div ref={containerRef} className={classNames(styles.container, {}, [className])}>
      <div className={styles.header}>
        <h2>
          Top <span>{data.length}</span> Spending Fans
        </h2>
      </div>
      <div className={styles.table}>
        <CustomizedTable
          items={data}
          columns={columns}
          onRenderColumnItem={onRenderColumnItem}
          className={styles.table}
          loading={loading}
        />
      </div>
    </div>
  );
};
