/* eslint-disable react/no-unknown-property */
import React, { forwardRef, useEffect, useState, useRef, useImperativeHandle, memo } from 'react';

import { USER_AGENT } from '../../constants';

import { IWebviewRef, IWebviewProps } from './interfaces';

const Webview = forwardRef<IWebviewRef, IWebviewProps>(({ src, onReady = null, ...props }, ref) => {
  const innerRef = useRef<any | null>(null);

  const [isReady, setIsReady] = useState(false);

  useImperativeHandle(ref, () => ({
    isReady,
    executeJavaScript: (...rest: any) => (isReady ? innerRef.current?.executeJavaScript(...rest) : null),
    addEventListener: (...rest: any) => (isReady ? innerRef.current?.addEventListener(...rest) : null),
    removeEventListener: (...rest: any) => (isReady ? innerRef.current?.removeEventListener(...rest) : null),
    insertCSS: (...rest: any) => (isReady ? innerRef.current?.insertCSS(...rest) : null),
  }));

  useEffect(() => {
    setIsReady(false);
  }, [src]);

  useEffect(() => {
    if (onReady) {
      onReady(isReady);
    }
  }, [isReady, onReady]);

  useEffect(() => {
    if (!innerRef.current) {
      return;
    }

    const ready = () => {
      setIsReady(true);

      const id = innerRef.current?.getWebContentsId();
      if (id) {
        window.electronAPI.openWindow(id);
      }

      //innerRef.current?.openDevTools();
    };

    innerRef.current.addEventListener('dom-ready', ready);

    return () => {
      if (innerRef?.current) {
        innerRef.current.removeEventListener('dom-ready', ready);
      }
    };
  }, [innerRef.current]);

  return (
    <webview
      key={src}
      ref={innerRef}
      src={src}
      {...props}
      useragent={USER_AGENT}
      style={{ position: 'absolute', width: '100%', height: '100%' }}
    />
  );
});

export default memo(Webview);
