import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { ISelectedAIPlanData } from 'models/billing';
import { ApiErrorCode, getErrorMessage } from 'constants/messages';
import { billingService } from 'services/BillingService';
import { useAppDispatch, useAppSelector } from 'store';
import { getBillingAIBalance, loadAIBalance, loadCurrentBalance } from 'store/reducers/billing';

const useAISubscription = (plan?: ISelectedAIPlanData) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const balance = useAppSelector(getBillingAIBalance);
  const dispatch = useAppDispatch();

  const getPlanTotals = useCallback(() => {
    const { discount, regular } = plan?.info?.price || {};
    const total = discount || regular || 0;
    const saved = (regular || 0) - total;
    return [total, saved];
  }, [plan]);

  const createPaymentAISubscription = async () => {
    if (!plan || paymentLoading) return;

    const [totalWithDiscount] = getPlanTotals();

    if (totalWithDiscount > +balance.balanceAI || +balance.balanceAI === 0) {
      enqueueSnackbar(getErrorMessage(ApiErrorCode.INSUFFICIENT_BALANCE_ERROR), {
        variant: 'error',
      });
      return;
    }

    setPaymentLoading(true);
    try {
      await billingService.createPaymentAISubscriptions(plan.name);
      dispatch(loadCurrentBalance());
      dispatch(loadAIBalance());
      enqueueSnackbar('Payment Successful', { variant: 'success' });
    } catch (e) {}
    setPaymentLoading(false);
  };

  return {
    paymentLoading,
    getPlanTotals,
    createPaymentAISubscription,
  };
};

export default useAISubscription;
