import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import { subMonths } from 'date-fns';

import { classNames } from 'utils/classNames';
import { IRunRate, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { useIsVisible } from 'hooks/useIsVisible';
import { formatNumberWithPrecision } from 'utils/convert';

import styles from './MonthlyEstimate.module.scss';

interface MonthlyEstimateProps {
  filters: StatisticFilters;
  className?: string;
}

export const MonthlyEstimate = ({ filters, className }: MonthlyEstimateProps) => {
  const [data, setData] = useState<IRunRate>();
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersectedOnce, resetIntersectedOnce } = useIsVisible(containerRef);

  const getAsyncAndConvertChartData = async () => {
    try {
      const { to } = filters;
      const startMonthlyDate = subMonths(new Date(to), 1).toISOString();
      const response = await statisticsService.getCreatorRunRate({ ...filters, from: startMonthlyDate });
      setData(response?.data);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      if (!isIntersectedOnce) return;
      await getAsyncAndConvertChartData();
      setLoading(false);
    })();
  }, [isIntersectedOnce]);

  useEffect(() => {
    setLoading(true);
    resetIntersectedOnce();
  }, [filters]);

  const renderTile = (title: string, value?: string | number) => {
    return (
      <div className={styles.tile}>
        <p className={styles.title}>{title}</p>
        {!loading ? <p className={styles.value}>{value}</p> : <Skeleton variant="rectangular" />}
      </div>
    );
  };

  return (
    <div ref={containerRef} className={classNames(styles.container, {}, [className])}>
      {renderTile('Accounts', formatNumberWithPrecision(data?.accountNumber) || 0)}
      {renderTile(
        'Monthly Estimate based on 7-Day Run Rate',
        `$ ${formatNumberWithPrecision(Number(data?.runRate7)) || 0}`,
      )}
      {renderTile(
        'Monthly Estimate based on 30-Day Run Rate',
        `$ ${formatNumberWithPrecision(Number(data?.runRate30)) || 0}`,
      )}
    </div>
  );
};
