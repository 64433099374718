import React from 'react';
import { Box } from '@mui/material';

import useElectronApplicationUpdate from 'modules/electron/hooks/useElectronApplicationUpdate';
import { WebviewURL } from 'constants/externalLinks';

import { useElectronContext } from '../../providers';
import useMainListener from '../../hooks/useMainListener';
import Webview from '../Webview';
import OFDashboard from '../OFDashboard';

import ElectronWrapper from './ElectronWrapper';
import useStyles from './useStyles';

interface IElectronContainer {
  children: React.ReactNode;
}

const ElectronContainerView = ({ children }: IElectronContainer) => {
  const styles = useStyles();

  const { ofUrl } = useElectronContext();

  useMainListener();
  useElectronApplicationUpdate();

  if (!ofUrl) {
    return (
      <ElectronWrapper>
        <Box sx={styles.content}>{children}</Box>
      </ElectronWrapper>
    );
  }

  return (
    <ElectronWrapper>
      <Box sx={styles.content}>
        {ofUrl !== WebviewURL.OF ? <Webview src={ofUrl} allowpopups="true" /> : <OFDashboard />}
      </Box>
    </ElectronWrapper>
  );
};

export default ElectronContainerView;
