import React, { InputHTMLAttributes, memo, useCallback, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { classNames } from 'utils/classNames';
import { ThemeField } from 'models/fields';

import { INamedFieldProps, NamedField } from '../NamedField/NamedField';

import styles from './Input.module.scss';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'children'>;

interface InputProps extends HTMLInputProps, INamedFieldProps {
  theme?: ThemeField;
  className?: string;
  value?: string | number;
  readonly?: boolean;
  Icon?: React.VFC<React.SVGProps<SVGSVGElement>>;
  onChange?: (value: string, targetName?: string) => void;
  isOptional?: boolean;
}

const Input = memo((props: InputProps) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  const onTogglePasswordShown = useCallback(() => setPasswordHidden((prevState) => !prevState), []);

  const {
    theme = ThemeField.DARK,
    className,
    value,
    onChange,
    type = 'text',
    fieldName,
    mandatory,
    readonly,
    Icon,
    error,
    isOptional,
    ...otherProps
  } = props;

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value, e.target.name);
  };

  const getInputType = useCallback(() => {
    if (type === 'password' && !passwordHidden) {
      return 'text';
    }

    return type;
  }, [type, passwordHidden]);

  const mods = {
    [styles.readonly]: readonly,
    [styles.error]: error,
    [styles.withIcon]: Boolean(Icon),
  };

  return (
    <NamedField
      fieldName={fieldName}
      mandatory={mandatory}
      error={error}
      isOptional={isOptional}
      className={classNames(styles.input, mods, [className, theme])}
    >
      <div className={styles.iconWrapper}>
        {Icon && <Icon className={styles.icon} />}
        <input
          type={getInputType()}
          value={value}
          onChange={onChangeValue}
          readOnly={readonly}
          autoComplete="off"
          {...otherProps}
        />
        {type === 'password' &&
          (passwordHidden ? (
            <VisibilityIcon onClick={onTogglePasswordShown} className={styles.passwordIcon} />
          ) : (
            <VisibilityOffIcon onClick={onTogglePasswordShown} className={styles.passwordIcon} />
          ))}
      </div>
    </NamedField>
  );
});

export default Input;
