import { useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios, { AxiosError, isAxiosError, isCancel } from 'axios';

import { ApiErrorCode, ApiErrorStatusCode, SILENT_ERROR_CODES, getErrorMessage } from 'constants/messages';
import { ModalType } from 'constants/modal';
import $api from 'utils/axios';
import { useAppDispatch } from 'store';
import { logoutApp } from 'store/reducers/auth';
import { AUTHORIZATION_TOKEN, ID_TOKEN, REFRESH_TOKEN } from 'config/api';
import { authService } from 'services/AuthService';
import { modalActions } from 'store/reducers/modal';

import { captureExceptionWithErrorCode } from '../utils/sentry';

import { useInternetConnection } from './useInternetConnection';

const useHandleApiErrors = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { waitForInternetConnection } = useInternetConnection();

  const onRedirect = useCallback((path: string) => {
    dispatch(modalActions.setModalType({ type: ModalType.NONE, config: undefined }));
    // Todo: redirect to frist find path
    navigate(path);
  }, []);

  useLayoutEffect(() => {
    const responseInterceptor = $api.interceptors.response.use(
      (response) => response,
      (error: unknown) => handleError(error, onRedirect),
    );

    return () => {
      $api.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const handleError = async (error: unknown, onRedirect?: (path: string) => void) => {
    if (isCancel(error)) {
      return Promise.reject(error);
    }

    if (isAxiosError(error) && error.code === AxiosError.ERR_NETWORK) {
      const originalRequest = { ...error.config, headers: { ...error.config?.headers } };
      //enqueueSnackbar(getErrorMessage(ApiErrorCode.ERR_NETWORK), { variant: 'error' });
      await waitForInternetConnection();

      return axios(originalRequest);
    }

    if (isAxiosError(error)) {
      const { status, data } = error.response ?? {};
      const originalRequest = { ...error.config, headers: { ...error.config?.headers } };

      const errorCode: ApiErrorCode = data?.errors[0]?.message;
      const errorMessage = getErrorMessage(errorCode);

      // refresh token logic
      if (status === ApiErrorStatusCode.EXPIRED_ACCESS_TOKEN) {
        try {
          const refreshToken = localStorage.getItem(REFRESH_TOKEN) || '';
          const accessToken = localStorage.getItem(AUTHORIZATION_TOKEN) || '';
          const { accessToken: updatedAccessToken, idToken: updatedIdToken } = await authService.refreshToken({
            refreshToken,
            accessToken,
          });

          localStorage.setItem(AUTHORIZATION_TOKEN, updatedAccessToken!);
          localStorage.setItem(ID_TOKEN, updatedIdToken!);

          originalRequest.headers[AUTHORIZATION_TOKEN] = `Bearer ${updatedAccessToken}`;
          originalRequest.headers[ID_TOKEN] = updatedIdToken;

          return axios(originalRequest);
        } catch {
          const config = { [ModalType.SESSION_EXPIRED]: { title: 'Sorry', description: 'Your session has expired' } };
          dispatch(modalActions.setModalType({ type: ModalType.SESSION_EXPIRED, config }));
          return;
        }
      }
      const isSilentError = SILENT_ERROR_CODES.includes(errorCode);
      !isSilentError && enqueueSnackbar(errorMessage, { variant: 'error' });

      if (status === ApiErrorStatusCode.ERROR_PERMISSIONS_ACCESS && errorCode === ApiErrorCode.ROLE_PERMISSION_ERROR) {
        captureExceptionWithErrorCode(error, errorCode ?? error.code);
        dispatch(logoutApp());
      } else {
        captureExceptionWithErrorCode(error, errorCode ?? error.code);
        console.log('err', error);
      }
    } else {
      captureExceptionWithErrorCode(error, 'unexpected');
      console.error('An unexpected error occurred:', error);
    }

    return Promise.reject(error);
  };
};

export default useHandleApiErrors;
