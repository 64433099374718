import { classNames } from 'utils/classNames';
import { IMember } from 'models/members';
import { useChatterCharms } from 'providers/ChatterCharmsProvider';

import styles from '../Members.module.scss';

const RoleCell = ({ item }: { item: IMember }) => {
  const { rolesMap } = useChatterCharms();
  const role = rolesMap?.get(item.roleId);

  if (role) {
    const { color } = role;
    const backgroundColor = color;
    return (
      <span className={classNames(styles.role, {}, [styles.owner])} style={{ backgroundColor }}>
        {role.name}
      </span>
    );
  }

  return <span></span>;
};

export default RoleCell;
