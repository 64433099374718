import { useCallback, useEffect, useState, useRef } from 'react';
import { AxiosRequestConfig } from 'axios';

import useFetch from 'hooks/useFetch';
import { scrappingService } from 'services/ScrapingService';
import { IMedia, MediaStatusEnum } from 'models/media';
import { IGetScrapingInfo, IScrapingMedia, IScrapingPagination } from 'models/scraping';
import { useElectronContext } from 'modules/electron/providers';

import { useWebviewContext } from '../../../../../../../providers';

import useHistoryPolling from './useHistoryPolling';

const LIMIT = 10;

const useHistory = (status: MediaStatusEnum | null = null) => {
  const { activeCreator } = useElectronContext();
  const { activeChatterId } = useWebviewContext();

  const pageRef = useRef(0);
  const requestIsSendRef = useRef(false);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [media, setMedia] = useState<IMedia[]>([]);

  const resetState = useCallback(() => {
    if (!requestIsSendRef.current) {
      //no need to reset on mount
      return;
    }

    pageRef.current = 0;

    setMedia([]);
    setHasNextPage(true);
  }, []);

  const loadMedia = useCallback(
    async (
      { creatorId, chatterId, offset, limit = LIMIT }: IGetScrapingInfo & IScrapingPagination & IScrapingMedia,
      config: AxiosRequestConfig = {},
    ): Promise<IMedia[]> => {
      if (!creatorId || !chatterId) return [];

      const { media } = await scrappingService.getMedia(
        {
          creatorId,
          chatterId,
          offset,
          limit,
          status,
        },
        config,
      );

      return media;
    },
    [status],
  );

  useHistoryPolling({ loadMedia, setMedia, count: media.length });

  useEffect(resetState, [activeChatterId, activeCreator?.id, resetState]);

  useEffect(() => {
    return resetState;
  }, [status]);

  const { data, callLoadData, loading } = useFetch(loadMedia);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.length < LIMIT) {
      setHasNextPage(false);
    }

    if (data.length) {
      setMedia((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  const loadNextPage = useCallback(() => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }

    callLoadData({ creatorId: activeCreator?.id, chatterId: activeChatterId, offset: pageRef.current * LIMIT });

    pageRef.current += 1;
    requestIsSendRef.current = true;
  }, [activeChatterId, activeCreator?.id, callLoadData]);

  useEffect(() => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }

    loadNextPage();
  }, [activeChatterId, activeCreator?.id, loadNextPage]);

  return {
    media,
    loadNextPage,
    hasNextPage,
    isLoading: loading,
  };
};

export default useHistory;
