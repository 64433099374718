import { IColumn } from '../../models/table';
import { MembersColumns } from '../../constants/members';

export const columns: IColumn[] = [
  {
    id: MembersColumns.MEMBER,
    fieldName: 'member',
    label: 'Member',
    width: 150,
  },
  {
    id: MembersColumns.EMAIL,
    fieldName: 'email',
    label: 'Email',
    width: 150,
  },
  {
    id: MembersColumns.ROLE,
    fieldName: 'role',
    label: 'Role',
    width: 300,
  },
  {
    id: MembersColumns.CREATOR_ASSIGNED,
    fieldName: 'creatorsAssigned',
    label: 'Creators Assigned',
    width: 150,
  },
  {
    id: MembersColumns.ACTION,
    fieldName: 'action',
    label: 'Actions',
    width: 150,
  },
];

export const ALL = 'all';
