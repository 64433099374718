import axios from 'axios';

import { INTERNET_CONNECTION } from 'config/api';
import { useAppDispatch } from 'store';
import { authActions } from 'store/reducers/auth';

const CHECK_CONNECTION_TIMEOUT = 3500;

class InternetConnectionChecker {
  public static isConnectedPromise: Promise<boolean> | null = null;

  public waitInternetConnection(onSuccessCallback?: () => void, onFailCallback?: () => void) {
    if (!InternetConnectionChecker.isConnectedPromise) {
      InternetConnectionChecker.isConnectedPromise = new Promise((resolve) => {
        const check = async () => {
          try {
            await axios.get(INTERNET_CONNECTION);
            resolve(true);
            onSuccessCallback?.();
            InternetConnectionChecker.isConnectedPromise = null;
          } catch {
            onFailCallback?.();
            setTimeout(check, CHECK_CONNECTION_TIMEOUT);
          }
        };

        // Start the initial check
        check();
      });
    }

    return InternetConnectionChecker.isConnectedPromise;
  }
}

export const connectionChecker = new InternetConnectionChecker();

export const useInternetConnection = () => {
  const dispatch = useAppDispatch();

  const onSuccessCallback = () => dispatch(authActions.updateIsOnlineStatus(true));
  const onFailCallback = () => dispatch(authActions.updateIsOnlineStatus(false));

  const waitForInternetConnection = () => {
    return connectionChecker.waitInternetConnection(onSuccessCallback, onFailCallback);
  };

  return {
    waitForInternetConnection,
  };
};
