import React from 'react';
import { Box, Button } from '@mui/material';

import { ReactComponent as HomeIcon } from 'assets/images/home.svg';

import { useAppSelector } from '../../../../store';
import { useElectronContext } from '../../providers';
import { getUserAuthData } from '../../../../store/reducers/auth';
import { CreatorsSessionStatus } from '../../../../constants/creators';
import { NOT_SET_PROXY } from '../../../../models/creators';
import { WebviewURL } from '../../../../constants/externalLinks';
import { useChatterCharms } from '../../../../providers/ChatterCharmsProvider';

import useStyles from './useStyles';

const ElectronSidebar = () => {
  const styles = useStyles();

  const isAuth = useAppSelector(getUserAuthData);

  const { creators: creatorsList } = useChatterCharms();

  const { setActiveCreator, setOfUrl, isActivatingModel, backToAdmin, setIsActivatingModel } = useElectronContext();

  return (
    <Box sx={styles.root}>
      <Button sx={styles.home} onClick={backToAdmin}>
        <HomeIcon />
      </Button>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        {isAuth &&
          creatorsList
            ?.filter(({ proxyId, proxy }) => proxyId !== NOT_SET_PROXY && proxy)
            ?.map((creator) => {
              const { id, ofNick, imageLink = null, name, sessionStatus } = creator;

              return (
                <Button
                  sx={styles.button(sessionStatus === CreatorsSessionStatus.ACTIVE)}
                  key={id}
                  onClick={() => {
                    if (sessionStatus !== CreatorsSessionStatus.ACTIVE) {
                      return;
                    }

                    setIsActivatingModel(true);
                    setActiveCreator(creator);
                    //setActiveChatterId(null);
                    setOfUrl(WebviewURL.OF);
                  }}
                  disabled={isActivatingModel}
                >
                  {imageLink ? (
                    <img src={imageLink} alt={name} />
                  ) : (
                    `${ofNick?.charAt(0)}${ofNick?.charAt(1)}`.toUpperCase()
                  )}
                </Button>
              );
            })}
      </Box>
    </Box>
  );
};

export default ElectronSidebar;
