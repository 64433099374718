import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { memo, useCallback, useMemo } from 'react';

import { RolesColumns } from 'constants/roles';
import { ModalType } from 'constants/modal';
import { Button, ThemeButton } from 'components/Button/Button';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { IColumn } from 'models/table';
import { modalActions } from 'store/reducers/modal';
import { classNames } from 'utils/classNames';
import { useAppDispatch, useAppSelector } from 'store';
import { getRolePermissions, getRolesList, getRolesLoading, removeRoles, rolesActions } from 'store/reducers/roles';
import { IRoles } from 'models/roles';
import { getUserAuthData } from 'store/reducers/auth';
import usePermissions from 'hooks/usePermissions';

import { getMembersList } from '../../store/reducers/members';

import styles from './Roles.module.scss';

const columns: IColumn[] = [
  {
    id: RolesColumns.ROLE,
    fieldName: 'name',
    label: 'Role',
    width: 300,
  },
  {
    id: RolesColumns.DESCRIPTION,
    fieldName: 'description',
    label: 'Description',
    width: 150,
  },
  {
    id: RolesColumns.TEAM_MEMBERS,
    fieldName: 'teamMembers',
    label: 'Team Members',
    width: 150,
  },
  {
    id: RolesColumns.PLATFORM_PERMISSION,
    fieldName: 'platformPermission',
    label: 'Platform Permission',
    width: 250,
  },
  {
    id: RolesColumns.OF_PERMISSION,
    fieldName: 'ofPermission',
    label: 'OF Permission',
    width: 250,
  },
  {
    id: RolesColumns.ACTION,
    fieldName: 'action',
    label: 'Actions',
    width: 150,
  },
];

interface RolesTableProps {
  className?: string;
}

const Roles = memo(({ className }: RolesTableProps) => {
  const loading = useAppSelector(getRolesLoading);
  const { items } = useAppSelector(getRolesList);
  const { items: members } = useAppSelector(getMembersList);
  const user = useAppSelector(getUserAuthData);
  const { ofPermissions, permissionsMap, platformPermissions } = useAppSelector(getRolePermissions);
  const { isSuperAdminPermission } = usePermissions();

  const dispatch = useAppDispatch();

  const onClickEditRow = useCallback((item: IRoles) => {
    dispatch(rolesActions.setRoleData(item));
    dispatch(modalActions.setModalType({ type: ModalType.UPDATE_ROLE }));
  }, []);

  const onRenderColumnItem = useCallback(
    (item: IRoles, index: number, column: IColumn) => {
      const fieldName = column?.fieldName;

      switch (column?.id) {
        case RolesColumns.ROLE: {
          const { name, color } = item;
          const backgroundColor = color;
          return (
            <span className={styles.role} style={{ backgroundColor }}>
              {name}
            </span>
          );
        }
        case RolesColumns.DESCRIPTION: {
          return <p className={styles.description}>{item.description}</p>;
        }
        case RolesColumns.TEAM_MEMBERS: {
          return (
            <div className={styles.teamMembers}>
              {members
                .filter((member) => member.roleId === item.id)
                .map(({ id, name }) => (
                  <p key={id}>{name}</p>
                ))}
            </div>
          );
        }
        case RolesColumns.PLATFORM_PERMISSION:
        case RolesColumns.OF_PERMISSION: {
          const config =
            column?.id === RolesColumns.PLATFORM_PERMISSION
              ? Object.values(platformPermissions || {})
              : Object.values(ofPermissions || {});

          const permissions = item.permissions.filter((permItem) => config.includes(permItem));
          const isFullAccess = config.length === permissions.length;

          return (
            <ul className={styles.list}>
              {isFullAccess ? (
                <li>Full Access</li>
              ) : (
                permissions?.map((item, index) => <li key={index}>{permissionsMap?.[item]}</li>)
              )}
            </ul>
          );
        }
        case RolesColumns.ACTION: {
          // Cannot delete or update an assigned role
          if (user?.roleId === item.id && !isSuperAdminPermission) return <></>;

          return (
            <div className={styles.action}>
              <BorderColorIcon onClick={() => onClickEditRow(item)} />
              <DeleteIcon onClick={() => dispatch(removeRoles(item))} />
            </div>
          );
        }
        default: {
          if (fieldName && item) {
            return <p>{(item as any)[fieldName]}</p>;
          }
          return <></>;
        }
      }
    },
    [ofPermissions, permissionsMap, isSuperAdminPermission, members, platformPermissions, user, onClickEditRow],
  );

  const addButton = useMemo(
    () => (
      <Button
        theme={ThemeButton.PRIMARY}
        className={styles.addBtn}
        onClick={() => {
          dispatch(rolesActions.resetRoleData());
          dispatch(modalActions.setModalType({ type: ModalType.ADD_ROLE }));
        }}
      >
        Add New Role
      </Button>
    ),
    [],
  );

  return (
    <div className={classNames(styles.rolesTable, {}, [className])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Roles</h1>
        {items.length > 0 && addButton}
      </div>
      <div className={styles.tableContainer}>
        <CustomizedTable
          items={items}
          columns={columns}
          onRenderColumnItem={onRenderColumnItem}
          className={styles.table}
          loading={loading}
          emptyElement={addButton}
        />
      </div>
    </div>
  );
});

export default Roles;
