import { Props } from 'react-apexcharts';
import { format } from 'date-fns';

import { StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { formatNumberWithPrecision } from 'utils/convert';

const useLineStatistics = () => {
  // const formatYAxis = (value: number, roundedValues: number[]) => {
  //   // Находим ближайшее округленное значение к текущему значению
  //   const roundedValue = roundedValues.reduce((prev, curr) =>
  //     Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev,
  //   );
  //
  //   return `$ ${roundedValue}`;
  // };

  const getConvertedLineSettings = async (filters: StatisticFilters, initialSettings: Props) => {
    try {
      const { data } = await statisticsService.getCreatorEarning(filters);
      const updatedSeries = data?.map((item) => Number(item.sum));
      const updatedCategories = data?.map((item) => format(new Date(item.value), 'yyyy-MM-dd, HH:mm'));
      const commonSum = updatedSeries?.reduce((sum, value) => sum + (value || 0), 0);
      //const roundedValues = [...new Set(updatedSeries.map((sValue) => Math.round(sValue)))];
      // To show line on chart need 2 points
      const isSingeValue = updatedSeries.length === 1;

      if (isSingeValue) {
        updatedSeries.push(updatedSeries[0]);
        updatedCategories.push(updatedCategories[0]);
      }

      const deepUpdatedSettings = JSON.parse(JSON.stringify(initialSettings));

      deepUpdatedSettings.options.xaxis.categories = updatedCategories;
      deepUpdatedSettings.series[0].data = updatedSeries;
      deepUpdatedSettings.options.yaxis.labels.formatter = (val: number): string => {
        return `$ ${Number(val)?.toFixed()}`;
      };

      /*deepUpdatedSettings.options.yaxis.tickAmount = roundedValues.length - 1 > 10 ? 10 : roundedValues.length;*/

      /**/

      deepUpdatedSettings.options.tooltip = {
        marker: {
          show: false,
        },
        y: {
          formatter: (value: number) => `$ ${formatNumberWithPrecision(value)}`,
        },
      };

      (!commonSum || isSingeValue) && (deepUpdatedSettings.options.yaxis.tickAmount = 4);

      return deepUpdatedSettings;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getConvertedLineSettings,
  };
};

export default useLineStatistics;
