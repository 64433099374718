import { IDropdownOption } from 'models/fields';

export const convertToDropdownItem = (value?: string, label?: string) => {
  if (value) {
    return { label: label || value, value } as IDropdownOption;
  }

  return null;
};

export const convertToDropdownArray = (array: string[] | undefined) => {
  if (array && array.length > 0) {
    return array.map((value) => ({ label: value, value }) as IDropdownOption);
  }

  return undefined;
};

export const getObjectKey = (obj: any, value?: string) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const isNumber = (value?: string): value is string => Boolean(value?.trim()) && !isNaN(+value!);

export const validateDate = (date?: Date | null) => {
  if (!date) return undefined;

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return undefined;
    }

    return date;
  }

  return undefined;
};

export function formatNumberToMln(number: number) {
  if (number >= 1000000) {
    const mlnNumber = (number / 1000000).toFixed(2);
    return mlnNumber + ' mln';
  } else {
    return formatNumberWithPrecision(number);
  }
}

export function formatNumberWithPrecision(number?: number) {
  if (number === undefined) return;

  let persitionNumber: number;

  if (Number.isInteger(number)) {
    persitionNumber = number;
  } else {
    persitionNumber = Number((+number || 0).toFixed(2));
  }

  return persitionNumber.toLocaleString('en-US');
}

export const splitArrayToChunks = (array: any[], size: number) => {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};
