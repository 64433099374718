import { Props } from 'react-apexcharts';

import variables from 'assets/styles/variables.module.scss';

export default {
  options: {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        rotate: -90,
        rotateAlways: true,
        style: {
          colors: '#A3ADB1',
        },
      },
      categories: [],
      tickPlacement: 'on',
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#474B56',
      },
    },
    yaxis: {
      forceNiceScale: true,
      min: 0,
      // @ts-ignore
      //stepSize: 50,
      labels: {
        style: {
          colors: '#A3ADB1',
        },
      },
    },
    colors: [variables.accentColor],
    tooltip: {
      marker: {
        show: false,
      },
    },
    grid: {
      borderColor: '#474B56',
    },
  },
  series: [
    {
      name: 'Earnings',
      data: [0],
    },
  ],
} as Props;
