import { Provider } from 'react-redux';
import { DeepPartial } from '@reduxjs/toolkit';

import { StateSchema, createReduxStore } from 'store';

interface StoreProviderProps {
  children?: React.ReactNode;
  initialState?: DeepPartial<StateSchema>;
  //asyncReducers?: DeepPartial<ReducersMapObject<StateSchema>>;  // [TODO]: Async Reducers
}

export const StoreProvider = ({ children, initialState }: StoreProviderProps) => {
  const store = createReduxStore(initialState as StateSchema);

  return <Provider store={store}>{children}</Provider>;
};
