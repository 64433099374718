export enum AccountsTableColumns {
  CREATOR,
  SUBS_PRICE,
  SESSION_STATUS,
  AI_STATUS,
  ACTIONS,
}

export enum CreatorsSessionStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  NOT_VERIFIED = 'NotVerified',
  DATA_SYNC = 'DataSync',
  UNPAID = 'Unpaid',
}

export enum AITrainingStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'Training In Progress',
  AWAITING = 'Awaiting',
}

export interface CreatorsConfig {
  name: string;
  className: string;
}

export const creatorsSessionConfig: Record<CreatorsSessionStatus, CreatorsConfig> = {
  [CreatorsSessionStatus.INACTIVE]: {
    name: 'Inactive',
    className: 'inactive',
  },
  [CreatorsSessionStatus.ACTIVE]: {
    name: 'Active',
    className: 'active',
  },
  [CreatorsSessionStatus.NOT_VERIFIED]: {
    name: 'Not Verified',
    className: 'notVerified',
  },
  [CreatorsSessionStatus.DATA_SYNC]: {
    name: 'Data Sync',
    className: 'dataSync',
  },
  [CreatorsSessionStatus.UNPAID]: {
    name: 'Unpaid',
    className: 'unpaid',
  },
};

export enum CreatorsActions {
  RECONNECT = 'Reconnect',
  OPEN_OF = 'Open OF',
}

export enum ConnectCreatorStep {
  REQUEST = 'REQUEST',
  PROXY = 'PROXY',
  BASIC_INFO = 'BASIC_INFO',
  ABOUT = 'ABOUT',
  SUCCESS = 'SUCCESS',
}
