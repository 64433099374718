import axios, { InternalAxiosRequestConfig } from 'axios';

import { AUTHORIZATION_TOKEN, ID_TOKEN } from 'config/api';

const $api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  timeout: 29000,
});

$api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const accessToken = localStorage.getItem(AUTHORIZATION_TOKEN);
  const idToken = localStorage.getItem(ID_TOKEN);

  if (accessToken && idToken) {
    config.headers[AUTHORIZATION_TOKEN] = `Bearer ${accessToken}`;
    config.headers[ID_TOKEN] = idToken;
  }
  return config;
});

export default $api;
