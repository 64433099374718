import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { yupToFormErrors } from 'formik';

import Input from 'components/fields/Input/Input';
import { Button, ThemeButton } from 'components/Button/Button';
import Select from 'components/fields/Select/Select';
import { TextArea } from 'components/fields/TextArea/TextArea';
import { ICreators, ICreatorsDictionary } from 'models/creators';
import { convertToDropdownArray, convertToDropdownItem } from 'utils/convert';
import { connectCreatorSchema } from 'utils/validators';
import { IValidateFlags } from 'models/validation';
import { ErrorData, IDropdownOption } from 'models/fields';
import { getApiErrorMessageByField } from 'constants/messages';

import styles from './BasicInfoStep.module.scss';

type BasicInfoDataType = Pick<ICreators, 'name' | 'birthDate' | 'country' | 'city' | 'notes' | 'sex'>;

interface BasicInfoStepProps {
  data: BasicInfoDataType;
  dictionary: ICreatorsDictionary;
  apiErrors: ErrorData[];
  onChangeData: (value: string, targetName?: string) => void;
  onNextStep: () => void;
}

export const BasicInfoStep = memo(({ data, dictionary, apiErrors, onChangeData, onNextStep }: BasicInfoStepProps) => {
  const [validateErrors, setValidateErrors] = useState<IValidateFlags<BasicInfoDataType>>({});
  const { name, birthDate, country, city, notes, sex } = data;

  useEffect(() => {
    setValidateErrors({});
  }, [data]);

  const sexOptions = useMemo(() => convertToDropdownArray(dictionary.sex), [dictionary]);
  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        ...convertToDropdownItem(country.name),
        ...country,
      })),
    [],
  );

  const getCityOptionsByCountry = useCallback(
    (country?: ICountry | string) => {
      if (!country) {
        return [];
      }

      let countryCode: string | undefined = '';
      if (typeof country === 'string') {
        countryCode = countryOptions.find((item) => item.name === country)?.isoCode;
      } else {
        countryCode = country.isoCode;
      }

      if (!countryCode) {
        return [];
      }

      const cities = City.getCitiesOfCountry(countryCode);
      const uniqueCities = [...new Map(cities?.map((item) => [item['name'], item])).values()] as ICity[];
      return uniqueCities.map((city) => ({ label: city.name, value: city.name, ...city }));
    },
    [countryOptions],
  );

  const onClickContinue = useCallback(async () => {
    try {
      await connectCreatorSchema.basicInfoStep.validate({ ...data }, { abortEarly: false });
      onNextStep();
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
  }, [data, onNextStep]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Basic Info</h2>
      {/*<AvatarUploader
                name="imageLink"
                avatarUrl={imageLink}
                onChangeAvatarUrl={onChangeData}
                enableEditMode={true}
                group='creator'
                className={styles.avatar}
    />*/}
      <section className={styles.form}>
        <Input
          name="name"
          fieldName="Creator's Name"
          placeholder="Name"
          value={name}
          onChange={onChangeData}
          className={styles.field}
          error={validateErrors.name || getApiErrorMessageByField('name', apiErrors)}
        />
        <Select
          name="sex"
          fieldName="Sex"
          className={styles.field}
          placeholder="Sex"
          value={convertToDropdownItem(sex)}
          options={sexOptions}
          onChange={(value, actionMeta) => {
            const targetName = actionMeta.name;
            const updatedValue = value as IDropdownOption;
            onChangeData(updatedValue.value, targetName!);
          }}
          error={validateErrors.sex || getApiErrorMessageByField('sex', apiErrors)}
        />
        <Input
          type="number"
          name="birthDate"
          fieldName="Age"
          placeholder="Age"
          value={birthDate}
          onChange={onChangeData}
          className={styles.field}
          error={validateErrors.birthDate || getApiErrorMessageByField('birthDate', apiErrors)}
        />
        <Select
          name="country"
          fieldName="Country"
          placeholder="Select or enter country"
          value={convertToDropdownItem(country)}
          options={countryOptions}
          onChange={(value, actionMeta) => {
            const targetName = actionMeta.name;
            const updatedValue = value as ICountry;
            onChangeData(updatedValue.name, targetName!);
          }}
          onInputChange={(newValue, actionMeta) => {
            //const targetName = actionMeta;
            // [TODO]: TargeName
            onChangeData(newValue, 'country');
          }}
          className={styles.field}
          error={validateErrors.country || getApiErrorMessageByField('country', apiErrors)}
        />
        <Select
          name="city"
          fieldName="City"
          placeholder="Select or enter city"
          value={convertToDropdownItem(city)}
          options={getCityOptionsByCountry(country)}
          onChange={(value, actionMeta) => {
            const targetName = actionMeta.name;
            const updatedValue = value as ICity;
            onChangeData(updatedValue.name, targetName!);
          }}
          onInputChange={(newValue, actionMeta) => {
            console.log('actionMeta', actionMeta);
            // [TODO]: TargeName
            //const targetName = actionMeta;
            onChangeData(newValue, 'city');
          }}
          className={styles.field}
          error={validateErrors.city || getApiErrorMessageByField('city', apiErrors)}
        />
        <TextArea
          name="notes"
          placeholder="AI Note"
          fieldName="AI Notes: Fill it with your creator Information"
          value={notes}
          className={styles.notes}
          onChangeValue={onChangeData}
        />
      </section>
      <div className={styles.actions}>
        <Button theme={ThemeButton.PRIMARY} onClick={onClickContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
});
