import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    tag: {
      color: ' #00AFF0',
      padding: '4px 10px',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      borderRadius: '5px',
      background: 'rgba(0, 175, 240, 0.12)',
      display: 'inline-block',
      marginBottom: '14px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '11px',
        marginBottom: '10px',
        padding: '5px 8px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '8px',
        marginBottom: '8px',
        padding: '4px 6px',
      },
    },

    free: {
      color: '#005E0F',
      background: 'rgba(0, 94, 15, 0.12)',
    },

    notpurchased: {
      color: '#C60202',
      background: 'rgba(198, 2, 2, 0.12)',
    },
  };
};

export default useStyles;
