import React, { useEffect } from 'react';

import { useElectronContext } from 'modules/electron/providers';
import { creatorsService } from 'services/CreatorsService';

const useCreatorLogout = ({ ref }: { ref: React.MutableRefObject<any | null> }) => {
  const { activeCreator, deactivateCreatorSession } = useElectronContext();

  useEffect(() => {
    if (!activeCreator || !window.electronAPI) {
      return;
    }

    const eventName = 'logout';

    const cb = async () => {
      if (!activeCreator?.id) {
        return;
      }

      try {
        const { sessionId } = await creatorsService.getCreatorSessionUrl(activeCreator.id);

        if (sessionId) {
          deactivateCreatorSession({ creatorId: activeCreator.id, sessionId, reason: 'LOGOUT_BUTTON' });
        }
      } catch (e) {}
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activeCreator, deactivateCreatorSession]);
};

export default useCreatorLogout;
