import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { CreatorsSessionStatus } from 'constants/creators';
import { WebviewURL } from 'constants/externalLinks';
import { useElectronContext } from 'modules/electron/providers';

import { useDashboardContext } from '../providers';
import { addTab, generateNewTab, getCreatorTabs, removeTab, updateTab } from '../helpers';
import DashboardTabs from '../DashboardTabs';
import OFTab from '../OFTabContainer';
import { ITab, IUpdateTab } from '../interfaces';

import useStyles from './useStyles';

const TABS_COUNT = 7;

const DashboardContainer = () => {
  const styles = useStyles();

  const { activateLoginCreatorId, activateLoginActivated, activeCreator, activeCreatorStatus } = useElectronContext();
  const { isSessionActive } = useDashboardContext();

  const [tabs, setTabs] = useState<ITab[]>([]);
  const [activeTab, setActiveTab] = React.useState<ITab>(tabs?.[0]);

  const creatorId = activateLoginCreatorId ?? activeCreator?.id;

  useEffect(() => {
    const newTab = generateNewTab();
    let creatorsTabs: ITab[] = [newTab];
    if (creatorId) {
      const storedCreatorsTabs = getCreatorTabs(creatorId);

      if (storedCreatorsTabs) {
        creatorsTabs = storedCreatorsTabs;
      } else {
        addTab(creatorId, newTab);
      }
    }

    setTabs(creatorsTabs);
    setActiveTab(creatorsTabs[0]);
  }, [creatorId]);

  const handleAddTab = useCallback(() => {
    const newTab = generateNewTab();

    if (creatorId) {
      addTab(creatorId, newTab);
    }

    setTabs((prevState) => [...prevState, newTab]);
    setActiveTab(newTab);
  }, [creatorId]);

  const handleUpdateTab = useCallback(
    (tabId: string) => (newTab: IUpdateTab) => {
      if (creatorId && tabId) {
        updateTab(creatorId, tabId, newTab);
      }
    },
    [creatorId],
  );

  const handleDeleteTab = useCallback(
    (tabId: string) => {
      if (creatorId) {
        removeTab(creatorId, tabId);
      }

      setTabs((prevState) => {
        if (prevState.length <= 1) {
          return prevState;
        }

        const newState = prevState.filter(({ id }) => id !== tabId);

        if (activeTab.id === tabId) {
          setActiveTab(newState[newState.length - 1]);
        }

        return newState;
      });
    },
    [creatorId, activeTab],
  );

  if (!tabs.length) {
    return null;
  }

  if (!activateLoginCreatorId && !activateLoginActivated && !isSessionActive) {
    return null;
  }

  const isSidebar = !!activeCreator && activeCreatorStatus === CreatorsSessionStatus.ACTIVE;

  return (
    <Box sx={styles.root(isSidebar)}>
      {!activateLoginCreatorId ? (
        <DashboardTabs
          activeTab={activeTab}
          tabs={tabs}
          onAddTab={handleAddTab}
          onDeleteTab={handleDeleteTab}
          onChange={setActiveTab}
          allowAddMore={!activateLoginCreatorId && tabs.length < TABS_COUNT}
        />
      ) : null}

      {(activateLoginCreatorId ? [tabs[0]] : tabs).map(({ id, url }) => (
        <Box sx={{ display: id === activeTab.id ? 'block' : 'none' }} key={id}>
          <OFTab tabId={id} isSidebar={isSidebar} src={`${WebviewURL.OF}${url}`} onTabChange={handleUpdateTab(id)} />
        </Box>
      ))}
    </Box>
  );
};

export default DashboardContainer;
