import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CirclePicker, ColorResult } from 'react-color';
import { yupToFormErrors } from 'formik';
import { Popover } from '@mui/material';

import { ReactComponent as NewRoleIcon } from 'assets/images/new-role.svg';
import { ReactComponent as EditRoleIcon } from 'assets/images/edit-role.svg';
import Input from 'components/fields/Input/Input';
import { Button, ThemeButton } from 'components/Button/Button';
import { IValidateFlags } from 'models/validation';
import { TextArea } from 'components/fields/TextArea/TextArea';
import { IRoles } from 'models/roles';
import { rolesSchema } from 'utils/validators';
import { useAppSelector } from 'store';
import { getApiErrorMessageByField } from 'constants/messages';
import { getRolesErrors } from 'store/reducers/roles';

import styles from './RequestStep.module.scss';

type RequestDataType = Pick<IRoles, 'name' | 'description' | 'color'>;

interface RequestStepProps {
  data: RequestDataType;
  isEdit?: boolean;
  onChangeData: (value: string, targetName?: string) => void;
  onNextStep: () => void;
}

export const RequestStep = memo(({ data, isEdit, onChangeData, onNextStep }: RequestStepProps) => {
  const [loading, setLoading] = useState(false);
  const [palleteElement, setPalleteElement] = useState<Element | null>(null);
  const [validateErrors, setValidateErrors] = useState<IValidateFlags<RequestDataType>>({});
  const apiErrors = useAppSelector(getRolesErrors);

  const isOpenPallete = useMemo(() => Boolean(palleteElement), [palleteElement]);

  const id = isOpenPallete ? 'pallete-popover' : undefined;

  useEffect(() => {
    setValidateErrors({});
  }, [data]);

  const onClickOpenPallete = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPalleteElement(event.currentTarget);
  }, []);

  const onClickClosePallete = useCallback(() => {
    setPalleteElement(null);
  }, []);

  const onClickContinue = useCallback(async () => {
    setLoading(true);

    try {
      await rolesSchema.requestStep.validate({ ...data }, { abortEarly: false });
      onNextStep();
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
    setLoading(false);
  }, [data, onNextStep]);

  const onChangeLabelColor = useCallback(
    (color: ColorResult) => {
      onChangeData(color.hex, 'color');
      setPalleteElement(null);
    },
    [onChangeData],
  );

  return (
    <div className={styles.container}>
      <div className={styles.icon}>{isEdit ? <EditRoleIcon /> : <NewRoleIcon />}</div>
      <h2 className={styles.title}>{isEdit ? 'Edit Role' : 'Add New Role'}</h2>
      <div className={styles.actions}>
        <Input
          name="name"
          fieldName="Name"
          placeholder="Setting & Statistics Analyst"
          value={data.name}
          onChange={onChangeData}
          className={styles.input}
          disabled={loading}
          error={validateErrors.name || getApiErrorMessageByField('name', apiErrors)}
        />
        <TextArea
          name="description"
          fieldName="Description"
          placeholder="Optimazes settings and analyses the statistics"
          value={data.description}
          onChangeValue={onChangeData}
          className={styles.input}
          error={validateErrors.description || getApiErrorMessageByField('description', apiErrors)}
        />
        <Popover
          id={id}
          open={isOpenPallete}
          anchorEl={palleteElement}
          onClose={onClickClosePallete}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <CirclePicker className={styles.pallete} circleSpacing={10} onChange={onChangeLabelColor} />
        </Popover>

        <div className={styles.colorChanger}>
          <span>Label Color </span>
          <button
            aria-describedby={id}
            onClick={onClickOpenPallete}
            className={styles.activePalleteColor}
            style={{ backgroundColor: data.color }}
          />
        </div>
        <div className={styles.nextBtn}>
          <Button theme={ThemeButton.PRIMARY} onClick={onClickContinue} loading={loading}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
});
