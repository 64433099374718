import Chart from 'react-apexcharts';
import { memo, useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import { IEarnings, IEarningsChargebacks, StatisticFilters } from 'models/statistics';
import { classNames } from 'utils/classNames';
import { useIsVisible } from 'hooks/useIsVisible';
import usePieStatistics from 'components/Statistics/hooks/usePieStatistics';
import { formatNumberWithPrecision } from 'utils/convert';

import styles from './PieChart.module.scss';
import initialSettings from './settings';

interface PieChartProps {
  filters: StatisticFilters;
}

const EmptyPieChart = () => {
  return (
    <div className={styles.emptyPie}>
      <div className={styles.innerPiece}>No data to build a pie chart</div>
    </div>
  );
};

const PieChart = ({ filters }: PieChartProps) => {
  const [settings, setSettings] = useState(initialSettings);
  const [data, setData] = useState<IEarnings[]>();
  const [chargebacks, setChargebacks] = useState<IEarningsChargebacks>();
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersectedOnce, resetIntersectedOnce } = useIsVisible(containerRef);
  const { getConvertedPieSettings, areAllValuesZero } = usePieStatistics();

  useEffect(() => {
    (async () => {
      if (!isIntersectedOnce) return;
      const result = await getConvertedPieSettings(filters, initialSettings);
      const { deepUpdatedSettings, data, chargebacks } = result || {};
      setSettings(deepUpdatedSettings);
      setData(data);
      setChargebacks(chargebacks);
      setLoading(false);
    })();
  }, [isIntersectedOnce]);

  useEffect(() => {
    setLoading(true);
    resetIntersectedOnce();
  }, [filters]);

  return (
    <div ref={containerRef} className={styles.container}>
      {loading ? (
        <Skeleton width="100%" height={400} />
      ) : (
        <>
          <div className={styles.chart}>
            {areAllValuesZero(data) ? (
              <EmptyPieChart />
            ) : (
              <Chart options={settings.options} series={settings.series} type="donut" />
            )}
          </div>

          <div className={styles.legend}>
            {data?.map(({ value, sum }, index) => (
              <div key={index} className={styles.item}>
                <p className={styles.title}>{value}</p>
                <div className={styles.stats}>
                  <span className={styles.marker} style={{ backgroundColor: settings.options?.colors?.[index] }} />
                  <span className={styles.earningsMoney}>$ {formatNumberWithPrecision(Math.round(Number(sum)))}</span>
                  {/*<span className={styles.percents}>{percentage}%</span>*/}
                </div>
              </div>
            ))}

            <div className={classNames(styles.item, {}, [styles.total])}>
              <p className={styles.title}>Chargebacks Value:</p>
              <div className={styles.stats}>
                <span className={styles.marker} />
                <span className={styles.earningsMoney}>$ {Math.round(Number(chargebacks?.totalAmount)) || 0}</span>
              </div>
            </div>

            <div className={classNames(styles.item, {}, [styles.total])}>
              <p className={styles.title}>Number of Chargebacks:</p>
              <div className={styles.stats}>
                <span className={styles.marker} />
                <span className={styles.earningsMoney}> {Math.round(Number(chargebacks?.totalCount)) || 0}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(PieChart);
