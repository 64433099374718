import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as AICopilotIcon } from 'assets/images/billing/aiCopilot.svg';

import { AI_COPILOT_NOTIFY } from '../../../constants/billing';

import styles from './AiCopilot.module.scss';

interface AiCopilotProps {
  onClose?: () => void;
}

export const AiCopilot = ({ onClose }: AiCopilotProps) => {
  const handleClose = () => {
    localStorage.setItem(AI_COPILOT_NOTIFY, 'read');
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <AICopilotIcon className={styles.icon} />
        <CloseIcon className={styles.close} onClick={handleClose} />
      </div>
      <div className={styles.body}>
        <h2 className={styles.title}>AI Copilot</h2>
        <p className={styles.text}>6 Months plan provides top priority for AI Copilot development</p>
      </div>
    </div>
  );
};
