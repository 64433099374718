import { useCallback } from 'react';

import { loadAIBalance } from 'store/reducers/billing';
import { aiService } from 'services/AIService';
import { useAppDispatch } from 'store';
import { getErrorMessage } from 'constants/messages';
import { useElectronContext } from 'modules/electron/providers';

import { useWebviewContext } from '../../../providers';
import { IOFWebviewHook } from '../../interfaces';

interface IAIMessageResp {
  message?: string;
  error?: boolean;
  errorMessage?: string;
  balanceAmount?: number | null;
}

const useAIMessages = ({ ref }: IOFWebviewHook) => {
  const dispatch = useAppDispatch();

  const { activeCreator } = useElectronContext();
  const { chatter } = useWebviewContext();

  const iaCopilotSend = useCallback(async () => {
    if (!activeCreator?.id || !chatter?.ofId) {
      return;
    }

    const runScript = (detail: IAIMessageResp) => {
      document.dispatchEvent(
        new CustomEvent(`ai-copilot:message`, {
          bubbles: true,
          cancelable: true,
          detail,
        }),
      );
    };

    let messageDetail: IAIMessageResp = { error: true, message: '' };
    try {
      const { message = '', balanceAmount = null } = await aiService.generateMessage({
        creatorId: activeCreator?.id,
        chatterId: chatter?.ofId,
      });

      if (message) {
        messageDetail = { message: message.trim().replace(/(^"|"$)/g, ''), balanceAmount, error: false };
      }
    } catch (e: any) {
      const errors = e?.response?.data?.errors;
      if (errors && errors[0]?.message) {
        const message = getErrorMessage(errors[0]?.message);

        if (message) {
          messageDetail = { ...messageDetail, errorMessage: message };
        }
      }
    } finally {
      dispatch(loadAIBalance({ isSilently: true }));
    }

    ref.current?.executeJavaScript(`(${runScript})(${JSON.stringify(messageDetail)})`);
  }, [activeCreator?.id, chatter?.ofId]);

  return { iaCopilotSend };
};

export default useAIMessages;
