import { useNavigate } from 'react-router-dom';

import { ReactComponent as HeartIcon } from 'assets/images/heart.svg';
import { Button, ThemeButton } from 'components/Button/Button';
import { RoutePath } from 'config/routeConfig';

import Layout from '../Layout/Layout';

import styles from './ErrorScreen.module.scss';

const ErrorScreen = () => {
  const navigate = useNavigate();

  const onReloadApp = () => {
    navigate(RoutePath.accounts);
    window.location.reload();
  };

  return (
    <Layout>
      <section>
        <div className={styles.icon}>
          <HeartIcon />
        </div>
        <h1>{`Oops something went wrong :(`}</h1>
        <Button theme={ThemeButton.PRIMARY} className={styles.restart} onClick={onReloadApp}>
          Restart
        </Button>
        <p>Please restart the application</p>
        <p>If the problem persists, contact our support</p>
      </section>
    </Layout>
  );
};

export default ErrorScreen;
