import { useCallback } from 'react';

const useVaultStyles = () => {
  const mainStyles = useCallback(() => {
    return `
      .cc-updated:before {
        background: rgba(0, 0, 0, 0.5);
      }
      
      .cc-updated .cc-vault-price {
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 2;
        font-size: 12px;
        font-weight: 600;
        padding: 2px 5px;
        color: #C60202;
        background: #DFC7C7;
        border-radius: 4px;
      }
      
      .l-sidebar-column__scroll-section .cc-updated .cc-vault-price {
        padding: 1px 3px;
        font-size: 8px;
      }
      
      .cc-updated .cc-vault-price.free {
        color: #005E0F;
        background: #DFEAE0;
      }
      
      .cc-updated .cc-vault-price.paid {
        color: #00AFF0;
        background: #DFF4FB;
      } 
    `;
  }, []);

  return { mainStyles };
};

export default useVaultStyles;
