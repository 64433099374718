import React from 'react';
import { Box } from '@mui/material';

import { WebviewProvider, VaultProvider } from '../providers';
import OFSidebar from '../OFSidebar';
import Webview from '../OFWebview';

import { ITabContainer } from './interfaces';
import useStyles from './useStyles';

const OFTabContainer = ({ tabId, src, isSidebar = false, onTabChange }: ITabContainer) => {
  const styles = useStyles();

  return (
    <WebviewProvider onTabChange={onTabChange}>
      <VaultProvider>
        <Box sx={styles.root}>
          <Box sx={styles.webview}>
            <Webview src={src} />
          </Box>
          {isSidebar ? <OFSidebar /> : null}
        </Box>
      </VaultProvider>
    </WebviewProvider>
  );
};

export default OFTabContainer;
