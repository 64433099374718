import { v4 } from 'uuid';

import { ICreatorsTabs, ITab, IUpdateTab } from './interfaces';
import { OF_URL } from './constants';

export const TABS = 'CreatorsTabs';

export const generateNewTab = () => ({
  id: v4(),
  title: 'New Tab',
  url: OF_URL.CHATS,
});

const getStoredTabs = (): ICreatorsTabs | null => {
  const creatorsTabsObject = localStorage.getItem(TABS);
  return creatorsTabsObject ? (JSON.parse(creatorsTabsObject) as ICreatorsTabs) : null;
};

export const getCreatorTabs = (creatorId: string) => {
  const creatorsTabsObject = getStoredTabs();
  return creatorsTabsObject && creatorsTabsObject[creatorId] && creatorsTabsObject[creatorId].length
    ? creatorsTabsObject[creatorId]
    : null;
};

export const setStoredTabs = (creatorId: string, tabs: ITab[]) => {
  const creatorsTabsObject = getStoredTabs();

  localStorage.setItem(
    TABS,
    JSON.stringify({
      ...(creatorsTabsObject ?? {}),
      [creatorId]: tabs,
    }),
  );
};

export const updateTab = (creatorId: string, tabId: string, newTab: IUpdateTab) => {
  const creatorTabs = getCreatorTabs(creatorId) ?? [];

  setStoredTabs(
    creatorId,
    creatorTabs.map((tab) => (tabId !== tab.id ? tab : { ...tab, ...newTab })),
  );
};

export const removeTab = (creatorId: string, tabId: string) => {
  const creatorTabs = getCreatorTabs(creatorId) ?? [];

  setStoredTabs(
    creatorId,
    creatorTabs.filter(({ id }) => id !== tabId),
  );
};

export const addTab = (creatorId: string, newTab: ITab) => {
  const creatorTabs = getCreatorTabs(creatorId) ?? [];
  setStoredTabs(creatorId, [...creatorTabs, newTab]);
};
