import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      background: 'var(--white-color)',
      border: '2px solid  #E7E7E7',
      maxWidth: '80px',
      borderRadius: '10px',
      marginRight: '10px',
      display: 'inline-block',
      padding: '4px 6px',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        borderWidth: '1px',
        maxWidth: '56px',
        marginRight: '8px',
        padding: '0 2px',
        fontSize: '14px',
        lineHeight: '16px',

        '& input': {
          padding: '2px 0 2px',
        },
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        borderWidth: '1px',
        maxWidth: '45px',
        marginRight: '5px',
        padding: '0 2px',
        fontSize: '12px',
        lineHeight: '12px',
      },

      '&:focus': {
        borderColor: '#00AFF0',
      },
      '&:before, &:after': {
        display: 'none',
      },
    },
  };
};

export default useStyles;
