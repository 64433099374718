import React, { useCallback, memo } from 'react';

import { useAppDispatch } from 'store';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as AssignedMembersIcon } from 'assets/images/editAccount/assignedMembers.svg';

import { membersActions } from '../../../store/reducers/members';
import { useChatterCharms } from '../../../providers/ChatterCharmsProvider';

import useAssignCreator from './useAssignCreator';
import AssignCreatorForm from './AssignCreatorForm';
import Creator from './Creator';
import styles from './AssignCreator.module.scss';

interface AssignCreatorProps {
  onClose?: () => void;
}

export const AssignCreator = memo(({ onClose }: AssignCreatorProps) => {
  const dispatch = useAppDispatch();

  const { creatorMap } = useChatterCharms();

  const { assignedCreators, nonAssignedCreators } = useAssignCreator();

  const handleClose = useCallback(() => {
    dispatch(membersActions.setMemberActiveModal(null));
    onClose?.();
  }, []);

  const renderAssignedCreators = () => {
    if (!assignedCreators.length) {
      return null;
    }

    return (
      <div className={styles.creatorsList}>
        {assignedCreators.map((creatorId) => {
          const creatorData = creatorMap?.get(creatorId);

          return creatorData ? <Creator creator={creatorData} key={creatorData.id} /> : null;
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CloseIcon className={styles.close} onClick={handleClose} />
      </div>

      <div className={styles.body}>
        <div className={styles.icon}>
          <AssignedMembersIcon />
        </div>
        <h2 className={styles.title}>Assign Account</h2>
        <p className={styles.description}>
          Make sure you link to a Creator's account and the Session Status for it is Active. Once done, we'll establish
          a new, distinct session for it.
        </p>
        <div className={styles.actions}>
          <AssignCreatorForm nonAssignedCreators={nonAssignedCreators} />
          {renderAssignedCreators()}
        </div>
      </div>
    </div>
  );
});
