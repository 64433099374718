import { memo, useState } from 'react';

import { IValidateFlags } from 'models/validation';
import Input from 'components/fields/Input/Input';
import { IPaymentBusinessPurchase } from 'models/billing';
import Select from 'components/fields/Select/Select';
import vatInfo from 'config/vatInfo';
import { IDropdownOption } from 'models/fields';

import styles from './BusinessPurchaseStep.module.scss';

interface BusinessPurchaseStepProps {
  data: IPaymentBusinessPurchase;
  errors: IValidateFlags<IPaymentBusinessPurchase>;
  onChange: (value: string, targetName?: string) => void;
}

export const BusinessPurchaseStep = memo(({ data, errors, onChange }: BusinessPurchaseStepProps) => {
  const [vatCountry, setVatCountry] = useState<IDropdownOption>();
  const vatCountryOptions = vatInfo.map((item) => ({ value: item.enum, label: `${item.country}` }) as IDropdownOption);
  const vatIdPlaceholder = vatInfo.find((item) => item.enum === vatCountry?.value)?.example;

  return (
    <div className={styles.container}>
      <Input
        name="businessName"
        placeholder="Business Name *"
        value={data.businessName}
        onChange={onChange}
        error={errors.businessName}
        className={styles.input}
      />
      <div className={styles.row}>
        <Select
          name="vatType"
          placeholder="VAT Type *"
          value={vatCountry}
          options={vatCountryOptions}
          onChange={(value, actionMeta) => {
            const targetName = actionMeta.name;
            const updatedValue = value as IDropdownOption;
            setVatCountry(updatedValue);
            onChange(updatedValue.value, targetName!);
          }}
          error={errors.businessId}
          className={styles.input}
        />
        <Input
          name="businessId"
          placeholder={vatIdPlaceholder || 'VAT ID *'}
          value={data.businessId}
          onChange={onChange}
          error={errors.businessId}
          className={styles.input}
          disabled={!vatCountry}
        />
      </div>
      <Input
        name="fullAddress"
        placeholder="Full Address *"
        value={data.fullAddress}
        onChange={onChange}
        error={errors.fullAddress}
        className={styles.input}
      />
      <Input
        name="city"
        placeholder="City *"
        value={data.city}
        onChange={onChange}
        error={errors.city}
        className={styles.input}
      />
      <Input
        name="postalCode"
        value={data.postalCode}
        onChange={onChange}
        placeholder="ZIP *"
        error={errors.postalCode}
        className={styles.input}
        disabled
      />
    </div>
  );
});
