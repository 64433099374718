import { Props } from 'react-apexcharts';

import { IEarnings, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';

const usePieStatistics = () => {
  const areAllValuesZero = (data?: IEarnings[]) => {
    return (data?.reduce((sum, value) => sum + Number(value.sum), 0) || 0) === 0;
  };

  const getConvertedPieSettings = async (filters: StatisticFilters, initialSettings: Props) => {
    try {
      const { data } = await statisticsService.getCreatorEarningBreakdown(filters);
      const chargebacks = await statisticsService.getCreatorEarningChargebacks(filters);

      const updatedLabels = data?.map((item) => item.value);
      const updatedSeries = data?.map((item) => Math.round(Number(item.sum)));
      // const updatedSeries = data?.map((item, index) => Number(`${index}0`));

      const deepUpdatedSettings = JSON.parse(JSON.stringify(initialSettings));
      deepUpdatedSettings.options.labels = updatedLabels;
      deepUpdatedSettings.series = updatedSeries;
      deepUpdatedSettings.options.tooltip = {
        y: {
          formatter: function (value: string) {
            return `$ ${value}`;
          },
        },
      };
      return { deepUpdatedSettings, chargebacks, data };
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getConvertedPieSettings,
    areAllValuesZero,
  };
};

export default usePieStatistics;
