import { useCallback, useEffect, useState } from 'react';

import { useElectronContext } from 'modules/electron/providers';
import { useChatterCharms } from 'providers/ChatterCharmsProvider';
import { captureMessageWithTag } from 'utils/sentry';

interface IProxy {
  port: string;
  host: string;
  username: string;
  password: string;
}

const useProxy = () => {
  const { creators } = useChatterCharms();
  const { activateLoginCreatorId, backToAdmin } = useElectronContext();

  const [isProxy, setIsProxy] = useState(false);

  const handleSetProxy = useCallback(async ({ port, host, username, password }: IProxy) => {
    return await window.electronAPI.invoke('set-proxy', {
      payload: {
        proxyRules: `http://${host}:${port}`,
        username,
        password,
      },
    });
  }, []);

  const setProxy = useCallback(async (proxy: IProxy) => {
    try {
      const isProxyActivated = await handleSetProxy(proxy);

      if (!isProxyActivated) {
        captureMessageWithTag('PROXY: can not be set', 'LOGIN_PROXY');
        backToAdmin();

        return;
      }

      console.log(`PROXY: Set proxy ${isProxyActivated}`);

      setIsProxy(isProxyActivated);
      return isProxyActivated;
    } catch (e) {}

    return false;
  }, []);

  const clearProxy = useCallback(() => {
    console.log(`PROXY: removed`);
    setIsProxy(false);
    window.electronAPI.send('remove-proxy', null);
  }, []);

  useEffect(() => {
    //If activateLoginCreatorId(first login)
    if (!activateLoginCreatorId) {
      return;
    }

    const creator = creators.find((item) => item.id === activateLoginCreatorId);

    if (!creator || !creator?.proxy) {
      return;
    }

    setProxy(creator.proxy);
  }, [activateLoginCreatorId, setProxy, creators]);

  useEffect(() => {
    return clearProxy;
  }, []);

  return { isProxy, setProxy, clearProxy };
};

export default useProxy;
