import { memo, ReactNode } from 'react';

import styles from './Card.module.scss';

interface CardProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

const Card = ({ title, icon, children }: CardProps) => (
  <div className={styles.box}>
    <div className={styles.titleBox}>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.title}>{title}</h3>
    </div>
    {children}
  </div>
);

export default memo(Card);
