import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      maxHeight: 'calc(100vh - 160px)',
      height: '100%',
      paddingBottom: '20px',
      overflow: 'scroll',
      marginTop: '72px',
      display: 'flex',
      flexDirection: 'column',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        marginTop: '56px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        marginTop: '32px',
      },
    },
  };
};

export default useStyles;
