export enum ChatterHelperStatus {
  Cross = 'cross',
  $ = '$',
  $$ = '$$',
  $$$ = '$$$',
}

export interface IHelper {
  ofChatterId: string;
  status: ChatterHelperStatus;
  isCreator: boolean;
  total: string;
  total30: string;
  isNew: boolean;
}
