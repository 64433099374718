import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import Header from '../../components/Header';
import Dashboard from '../../components/Dashboard';
import { useWebviewContext } from '../../../providers';
import { useOfSidebarContext } from '../../providers';
import { OF_SIDEBAR_ROUTE } from '../../constants/enums';

import useStyles from './useStyles';

const HomePage = () => {
  const styles = useStyles();

  const { activeChatterId } = useWebviewContext();
  const { setSidebarRoute } = useOfSidebarContext();

  useEffect(() => {
    if (activeChatterId) {
      setSidebarRoute(OF_SIDEBAR_ROUTE.ACCOUNT);
    }
  }, [activeChatterId]);

  return (
    <>
      <Header />
      <Box sx={styles.root}>
        <Dashboard />
      </Box>
    </>
  );
};

export default HomePage;
