import React from 'react';

import { ReactComponent as SettingsIcon } from 'assets/images/settings/settings.svg';
import { ReactComponent as PasswordIcon } from 'assets/images/settings/password.svg';
import { ReactComponent as OwnerSettingsIcon } from 'assets/images/settings/ownerSettings.svg';

import usePermissions from '../../hooks/usePermissions';

import Card from './Card/Card';
import { SettingsForm } from './SettingsForm/SettingsForm';
import { PasswordForm } from './PasswordForm/PasswordForm';
import { OwnerSettingsForm } from './OwnerSettingsForm/OwnerSettingsForm';
import styles from './Settings.module.scss';

const SETTINGS = [
  {
    title: 'Settings',
    icon: <SettingsIcon />,
    children: <SettingsForm />,
  },
  {
    title: 'Update Password',
    icon: <PasswordIcon />,
    children: <PasswordForm />,
  },
  {
    title: 'Owner Settings',
    icon: <OwnerSettingsIcon />,
    children: <OwnerSettingsForm />,
    visibleOwnerOnly: true,
  },
];

export const Settings = () => {
  const { isSuperAdminPermission } = usePermissions();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Settings</h1>
      </div>
      <div className={styles.cards}>
        {SETTINGS.filter(
          ({ visibleOwnerOnly }) => !visibleOwnerOnly || (isSuperAdminPermission && visibleOwnerOnly),
        ).map((item) => (
          <Card {...item} key={item.title} />
        ))}
      </div>
    </div>
  );
};
