import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    header: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: 'var(--white-color)',
      borderRadius: '0 0 30px 30px',
      boxShadow: '0 4px 30px 0 rgba(0, 0, 0, 0.05)',
      zIndex: 2,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        borderRadius: '0 0 22px 22px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        borderRadius: '0 0 16px 16px',
      },
    },
    main: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      paddingLeft: '8px',
      paddingRight: '8px',
      position: 'relative',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        maxHeight: '64px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        maxHeight: '47px',
      },
    },
    logo: {
      transform: 'scale(0.5)',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        transform: 'scale(0.41) translateX(-50%) translateY(-6%)',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        transform: 'scale(0.32) translateX(-100%) translateY(-20%)',
      },
    },
    title: {
      color: '#00AFF0',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      marginTop: '22px',
      marginBottom: '28px',
      marginLeft: '40px',
      alignItems: 'center',
      flexGrow: 1,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '22px',
        marginLeft: '16px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '16px',
        marginLeft: '-8px',
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
  };
};

export default useStyles;
