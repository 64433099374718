import React from 'react';

const ArrowUp = () => {
  return (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9L10 2L19 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowUp;
